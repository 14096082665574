import {useEffect} from 'react'
import {Route, Routes, useLocation, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from './redux/hooks'

import {closeAll} from './redux/slices/modals'
import {closePopup} from './redux/slices/popup'
import {currentUser, currentUserLang, isAuthed} from './redux/slices/user'
import {currentBreadcrumbs} from "./redux/slices/breadcrumbs";
import {currentSidebar} from "./redux/slices/sidebar";

import {RequireAuth} from './Auth'
import Base from "./layouts/Base";
import {MessagePopup} from './components'
import Modals from './components/Modals'
import EventsCreate from "./components/EventsCreate";

import Authorization from "./pages/Authorization";
import RecoverPassword from "./pages/Authorization/RecoverPassword";
import ConfirmRegistration from "./pages/Confirm/Registration";
import ResetPassword from "./pages/Confirm/ResetPassword";
import ConfirmInvite from "./pages/Confirm/Invite";
import YandexDirect from "./pages/Confirm/Integrations/YandexDirect";

import Profile from "./pages/Profile";

import ProjectsCreate from './pages/Projects/Create'
import {FirstStep} from "./pages/Projects/Create/FirstStep";
import {SecondStep} from "./pages/Projects/Create/SecondStep";
import {ThirdStep} from "./pages/Projects/Create/ThirdStep";
import {FourthStep} from "./pages/Projects/Create/FourthStep";

import Projects from "./pages/Projects";
import Project from "./pages/Projects/Project";
import Campaigns from "./pages/Projects/Project/Campaigns";
import Placements from "./pages/Projects/Project/Campaigns/Placements";

import EventsList from "./pages/Projects/Project/Events/List";
import EventsAdd from "./pages/Projects/Project/Events/Create";
import EventsEdit from "./pages/Projects/Project/Events/Edit";

import ProjectSettings from "./pages/Projects/Project/Settings";
import Attributions from "./pages/Projects/Project/Settings/Attributions";
import PlainSettings from "./pages/Projects/Project/Settings/PlainSettings";
import Counter from "./pages/Projects/Project/Settings/Counter";
import UserControl from "./pages/Projects/Project/Settings/UserControl";
import Integrations from "./pages/Projects/Project/Settings/Integrations";

import Upload from "./pages/Projects/Project/Upload";
import UploadDefault from "./pages/Projects/Project/Upload/Default";
import UploadPerformance from "./pages/Projects/Project/Upload/Performance";
import UploadMedia from "./pages/Projects/Project/Upload/PlanMedia";
import UploadPerformancePlan from "./pages/Projects/Project/Upload/PlanPerformance";

import Dashboards from "./pages/Projects/Project/Dashboards";

import NotFound from "./pages/NotFound";
import CampaignEfficiency from "./pages/Projects/Project/Dashboards/CampaignEfficiency";
import DashboardECommerce from "./pages/Projects/Project/Dashboards/ECommerce";
import DashboardUserBehavior from "./pages/Projects/Project/Dashboards/UserBehavior";
import Reports from "./pages/Projects/Project/Reports";
import ReportsCreate from "./pages/Projects/Project/Reports/Create";
import ReportsCreateBased from "./pages/Projects/Project/Reports/CreateBase";
import ReportsEdit from "./pages/Projects/Project/Reports/Edit";
import GetIntent from "./pages/Projects/Project/Dashboards/GetIntent";
import ReportsView from "./pages/Projects/Project/Reports/View";
import DashboardGeneral from "./pages/Projects/Project/Dashboards/General";
import SettingsApi from "./pages/Projects/Project/Settings/Api";
import SettingsRoles from "./pages/Projects/Project/Settings/Roles";
import PersonalDataPolicy from "./pages/Information/PersonalDataPolicy ";
import UserAgreement from "./pages/Information/UserAgreement";
import AdvertisingPolicy from "./pages/Information/AdvertisingPolicy";
import DashboardPostViewSessions from "./pages/Projects/Project/Dashboards/PostViewSessions";
import {setCurrentProject} from "./redux/slices/project";
import MarketingCampaigns from "./pages/Projects/Project/MarketingCampaigns";
import {trans} from "./_locales";
import MarketingCampaignsItem from "./pages/Projects/Project/MarketingCampaigns/Item";
import SourceItem from "./pages/Projects/Project/MarketingCampaigns/Item/SourceItem";
import PlacementItem from "./pages/Projects/Project/MarketingCampaigns/Item/SourceItem/PlacementItem";
import GacMediaPerformance from "./pages/Projects/Project/Dashboards/Individual/Gac/MediaPerformance";
import GacUserBehavior from "./pages/Projects/Project/Dashboards/Individual/Gac/UserBehavior";
import TefalPlanFact from "./pages/Projects/Project/Dashboards/Individual/Tefal/PlanFact";

function App() {
  const { pathname } = useLocation();
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const user = useAppSelector(currentUser);
  const isAuth = useAppSelector(isAuthed);
  const language = useAppSelector(currentUserLang);
  const breadcrumbs = useAppSelector(currentBreadcrumbs);
  const sidebar = useAppSelector(currentSidebar);

  useEffect(() => {
    dispatch(closeAll());
    dispatch(closePopup());
    dispatch(setCurrentProject(projectId));
  }, [pathname, dispatch, projectId]);

  const headerData = {
    breadcrumbsData:{
      data: breadcrumbs
    },
    userMenuData: {
      userName: '',
    }
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Authorization />} />

        <Route path="/recover/" element={<RecoverPassword />} />
        <Route path="/confirm/" element={<ConfirmRegistration/>}/>
        <Route path="/user/new_password/" element={<ResetPassword />} />
        <Route path="/invite/confirm/" element={<ConfirmInvite/>}/>
        <Route path="/privacy-policy/" element={<PersonalDataPolicy/>}/>
        <Route path="/user-agreement/" element={<UserAgreement/>}/>
        <Route path="/advertising-policy/" element={<AdvertisingPolicy/>}/>

        <Route element={<RequireAuth />}>
          <Route element={<ProjectsCreate />}>
            <Route path="/projects/create/1/" element={<FirstStep />} />
            <Route path="/projects/create/2/" element={<SecondStep />} />
            <Route path="/projects/create/3/" element={<ThirdStep />} />
            <Route path="/projects/create/4/" element={<FourthStep />} />
          </Route>

          <Route element={<Base headerData={headerData} sidebarData={sidebar} border={true}/>}>
            <Route path="/projects/" element={<Projects />} />
            <Route path="/projects/:projectId/campaigns/" element={<Campaigns />} />
            <Route path="/projects/:projectId/campaigns/:campaignId/platforms/" element={<Placements />} />

            <Route path="/profile/" element={<Profile />} />

            <Route element={<Upload/>} >
              <Route path="/projects/:projectId/upload/" element={<UploadDefault />} />
              <Route path="/projects/:projectId/upload-performance/" element={<UploadPerformance />} />
              <Route path="/projects/:projectId/upload-plan-media/" element={<UploadMedia />} />
              <Route path="/projects/:projectId/upload-plan-performance/" element={<UploadPerformancePlan />} />
            </Route>

            <Route path="/projects/:projectId/events/" element={<EventsList/>}/>
            <Route path="/projects/:projectId/events/create/" element={<EventsAdd />}/>
            <Route path="/projects/:projectId/events/edit/:eventsId/" element={<EventsEdit />}/>

            <Route element={<ProjectSettings />} >
              <Route path="/projects/:projectId/settings/" element={<PlainSettings />} />
              <Route path="/projects/:projectId/attributions/" element={<Attributions />} />
              <Route path="/projects/:projectId/events_create/" element={<EventsCreate />} />
              <Route path="/projects/:projectId/counter/" element={<Counter />} />
              <Route path="/projects/:projectId/user_control/" element={<UserControl />} />
              <Route path="/projects/:projectId/integrations/" element={<Integrations />} />
              <Route path="/projects/:projectId/api/" element={<SettingsApi />} />
              <Route path="/projects/:projectId/roles/" element={<SettingsRoles />} />
            </Route>
            <Route path="/projects/:projectId/reports/" element={<Dashboards />} />
            <Route path="/projects/:projectId/custom-reports/" element={<Reports />} />
            <Route path="/projects/:projectId/custom-reports/create/" element={<ReportsCreate />} />
            <Route path="/projects/:projectId/custom-reports/:reportId/create/" element={<ReportsCreateBased />} />
            <Route path="/projects/:projectId/custom-reports/:reportId/edit/" element={<ReportsEdit />} />
          </Route>
          <Route element={<Base headerData={headerData} sidebarData={sidebar} border={true} label={{title: trans('Campaigns', language), typeId: 'project'}}/>}>
            <Route path="/projects/:projectId/marketing/" element={<MarketingCampaigns />} />
          </Route>
          <Route element={<Base headerData={headerData} sidebarData={sidebar} border={true} label={{typeId: 'campaign'}}/>}>
            <Route path="/projects/:projectId/marketing/:marketingId/" element={<MarketingCampaignsItem />} />
          </Route>
          <Route element={<Base headerData={headerData} sidebarData={sidebar} border={true} label={{typeId: 'source'}}/>}>
            <Route path="/projects/:projectId/marketing/:marketingId/:sourceId/" element={<SourceItem />} />
          </Route>
          <Route element={<Base headerData={headerData} sidebarData={sidebar} border={true} label={{typeId: 'placement'}}/>}>
            <Route path="/projects/:projectId/marketing/:marketingId/:sourceId/:placementId/" element={<PlacementItem />} />
          </Route>

          <Route element={<Base headerData={headerData} sidebarData={sidebar} />}>
            {/*<Route path="/projects/:projectId/" element={<Project />} />*/}
            <Route path="/projects/:projectId/" element={<CampaignEfficiency />} />
            <Route path="/projects/:projectId/reports/general/" element={<DashboardGeneral/>} />
            <Route path="/projects/:projectId/reports/ecommerce/" element={<DashboardECommerce />} />
            <Route path="/projects/:projectId/reports/user_behavior/" element={<DashboardUserBehavior />} />
            <Route path="/projects/:projectId/reports/getintent/" element={<GetIntent />} />
            <Route path="/projects/:projectId/reports/postview_sessions/" element={<DashboardPostViewSessions />} />
            <Route path="/projects/:projectId/reports/gac_media_performance/" element={<GacMediaPerformance />} />
            <Route path="/projects/:projectId/reports/gac_user_behavior/" element={<GacUserBehavior />} />
            <Route path="/projects/:projectId/reports/plan_fact/" element={<TefalPlanFact />} />
            <Route path="/projects/:projectId/custom-reports/:reportId/view/" element={<ReportsView />} />
          </Route>

          <Route path="/confirm/integrations/yandex-direct/" element={<YandexDirect/>}/>
        </Route>

        {/*<Route path="/auth" element={<Authorization />} />*/}
        {/*<Route path="/" element={<MainIndex />} />*/}
        {/*<Route path="/header" element={<MainHeader />} />*/}
        {/*<Route path="/ui" element={<MainUI />} />*/}
        {/*<Route path="/projects-mocks" element={<MainProjects withMocks={true} />} />*/}
        {/*<Route path="/no-projects" element={<MainProjects noProjects={true} />} />*/}
        {/*<Route path="/campaigns-mocks" element={<MainCampaigns withMocks={true} />} />*/}
        {/*<Route path="/no-campaigns" element={<MainCampaigns noCampaigns={true} />} />*/}
        {/*<Route path="/platforms-mocks" element={<MainPlatforms withMocks={true} />} />*/}
        {/*<Route path="/no-platforms" element={<MainPlatforms noPlatforms={true} />} />*/}
        {/*<Route path="/dashboard" element={<MainDashboard />} />*/}

        <Route path="*" element={<NotFound />} />
      </Routes>

      <Modals />
      <MessagePopup />
    </>
  )
}

export default App

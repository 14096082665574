import { FC } from 'react'
import { Input } from '../index'
import { ISearchForm } from './SearchForm.d'
import './SearchForm.scss'
import {trans} from "../../_locales";
import {useAppSelector} from "../../redux/hooks";
import {currentUserLang} from "../../redux/slices/user";

const SearchForm: FC<ISearchForm> = ({ onChangeCb, value='' }) => {
  const language = useAppSelector(currentUserLang);
  return (
    <div className="form-search">
        <Input
          className={`custom-input--search ${value ? 'active': ''}`}
          type={'text'}
          name={'field-search'}
          placeholder={trans('Search', language)}
          isSearch
          value={value}
          onChange={onChangeCb}
          isDirectCbInput={true}
        />
    </div>
  );
};

export default SearchForm;

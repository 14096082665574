import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {IPlacement} from '../../interfaces/common.d'
import {RootState} from '..'
import {
  changePlacementStatus,
  creatPlacement,
  deletePlacement,
  downloadPlacementsData,
  updatePlacementById
} from '../../actions/placements'

interface PlacementsState {
  // data: IPlacement[]
  data: {
    [id: string]: {
      [id: string]: IPlacement[]
    }
  }
  loaded: boolean
  isLoading: boolean
  isSubmitLoading: boolean
  error: string | null
}

interface downloadPlacementsDataPayload {
  projectId: number
  campaignId: number
  data: IPlacement[]
}

const initialState: PlacementsState = {
  data: {},
  loaded: false,
  isLoading: false,
  isSubmitLoading: false,
  error: null
}

export const placementsSlice = createSlice({
  name: 'placements',
  initialState,
  reducers: {
    setPlacements: (state, action:PayloadAction<downloadPlacementsDataPayload>) => {
      const projectId = action.payload.projectId;
      const campaignId = action.payload.campaignId;
      const data = action.payload.data;

      if (projectId && campaignId && data) {
        state.data[projectId] = {
          [campaignId]: data
        };
      }
    },
    clearPlacements: (state) => {
      state.data = {};
    },
    setPlacementsLoaded: (state, action) => {
      state.loaded = action.payload
    },
    removeError: (state) => {
      state.error = null;
    },
    setPlacementsSubmitLoading: (state, action:PayloadAction<boolean>) => {
      state.isSubmitLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      downloadPlacementsData.fulfilled,
      (state, action: PayloadAction<downloadPlacementsDataPayload>) => {
      // state.data = action.payload ? action.payload : [];
      const projectId = action.payload.projectId;
      const campaignId = action.payload.campaignId;
      const data = action.payload.data;
      if (projectId && campaignId && data) {
        state.data[projectId] = {
          [campaignId]: data
        };
      }

      state.loaded = true;
      state.isLoading = false;
    });
    builder.addCase(
      downloadPlacementsData.pending,
      (state) => {
      state.isLoading = true;
      state.loaded = false;
    });
    builder.addCase(
      downloadPlacementsData.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      creatPlacement.fulfilled,
      (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      creatPlacement.pending,
      (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      creatPlacement.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      deletePlacement.fulfilled,
      (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      deletePlacement.pending,
      (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      deletePlacement.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      updatePlacementById.fulfilled,
      (state) => {
        state.isLoading = false;
      });
    builder.addCase(
      updatePlacementById.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      updatePlacementById.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
    builder.addCase(
      changePlacementStatus.fulfilled,
      (
        state,
        // action: PayloadAction<any>
      ) => {
        // const placementIndex = state.data.findIndex((item) => {
        //   return item.id === action.payload.placementId
        // })
        // if (action.payload.requestStatus === 'success') {
        //   state.data[placementIndex].status = action.payload.newStatus;
        // }
        state.isLoading = false;
      });
    builder.addCase(
      changePlacementStatus.pending,
      (state) => {
        state.isLoading = true;
      });
    builder.addCase(
      changePlacementStatus.rejected,
      (state,  action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload
      });
  }
});

export const { setPlacements, removeError, clearPlacements, setPlacementsLoaded, setPlacementsSubmitLoading } = placementsSlice.actions

export const placementsData = createSelector(
  (state: RootState) => state.placements,
  (placements) => placements.data
)

export const placementsLoaded = createSelector(
  (state: RootState) => state.placements,
  (placements) => placements.loaded
)

export const getIsLoading = createSelector(
  (state: RootState) => state.placements,
  (placements) => placements.isLoading
)

export const getIsSubmitLoading = createSelector(
  (state: RootState) => state.placements,
  (placements) => placements.isSubmitLoading
)

export const errorValue = createSelector(
  (state: RootState) => state.placements,
  (placements) => placements.error
)

export default placementsSlice.reducer

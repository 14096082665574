import {trans} from "../../_locales";
import {FC, useEffect, useState} from "react";
import Btn from "../../ui/Btn";
import {useAppSelector} from "../../redux/hooks";
import {currentUserLang} from "../../redux/slices/user";
import Input from "../../ui/Input";
import {DefaultObject} from "../../interfaces/common.d";
import './style.scss';

interface IChangeByBtn {
    format: 'input' | 'select',
    type: 'number' | 'string',
    name: string,
    value: string,
    onChange: (value?: string|number) => void,
    classNameBtn?: string
    classNameInput?: string
    classNameSubmit?: string
    limitNumber?: number
}


const ChangeByBtn:FC<IChangeByBtn> = ({
    type,
    name,
    value,
    onChange = (value?: string|number) => null,
    classNameBtn,
    classNameInput,
    classNameSubmit,
    limitNumber
}) => {
    const language = useAppSelector(currentUserLang);
    const [active, setActive] = useState(false);
    const [text, setText] = useState<string>(value || '');

    const changeInput = (result: DefaultObject<any>) => {
        console.log(result[name])
        const temp = result[name];
        setText(temp);
    }

    const onSubmit = () => {
        setActive(false);
        if(type === 'number') {
            onChange(+text || undefined);
        }
        if(type === 'string') {
            onChange(text || '');
        }
    }

    return (
        <>
            {
                !active
                    ?
                    <Btn
                        className={classNameBtn}
                        onClick={() => setActive(!active)}
                        text={trans('Change', language)}
                    />
                    :
                    <>
                        <Input className={classNameInput} limitNumber={limitNumber} name={name} type={type} onChange={changeInput} value={text?text:''} />
                        <Btn
                            className={classNameSubmit}
                            onClick={onSubmit}
                            text={trans('Ok', language)}
                            color={'blue'}
                        />
                    </>
            }
        </>
    )
}

export default ChangeByBtn;
import {FC, useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../../../redux/hooks'

import {currentUser, currentUserLang, userId} from '../../../../redux/slices/user'
import {
  clearMarketing,
  marketingData,
  errorValue,
  getIsLoading,
  removeError, IMarketingItem, clearCurrentMarketing
} from '../../../../redux/slices/marketing'
import {closeModal, ModalName, openModal} from '../../../../redux/slices/modals'
import {projectGeneral} from '../../../../redux/slices/project'
import {setBreadcrumbs} from "../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../redux/slices/sidebar";

import {getProjectGeneral} from '../../../../actions/project'

import {useShowError} from '../../../../helpers'

import {Loader} from '../../../../components'

import cx from 'classnames'
import './style.scss';
import {trans} from "../../../../_locales";
import {sources} from "../../../../redux/slices/sources";
import {getTags} from "../../../../actions/tags";
import {getScreenResolution, sendSdk} from "../../../../utils";
import {tags} from "../../../../redux/slices/tags";
import {DefaultObject, ICampaign, IPlacement, ISources} from "../../../../interfaces/common.d";
import {clearInstructions, setInstructions} from "../../../../redux/slices/instructions";
import {getPages} from "../../../../components/SideMenu/Role";
import TableFunnel, {ITableCheckedTypes, ITableFunnelFilter} from "../../../../components/Tables/TableFunnel";
import NotFoundElements from "../../../../components/NotFoundElements";
import {editStatusMarketingCampaign, getMarketingCampaign} from "../../../../actions/marketing";
import {downloadPixelsCsv} from "../../../../actions/media";
import {
  getPageCampaignsFilter,
  getPageCampaignsMetrics,
  getPagesStateItem,
  setCampaignsFilter,
  setCampaignsMetrics
} from "../../../../redux/slices/pages";

interface IMarketingCampaigns {
  noCampaigns?: boolean
}

const MarketingCampaigns: FC<IMarketingCampaigns> = ({ noCampaigns }) => {
  const reduxUser = useAppSelector(currentUser);
  const reduxUserId = useAppSelector(userId);
  const navigate = useNavigate();
  const reduxCampaigns = useAppSelector(marketingData);
  const reduxCurrentProject = useAppSelector(projectGeneral);
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const language = useAppSelector(currentUserLang);
  const reduxSources = useAppSelector(sources);
  const reduxTags = useAppSelector(tags);
  const settingsFilters = useAppSelector(getPageCampaignsFilter);
  const settingsMetrics = useAppSelector(getPageCampaignsMetrics);
  const dispatch = useAppDispatch();
  const {projectId} = useParams();
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('marketing');
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('marketing');
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('marketing');
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('marketing');

  if (permissions && !isView) {
    navigate(`/projects/${projectId}/`)
  }

  const [isInit, setIsInit] = useState(true);
  const [dataReady, setDataReady] = useState(false);
  const [activeMetrics, setActiveMetrics] = useState<Array<string>>([]);
  useEffect(() => {
    if(settingsMetrics && isInit) {
      setIsInit(false);
      setActiveMetrics(settingsMetrics || []);
    }
  }, [settingsMetrics])

  const reduxUserIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (projectId && reduxUserId) {
      sendSdk('page_view', {
        'user': {
          'uid': reduxUserId
        },
        'event_params': {
          'project_id': projectId,
          'user_id': reduxUserId,
          'page': 'campaign',
          'resolution': getScreenResolution()
        }
      });
    }
  }, [projectId, reduxUserId])

  useEffect(() => {
    dispatch(setBreadcrumbs([
      {
        href: '/projects',
        title: 'Проект',
        subtitle: reduxCurrentProject?.name
      },
      {
        href: '#',
        title: trans('Campaigns', language),
      },
    ]))
    dispatch(setSidebar(getPages({
      projectId: Number(projectId),
      access: permissions && permissions['can_view'],
      language,
      active: 'campaigns'
    })))
    dispatch(clearInstructions());
    dispatch(setInstructions({
      title: trans('Instruction', language),
      cb: () => {
        dispatch(openModal({
          name: ModalName.DOCS_CREATE_CAMPAIGN_FULL,
          data: {}
        }))
      }
    }))
    dispatch(clearMarketing())
    dispatch(clearCurrentMarketing());
  }, [dispatch, reduxCurrentProject?.name]);
  useEffect(() => {
    if(settingsFilters) {
      onSubmitFilter(settingsFilters || {});
    }
  }, [settingsFilters]);

  useEffect(() => {
    if (reduxUserId && projectId && reduxUserId !== reduxUserIdRef.current) {
      reduxUserIdRef.current = reduxUserId;
      const defaultState = getPagesStateItem('campaigns');
      dispatch(getMarketingCampaign({projectId: +projectId, filter: defaultState.filter})).then(()=>setDataReady(true));
      dispatch(getProjectGeneral({currentUserId: reduxUserId, projectId: +projectId}));
      dispatch(getTags({projectId: +projectId}))
    }
  }, [reduxUserId, projectId, dispatch]);

  useShowError(errorMessage as string, removeError);

  const tableData = (reduxCampaigns || [])

  const contentClassName = cx('page-content__content', 'is-label', {'empty': noCampaigns});

  const downloadHandler = (mode: 'all' | 'active' | number[], checkedType: ITableCheckedTypes='campaigns') => {
    if (projectId) {
      dispatch(downloadPixelsCsv({
        mode: mode,
        projectId: +projectId,
        checkedType: checkedType
      }))
    }
  }

  const onCreate = () => {
    let tempFilter:ITableFunnelFilter = {
      filter: {
        status: ['active']
      }
    };
    if(filter.filter && filter.filter.status) {
      tempFilter = filter;
    }
    dispatch(openModal({
      name: ModalName.NEW_MARKETING_CAMPAIGN,
      data: {
        userId: reduxUserId,
        projectId: projectId ? +projectId : -1,
        filter: tempFilter
      }
    }));
    sendSdk('event', {
      'event_type': 'open',
      'event_category': 'marketing',
      'event_name': 'create',
      'event_value': '10',
      'event_params': {
        'project_id': projectId,
        'user_id': reduxUserId,
      }
    });
  }

  const onEdit = (id: number, data: IMarketingItem|ICampaign|IPlacement|ISources) => {
    let tempFilter:ITableFunnelFilter = {
      filter: {
        status: ['active']
      }
    };
    if(filter.filter && filter.filter.status) {
      tempFilter = filter;
    }
    dispatch(openModal({
      name: ModalName.EDIT_MARKETING_CAMPAIGN,
      data: {
        userId: reduxUserId,
        projectId: projectId ? +projectId : -1,
        marketingId: id,
        initialValues: data,
        filter: tempFilter
      }
    }));
  }

  const onChangeStatus = (id: number, status: 'active'|'archive', type?: ITableCheckedTypes) => {
    if(projectId) {
      let tempFilter:ITableFunnelFilter = {
        filter: {
          status: ['active']
        }
      };
      if(filter.filter && filter.filter.status) {
        tempFilter = filter;
      }
      dispatch(editStatusMarketingCampaign({
        projectId: +projectId,
        marketingId: id,
        filter: tempFilter,
        status
      }))
    }
  }
  const [filter, setFilter] = useState<ITableFunnelFilter>({
        'search': '',
        filter: {
          status: ['active']
        },
        sort: {}
  })
  const onFilter = (data: ITableFunnelFilter) => {
    if(projectId) {
      dispatch(setCampaignsFilter(data));
    }
  }
  const onChangeMetric = (data: string[]) => {
    dispatch(setCampaignsMetrics(data));
  }
  const onSubmitFilter = (data: ITableFunnelFilter) => {
    if(projectId) {
      setDataReady(false);
      dispatch(getMarketingCampaign({projectId: +projectId, filter: data})).then(() => setDataReady(true));
      setFilter(data);
    }
  }

  return (
      <>
        <div className={contentClassName}>
          {(noCampaigns || (!isLoading && tableData.length === 0 && ((!filter.filter || Object.keys(filter.filter).length === 0) && !filter.search))) && (
              <NotFoundElements
                  text={'first campaign'}
                  onCreate={onCreate}
                  isCreate={isCreate}
                  // onUpload={onFileChosen}
              />
          )}
          {!noCampaigns && dataReady && projectId && (tableData.length > 0 || ((filter.filter && Object.keys(filter.filter).length > 0) || filter.search)) && (
              <TableFunnel
                  type={'campaigns'}
                  projectId={Number(projectId)}
                  parentType={'campaigns'}
                  data={tableData}
                  // onUpload={onFileChosen}
                  onDownload={downloadHandler}
                  filter={filter}
                  onFilter={onFilter}
                  onCreate={onCreate}
                  onEdit={onEdit}
                  onChangeStatus={onChangeStatus}
                  columnPreferences={[
                    {
                      name: 'name',
                      title: 'Имя'
                    },
                    {
                      name: 'id',
                      title: 'ID'
                    },
                    {
                      name: 'author',
                      title: 'Создатель'
                    },
                    {
                      name: 'onboarding_url',
                      title: 'Посадочная страница'
                    }
                  ]}
                  fixedChoice={['name', 'id']}
                  metricChoice={['author', 'onboarding_url']}
                  metricChoiceActive={activeMetrics}
                  onChangeMetricChoiceActive={onChangeMetric}
                  stretch={[]}
                  activePin={{'name': {
                    left: 24+28
                  }
                  }}
                  onChangePin={()=>null}
                  defaultSettingSize={{
                    'name': {
                      default: 'auto',
                      min: '30%',
                      max: '81%'
                    },
                    'id': {
                      default: 150,
                      min: 150,
                      max: 300,
                    },
                    'author': {
                      default: 200,
                      min: 150,
                      max: 400
                    },
                    'onboarding_url': {
                      default: 200,
                      min: 200,
                      max: 400
                    }
                  }}
                  dataReady={dataReady}
                  isView={isView}
                  isCreate={isCreate}
                  isEdit={isEdit}
                  viewMode={'campaigns'}
              />
          )}
        </div>
        {
            (isLoading) &&
            <Loader inBlock={true}/>
        }
      </>
  )
}

export default MarketingCampaigns;

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {ITableFunnelFilter} from "../../components/Tables/TableFunnel";

const PAGES_STORAGE_KEY = 'pages';
interface IPagesState {
  projects: {
    filter?: ITableFunnelFilter
  },
  campaigns: {
    filter?: ITableFunnelFilter,
    metrics?: string[]
  },
  sources: {
    filter?: ITableFunnelFilter,
    metrics?: string[]
  },
  placements: {
    filter?: ITableFunnelFilter,
    metrics?: string[]
  },
  creatives: {
    filter?: ITableFunnelFilter,
    metrics?: string[]
  }
}

const defaultState: IPagesState = {
  projects: {},
  campaigns: {
    filter: {
      filter: {status: ['active']}
    }
  },
  sources: {
    filter: {
      filter: {
        status: ['active'],
        status_creative: ['active']
      }
    }
  },
  placements: {
    filter: {
      filter: {status: ['active']}
    }
  },
  creatives: {
    filter: {
      filter: {
        status: ['active', 'archive'],
        status_creative: ['active']
      }
    }
  }
}

export const loadPagesState = (): IPagesState => {
  try {
    const serializedState = sessionStorage.getItem(PAGES_STORAGE_KEY);
    if (!serializedState) {
      sessionStorage.setItem(PAGES_STORAGE_KEY, JSON.stringify(defaultState));
      return defaultState;
    }
    return JSON.parse(serializedState) as IPagesState;
  } catch (err) {
    console.warn('Ошибка при чтении state из sessionStorage', err);
    sessionStorage.setItem(PAGES_STORAGE_KEY, JSON.stringify(defaultState));
    return defaultState;
  }
}

export const getPagesStateItem = (key: keyof IPagesState) => {
  try {
    const serializedState = sessionStorage.getItem(PAGES_STORAGE_KEY);
    if (!serializedState) {
      return defaultState[key];
    }
    return (JSON.parse(serializedState) as IPagesState)[key];
  } catch (err) {
    console.warn('Ошибка при чтении state из sessionStorage', err);
    return defaultState[key];
  }
}

export const savePagesState = (state: IPagesState): void => {
  try {
    sessionStorage.setItem(PAGES_STORAGE_KEY, JSON.stringify(state));
  } catch (err) {
    console.warn('Ошибка при сохранении state в sessionStorage', err);
  }
}


export const pagesSlice = createSlice({
  name: 'pages',
  initialState: loadPagesState(),
  reducers: {
    setProjects: (state, action:PayloadAction<IPagesState['projects']>) => {
      state.projects = action.payload;
      savePagesState(state);
    },
    setCampaigns: (state, action:PayloadAction<IPagesState['campaigns']>) => {
      state.campaigns = action.payload;
      savePagesState(state);
    },
    setCampaignsFilter: (state, action:PayloadAction<IPagesState['campaigns']['filter']>) => {
      state.campaigns.filter = action.payload;
      savePagesState(state);
    },
    setCampaignsMetrics: (state, action:PayloadAction<IPagesState['campaigns']['metrics']>) => {
      state.campaigns.metrics = action.payload;
      savePagesState(state);
    },
    setSources: (state, action:PayloadAction<IPagesState['sources']>) => {
      state.sources = action.payload;
      savePagesState(state);
    },
    setSourcesFilter: (state, action:PayloadAction<IPagesState['sources']['filter']>) => {
      state.sources.filter = action.payload;
      savePagesState(state);
    },
    setSourcesMetrics: (state, action:PayloadAction<IPagesState['sources']['metrics']>) => {
      state.sources.metrics = action.payload;
      savePagesState(state);
    },
    setPlacements: (state, action:PayloadAction<IPagesState['placements']>) => {
      state.placements = action.payload;
      savePagesState(state);
    },
    setPlacementsFilter: (state, action:PayloadAction<IPagesState['placements']['filter']>) => {
      state.placements.filter = action.payload;
      savePagesState(state);
    },
    setPlacementsMetrics: (state, action:PayloadAction<IPagesState['placements']['metrics']>) => {
      state.placements.metrics = action.payload;
      savePagesState(state);
    },
    setCreatives: (state, action:PayloadAction<IPagesState['creatives']>) => {
      state.creatives = action.payload;
      savePagesState(state);
    },
    setCreativesFilter: (state, action:PayloadAction<IPagesState['creatives']['filter']>) => {
      state.creatives.filter = action.payload;
      savePagesState(state);
    },
    setCreativesMetrics: (state, action:PayloadAction<IPagesState['creatives']['metrics']>) => {
      state.creatives.metrics = action.payload;
      savePagesState(state);
    },
    clearProjects: (state) => {
      state.projects = {};
      savePagesState(state);
    },
    clearCampaigns: (state) => {
      state.campaigns = {};
      savePagesState(state);
    },
    clearPlacement: (state) => {
      state.placements = {};
      savePagesState(state);
    },
    clearCreatives: (state) => {
      state.creatives = {};
      savePagesState(state);
    }
  }
})

export const {
  setProjects,
  setCampaigns,
  setCampaignsFilter,
  setCampaignsMetrics,
    setSources,
    setSourcesFilter,
    setSourcesMetrics,
  setPlacements,
    setPlacementsFilter,
    setPlacementsMetrics,
  setCreatives,
    setCreativesFilter,
    setCreativesMetrics,
  clearProjects,
  clearCampaigns,
  clearPlacement,
  clearCreatives
} = pagesSlice.actions

export const getProjectsFilter = createSelector(
  (state: RootState) => state.pages,
  (pages) => pages.projects["filter"]
)

export const getPageCampaigns = createSelector(
  (state: RootState) => state.pages,
  (pages) => pages.campaigns
)

export const getPageCampaignsFilter = createSelector(
  (state: RootState) => state.pages,
  (pages) => pages.campaigns['filter']
)

export const getPageCampaignsMetrics = createSelector(
  (state: RootState) => state.pages,
  (pages) => pages.campaigns['metrics']
)

export const getPageSources = createSelector(
  (state: RootState) => state.pages,
  (pages) => pages.sources
)

export const getPageSourcesFilter = createSelector(
  (state: RootState) => state.pages,
  (pages) => pages.sources['filter']
)

export const getPageSourcesMetrics = createSelector(
  (state: RootState) => state.pages,
  (pages) => pages.sources['metrics']
)

export const getPagePlacementsFilter = createSelector(
  (state: RootState) => state.pages,
  (pages) => pages.placements["filter"]
)
export const getPagePlacementsMetrics = createSelector(
  (state: RootState) => state.pages,
  (pages) => pages.placements['metrics']
)

export const getPageCreativesFilter = createSelector(
  (state: RootState) => state.pages,
  (pages) => pages.creatives["filter"]
)
export const getPageCreativesMetrics = createSelector(
  (state: RootState) => state.pages,
  (pages) => pages.creatives['metrics']
)

export default pagesSlice.reducer

import {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../../../../../../redux/hooks'

import {currentUser, currentUserLang, userId} from '../../../../../../../redux/slices/user'
import {
  clearMarketing,
  errorValue,
  getIsLoading,
  IMarketingItem,
  marketingCurrent,
  marketingData,
  removeError
} from '../../../../../../../redux/slices/marketing'
import {ModalName, openModal} from '../../../../../../../redux/slices/modals'
import {projectGeneral} from '../../../../../../../redux/slices/project'
import {setBreadcrumbs} from "../../../../../../../redux/slices/breadcrumbs";
import {setSidebar} from "../../../../../../../redux/slices/sidebar";

import {getProjectGeneral} from '../../../../../../../actions/project'

import {useShowError} from '../../../../../../../helpers'

import {Loader} from '../../../../../../../components'

import cx from 'classnames'
import '../../style.scss';
import {trans} from "../../../../../../../_locales";
import {sources} from "../../../../../../../redux/slices/sources";
import {getTags} from "../../../../../../../actions/tags";
import {getScreenResolution, sendSdk} from "../../../../../../../utils";
import {tags} from "../../../../../../../redux/slices/tags";
import {ICampaign, IPlacement, ISources, ItemStatus} from "../../../../../../../interfaces/common.d";
import {clearInstructions, setInstructions} from "../../../../../../../redux/slices/instructions";
import {getPages} from "../../../../../../../components/SideMenu/Role";
import TableFunnel, {ITableCheckedTypes, ITableFunnelFilter} from "../../../../../../../components/Tables/TableFunnel";
import NotFoundElements from "../../../../../../../components/NotFoundElements";
import {editStatusMarketingCampaign, getMarketingItem} from "../../../../../../../actions/marketing";
import {
  changeCampaignStatus,
  downloadCampaignsTemplateXlsx,
  downloadPixelsCsv,
  uploadMediaCsv
} from "../../../../../../../actions/media";
import {changePlacementStatus} from "../../../../../../../actions/placements";
import {fetchSources, getBaseSources} from "../../../../../../../actions/source";
import {getEvents} from "../../../../../../../actions/events";
import {IViewMode} from "../../index";
import {
  getPageCreativesFilter,
  getPageCreativesMetrics,
  getPagesStateItem,
  setCreativesFilter,
  setCreativesMetrics
} from "../../../../../../../redux/slices/pages";

const PlacementItem= () => {
  const reduxUser = useAppSelector(currentUser);
  const reduxUserId = useAppSelector(userId);
  const navigate = useNavigate();
  const reduxCampaigns = useAppSelector(marketingData);
  const reduxCurrentProject = useAppSelector(projectGeneral);
  const reduxCurrentMarketing = useAppSelector(marketingCurrent);
  const isLoading = useAppSelector(getIsLoading);
  const errorMessage = useAppSelector(errorValue);
  const language = useAppSelector(currentUserLang);
  const reduxSources = useAppSelector(sources);
  const reduxTags = useAppSelector(tags);
  const dispatch = useAppDispatch();
  const {projectId, marketingId, sourceId, placementId} = useParams();
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('campaigns');
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('campaigns');
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('campaigns');
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('campaigns');
  const [viewMode, setViewMode] = useState<IViewMode>('creatives');
  const [isOnlyParent, setIsOnlyParent] = useState<boolean>(false)

  if (permissions && !isView) {
    navigate(`/projects/${projectId}/marketing/${marketingId}/${sourceId}/`)
  }

  const [dataReady, setDataReady] = useState(false);
  const settingsMetrics = useAppSelector(getPageCreativesMetrics);
  const [isInitMetric, setIsInitMetric] = useState(true);
  const [activeMetrics, setActiveMetrics] = useState<Array<string>>([]);
  useEffect(() => {
    if(settingsMetrics && isInitMetric) {
      setIsInitMetric(false);
      setActiveMetrics(settingsMetrics || []);
    }
  }, [settingsMetrics])

  const reduxUserIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (projectId && reduxUserId) {
      sendSdk('page_view', {
        'user': {
          'uid': reduxUserId
        },
        'event_params': {
          'project_id': projectId,
          'user_id': reduxUserId,
          'page': 'campaign',
          'resolution': getScreenResolution()
        }
      });
      dispatch(fetchSources({projectId: +projectId}));
      dispatch(getEvents({projectId, calcAttr: true}));
      dispatch(getTags({projectId: +projectId}));
      dispatch(getBaseSources({projectId: +projectId}));
    }
  }, [projectId, reduxUserId])

  useEffect(() => {
    let currentSource, currentPlacement;
    if(sourceId&&reduxCurrentMarketing?.sources) {
      currentSource = reduxCurrentMarketing.sources.find((item)=>item.id === +sourceId);

      if(currentSource && placementId && currentSource.placements) {
        currentPlacement= currentSource.placements.find((item)=>item.id === +placementId);
      }
    }
    dispatch(setBreadcrumbs([
      {
        href: '/projects',
        title: 'Проект',
        subtitle: reduxCurrentProject?.name
      },
      {
        href: `/projects/${projectId}/marketing`,
        title: trans('Campaigns', language),
      },
      {
        href: `/projects/${projectId}/marketing/${marketingId}/`,
        title: reduxCurrentMarketing?.name || trans('Marketing', language),
      },
      {
        href: `/projects/${projectId}/marketing/${marketingId}/${sourceId}/`,
        title: currentSource?.name || trans('Source', language),
      },
      {
        href: `#`,
        title: (currentPlacement?.name) || trans('Creative', language),
        active: true
      },
    ]))
    dispatch(setSidebar(getPages({
      projectId: Number(projectId),
      access: permissions && permissions['can_view'],
      language,
      active: 'campaigns'
    })))
    dispatch(clearInstructions());
    dispatch(setInstructions({
      title: trans('Instruction', language),
      cb: () => {
        dispatch(openModal({
          name: ModalName.DOCS_CREATE_CAMPAIGN_FULL,
          data: {}
        }))
      }
    }))
    dispatch(clearMarketing())
  }, [dispatch, reduxCurrentProject?.name, reduxCurrentMarketing?.name, sourceId]);

  useEffect(() => {
    if (reduxUserId && projectId && marketingId && reduxUserId !== reduxUserIdRef.current) {
      reduxUserIdRef.current = reduxUserId;
      const defaultState = getPagesStateItem('creatives');
      dispatch(getMarketingItem({
        projectId: +projectId,
        marketingId: +marketingId,
        filter: defaultState.filter
      })).then(()=>setDataReady(true));
      dispatch(getProjectGeneral({currentUserId: reduxUserId, projectId: +projectId}));
      dispatch(getTags({projectId: +projectId}))
    }
  }, [reduxUserId, projectId, dispatch, marketingId]);

  useShowError(errorMessage as string, removeError);

  const [tableData, setTableData] = useState<Array<IMarketingItem|ICampaign|IPlacement|ISources>>(reduxCurrentMarketing && [reduxCurrentMarketing] || [])
  useEffect(() => {
    if(reduxCurrentMarketing) {
      let temp: Array<IMarketingItem|ICampaign|IPlacement|ISources> = [reduxCurrentMarketing];
      let placements:ICampaign[] = [];
      let creatives:IPlacement[] = [];
      let isCurrent = false;
      if (['sources', 'only_sources'].includes(viewMode)) {
        temp = reduxCurrentMarketing.sources || [];
      }
      if (['placements', 'creatives','only_placements','only_creatives'].includes(viewMode)) {
        const sources = reduxCurrentMarketing.sources || [];
        sources.forEach((item, index)=> {
          if(item.placements) {
            placements = [...placements, ...item.placements];
            if(sourceId && item.id === +sourceId) {
              temp = item.placements;
              isCurrent = true;
              return;
            }
          }
        })
        if(isCurrent) {
          let isCurrentCreative = false;
          placements.forEach((item, index) => {
            if (item.creatives) {
              creatives = [...creatives, ...item.creatives];
              if(placementId && item.id === +placementId) {
                temp = item.creatives;
                isCurrentCreative = true
                return;
              }
            }
          })
          if(!isCurrentCreative) temp = [];
          setTableData(temp);
          return;
        } else placements = [];
        temp = placements
      }
      if (['creatives','only_creatives'].includes(viewMode)) {
        placements.forEach((item, index)=> {
          if(item.creatives) {
            creatives = [...creatives, ...item.creatives];
          }
        })
        temp = creatives;
      }
      setTableData(temp);
    }
    if(['only_sources','only_placements','only_creatives'].includes(viewMode)) {
      setIsOnlyParent(true);
    } else setIsOnlyParent(false);
  }, [reduxCurrentMarketing, viewMode]);

  const contentClassName = cx('page-content__content', 'is-label');

  const onUpload = (evt: any) => {
    const formData = new FormData();
    formData.append('csv_file', evt.currentTarget.files[0]);
    if (reduxUserId && projectId && marketingId) {
      dispatch(uploadMediaCsv({
        data: formData,
        userId: reduxUserId,
        projectId: +projectId,
        marketingId: +marketingId
      }));
    }
  };

  const downloadHandler = (mode: 'all' | 'active' | number[], checkedType?: ITableCheckedTypes) => {
    if (projectId && marketingId) {
      let tempCheckedType: ITableCheckedTypes = 'sources'
      if(!checkedType) {
        if(['placements', 'only_placements'].includes(viewMode)) {
          tempCheckedType = 'placements';
        }
        if(['creatives', 'only_creatives'].includes(viewMode)) {
          tempCheckedType = 'creatives';
        }
      } else tempCheckedType = checkedType;
      dispatch(downloadPixelsCsv({
        mode: mode,
        projectId: +projectId,
        marketingId: +marketingId,
        checkedType: tempCheckedType
      }))
    }
  }
  const onDownloadTemplate = (mode: 'all' | 'active' | number[], checkedType?: ITableCheckedTypes) => {
    if (marketingId && projectId) {
      let tempCheckedType: ITableCheckedTypes = 'sources'
      if(!checkedType) {
        if(['placements', 'only_placements'].includes(viewMode)) {
          tempCheckedType = 'placements';
        }
        if(['creatives', 'only_creatives'].includes(viewMode)) {
          tempCheckedType = 'creatives';
        }
      } else tempCheckedType = checkedType;
      dispatch(downloadCampaignsTemplateXlsx({
        mode: mode,
        userId: reduxUserId,
        projectId: +projectId,
        marketingId: +marketingId,
        checkedType: tempCheckedType
      }))
    }
  }

  const onCreate = () => {
    if(marketingId && marketingId) {
      let tempFilter: ITableFunnelFilter = {
          filter: {
            status: ['active', 'archive'],
            status_creative: ['active']
          }
      };
      if (filter.filter && filter.filter.status_creative) {
        tempFilter = filter;
      }
      dispatch(openModal({
        name: ModalName.NEW_PLATFORM,
        data: {
          userId: reduxUserId,
          projectId: projectId ? +projectId : -1,
          campaignId: placementId,
          marketingId: +marketingId,
          filter: tempFilter
        }
      }))
      sendSdk('event', {
        'event_type': 'open',
        'event_category': 'marketing',
        'event_name': 'create',
        'event_value': '10',
        'event_params': {
          'project_id': projectId,
          'user_id': reduxUserId,
        }
      });
    }
  }

  const onCreateCreative = (placementId: number) => {
    if (projectId && marketingId) {
      let tempFilter: ITableFunnelFilter = {
          filter: {
            status: ['active', 'archive'],
            status_creative: ['active']
          }
      };
      if (filter.filter && filter.filter.status_creative) {
        tempFilter = filter;
      }
      dispatch(openModal({
        name: ModalName.NEW_PLATFORM,
        data: {
          userId: reduxUserId,
          projectId: projectId ? +projectId : -1,
          campaignId: placementId,
          marketingId: +marketingId,
          filter: tempFilter
        }
      }))
    }
  }

  const onEdit = (id: number, data: IMarketingItem|ICampaign|IPlacement|ISources) => {
    if(projectId) {
      let tempFilter: ITableFunnelFilter = {
          filter: {
            status: ['active', 'archive'],
            status_creative: ['active']
          }
      };
      if (filter.filter && filter.filter.status_creative) {
        tempFilter = filter;
      }
      if (data.data_type === 'campaigns') {
        dispatch(openModal({
          name: ModalName.EDIT_MARKETING_CAMPAIGN,
          data: {
            userId: reduxUserId,
            projectId: +projectId,
            marketingId: id,
            initialValues: data,
            filter: tempFilter
          }
        }));
      }
      if (data.data_type === 'placements' && marketingId) {
        dispatch(openModal({
          name: ModalName.CAMPAIGN_EDITING,
          data: {
            title: 'Редактирование размещения',
            userId: reduxUserId,
            status: data.status,
            projectId: +projectId,
            campaignId: data.id,
            campaign: data,
            marketingId: +marketingId,
            filter: tempFilter
          }
        }))
      }
      if (data.data_type === 'creatives' && marketingId) {
        dispatch(openModal({
          name: ModalName.PLATFORM_EDITING,
          data: {
            userId: reduxUserId,
            projectId: +projectId,
            campaignId: data.campaign_id,
            placementId: data.id,
            sourceId: data.source_id,
            fields: data,
            marketingId: +marketingId,
            filter: tempFilter
          }
        }))
      }
    }
  }

  const onChangeStatus = (id: number, status: 'active'|'archive', type?: ITableCheckedTypes) => {
    if(projectId && marketingId) {
      let tempFilter: ITableFunnelFilter = {
          filter: {
            status: ['active', 'archive'],
            status_creative: ['active']
          }
      };
      if (filter.filter && filter.filter.status_creative) {
        tempFilter = filter;
      }
      if(type === 'placements') {
        dispatch(changeCampaignStatus({
            status: status==='active' ? ItemStatus.ACTIVE : ItemStatus.ARCHIVE,
            projectId: +projectId,
            marketingId: +marketingId,
            filter: tempFilter,
            campaignId: id
          }))
      }
      if(type === 'creatives') {
        dispatch(changePlacementStatus({
          status: status==='active' ? ItemStatus.ACTIVE : ItemStatus.ARCHIVE,
          projectId: +projectId,
          marketingId: +marketingId,
          filter: tempFilter,
          placementId: id
        }))
      }
      if(type === 'campaigns') {
        dispatch(editStatusMarketingCampaign({
          projectId: +projectId,
          marketingId: id,
          filter: tempFilter,
          status
        }))
      }
    }
  }
  const [filter, setFilter] = useState<ITableFunnelFilter>({
        'search': '',
        filter: {
          status: ['active', 'archive'],
          status_creative: ['active']
        },
        sort: {}
  })
  const onFilter = (data: ITableFunnelFilter) => {
    if(projectId && marketingId) {
      setDataReady(false);
      const temp = data;
      if(!temp.filter) temp.filter = {};
      temp.filter.status = ['active', 'archive'];
      dispatch(setCreativesFilter(temp));
    }
  }
  const onSubmitFilter = (data: ITableFunnelFilter) => {
    if(projectId && marketingId) {
      setDataReady(false);
      const temp = JSON.parse(JSON.stringify(data)) as ITableFunnelFilter;
      if(!temp.filter) temp.filter = {};
      temp.filter.status = ['active', 'archive'];
      dispatch(getMarketingItem({projectId: +projectId, marketingId: +marketingId, filter: temp})).then(() => setDataReady(true));
      setFilter(data);
    }
  }

  const settingsFilters = useAppSelector(getPageCreativesFilter);
  useEffect(() => {
    if(settingsFilters) {
      onSubmitFilter(settingsFilters || {});
    }
  }, [settingsFilters]);
  const onChangeMetric = (data: string[]) => {
    dispatch(setCreativesMetrics(data));
  }

  const onChangeView = (mode: IViewMode, id?:number) => {
    // if(id) {
    //
    // }
    setViewMode(mode);
  }

  const renderNotFounds = () => {
    const isNotLoading = !isLoading;
    const isTableEmpty = tableData.length === 0;
    const isFilterEmpty = !filter.filter || Object.keys(filter.filter).length === 0;
    const isSearchEmpty = !filter.search;
    const isFilterActiveOnly = filter.filter?.status?.length === 1 && filter.filter.status[0] === 'active';

    if (isNotLoading && isTableEmpty && (isFilterEmpty || isFilterActiveOnly) && isSearchEmpty) {
      return (
          <NotFoundElements
              text="first creative"
              onCreate={onCreate}
              isCreate={isCreate}
          />
      );
    }

    if (dataReady && projectId && (tableData.length > 0 || ((filter.filter && Object.keys(filter.filter).length > 0) || filter.search))) {
      return (
          <TableFunnel
              type={'creatives'}
              projectId={Number(projectId)}
              parentType={(() => {
                if (['sources', 'only_sources'].includes(viewMode)) {
                  return 'sources'
                }
                if (['placements', 'only_placements'].includes(viewMode)) {
                  return 'placements'
                }
                if (['creatives', 'only_creatives'].includes(viewMode)) {
                  return 'creatives'
                }
                return 'campaigns'
              })()}
              onChangeParent={onChangeView}
              isOnlyParent={isOnlyParent}
              data={tableData}
              // onUpload={onUpload}
              onDownload={downloadHandler}
              onDownloadTemplate={onDownloadTemplate}
              filter={filter}
              onFilter={onFilter}
              onCreate={onCreate}
              onCreateDaughter={onCreateCreative}
              onEdit={onEdit}
              onChangeStatus={onChangeStatus}
              columnPreferences={[
                {
                  name: 'name',
                  title: 'Имя'
                },
                {
                  name: 'id',
                  title: 'ID'
                },
                {
                  name: 'author',
                  title: 'Создатель'
                },
                {
                  name: 'onboarding_url',
                  title: 'Посадочная страница'
                }
              ]}
              fixedChoice={['name', 'id']}
              metricChoice={['author', 'onboarding_url']}
              metricChoiceActive={activeMetrics}
              onChangeMetricChoiceActive={onChangeMetric}
              stretch={[]}
              activePin={{
                'name': {
                  left: 24 + 28
                }
              }}
              onChangePin={() => null}
              defaultSettingSize={{
                'name': {
                  default: 'auto',
                  min: '30%',
                  max: '81%'
                },
                'id': {
                  default: 150,
                  min: 150,
                  max: 300,
                },
                'author': {
                  default: 200,
                  min: 150,
                  max: 400
                },
                'onboarding_url': {
                  default: 200,
                  min: 200,
                  max: 400
                }
              }}
              dataReady={dataReady}
              isView={isView}
              isCreate={isCreate}
              isEdit={isEdit}
              viewMode={'creatives'}
          />
      )
    }

    return null;
  }

  return (
      <>
        <div className={contentClassName}>
          {renderNotFounds()}
        </div>
        {
            (isLoading) &&
            <Loader inBlock={true}/>
        }
      </>
  )
}

export default PlacementItem;

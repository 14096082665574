import { FC, useState } from 'react'
import cx from 'classnames';
import { ReactComponent as AccordionIcon } from '../../assets/icons/icon-accordion.svg'
import { Btn } from '../index';
import { IAccordion } from './Accordion.d'
import './Accordion.scss'

const Accordion: FC<IAccordion> = ({
   className='',
   title = '',
   children,
   isOpen = false
}) => {
  const [isActive, setIsActive] = useState(isOpen);

  const accordionClassName = cx('accordion', {'is-active': isActive}, className);

  const handleBtnClick = () => {
    setIsActive(!isActive);
  }

  return (
    <div className={accordionClassName}>
      <Btn
        className='accordion__btn'
        text={title}
        icon={{
          Component: AccordionIcon,
          width: 20,
          height: 10,
        }}
        onClick={handleBtnClick}
      />

      <div className="accordion__content">
        { children }
      </div>
    </div>
  );
};

export default Accordion;

import {FC, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { IBtn } from './Btn.d'
import { ReactComponent as MenuTriangle } from '../../assets/icons/menu-triangle.svg'
import { ReactComponent as AngleRight } from '../../assets/icons/angle-right.svg'
import './Btn.scss'

const Btn: FC<IBtn> = ({
  name,
  className,
  ariaLabel,
  href,
  isExternal,
  isDiv,
  isSpan,
  type,
  isReset,
  disabled=false,
  active,
  icon,
  isIconLeft,
  isNotPadding,
  dot,
  notify,
  text,
  widthAuto = true,
  color,
  colorHover,
  colorText,
  colorBorder,
  size,
  isIconBtn,
  onClick,
  isDownloadBtn,
  onFileChosen,
  fileType,
  withMenuTriangle,
  isfullWidth,
  isInfoBtn,
  isInfoPassword,
  infoTitle,
  infoValue,
  infoListItems,
  ...props
}) => {
  const Tag = href ? Link : isDiv ? "div" : isSpan ? "span" : isDownloadBtn ? 'label' : "button";
  const target = isExternal ? "_blank" : undefined;
  const rel = isExternal ? "nofollow noopener noreferrer" : undefined;
  const typeAttr = type || (Tag === "button" ? "button" : undefined);

  const btnClassName = cx(
    'btn',
    className,
    {
      'btn--width-auto': widthAuto,
      'btn--dodger-blue': color === 'blue',
      'btn--alice-blue': color === 'light-blue',
      'btn--border-blue': color === 'border-blue',
      'btn--transparent': color === 'transparent',
      'btn--transparent-hover': colorHover === 'transparent',
      'btn--border-transparent': colorBorder === 'transparent',
      'btn--not-padding': isNotPadding,
      'btn--dark': color === 'dark',
      'btn--red': color === 'red',
      'btn--sm': size === 'sm',
      'btn--md': size === 'md',
      'btn--lg': size === 'lg',
      'btn--icon': isIconBtn,
      'btn--notify': notify !== undefined && notify >= 0,
      'is-active': active,
      'btn--reset': isReset,
      'btn--icon-left': isIconLeft,
      'btn--info': isInfoBtn,
      'btn--full-width': isfullWidth,
      'btn--disabled': disabled,
    }
  );

  const infoValueClassName = cx(
    'btn__info-value',
    {
      'btn__info-value--password': isInfoPassword,
    }
  );

  // if (isDownloadBtn) {
  //   return (
  //     <div className=""></div>
  //   )
  // }

  const accepts = {
      'csv': '.csv',
      'xlsx': 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'image': 'image/*',
      'video': 'video/mp4',
      'txt': 'text/plain'
  }

  const [acceptFile, setAcceptFile] = useState<string>('.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
  useEffect(()=> {
      if(isDownloadBtn && fileType) {
          setAcceptFile(accepts[fileType])
      }
  }, [])

  return (
    <Tag
      name={name}
      className={btnClassName}
      aria-label={ariaLabel}
      to={href as string}
      target={target}
      rel={rel}
      type={typeAttr}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {icon && <icon.Component width={icon.width} height={icon.height} />}
      {dot && <span className="btn__dot"></span>}
      {notify !== undefined && notify >= 0 && <span className="btn__notify">{notify}</span>}
      {text && <span className="btn__text">{text}</span>}
      {withMenuTriangle && <span className="btn__triangle"><MenuTriangle width={8} height={4}/></span>}
      {isDownloadBtn && <input className='visually-hidden' type='file' name='csv' accept={acceptFile} onChange={onFileChosen} disabled={disabled} />}

      {isInfoBtn && (
        <>
          <span className='btn__text-wrapper'>
            {infoTitle && <span className="btn__info-title">{infoTitle}</span>}
            {infoValue && <span className={infoValueClassName}>{infoValue}</span>}
            {infoListItems && (
              <span className='btn__info-list'>
                {infoListItems.map((item, i) => {
                  return (
                    <span key={i} className='btn__info-item'>
                      <span className='btn__info-item-title'>{item.location} {item.browser}</span>
                      {item.last_visit && <span className='btn__info-item-subtitle'>{item.last_visit}</span>}
                    </span>
                  )
                })}
              </span>
            )}
          </span>
          <span className='btn__angle'>
            <AngleRight width={8} height={14} />
          </span>
        </>
      )}
    </Tag>
  );
}

export default Btn;

import React, {FC} from "react";

import "./style.scss";
import {useAppSelector} from "../../../../../redux/hooks";
import {currentUserLang} from "../../../../../redux/slices/user";
import {IStretchSettingItem, onStretchBlock} from "../../../TablePagination/Head/functions";
import cx from "classnames";
import {IPinColumns} from "../../../interfaces";
import {getStylesItem} from "../../functions";
import {ITableCheckedTypes, ITableFunnelSize} from "../../index";
import { ReactComponent as SourceIcon } from '../../../../../assets/icons/globus.svg'
import { ReactComponent as PlacementIcon } from '../../../../../assets/icons/placement.svg'
import { ReactComponent as CreativeIcon } from '../../../../../assets/icons/picture.svg'

interface ITableFunnelBodyItem {
    className?:string,
    index:number,
    name:string,
    type: ITableCheckedTypes,
    value?: string | number | null,
    // name: string,
    activePin?:IPinColumns,
    // onChangePin?:(i:number)=>void,
    // onStretch?: (setting: IStretchSettingItem) => void,
    settingSize?: ITableFunnelSize
    isStretch?: boolean,
    level: number
}

const TableFunnelBodyItem:FC<ITableFunnelBodyItem> = ({
    className,
    index,
    name,
    type,
    value,
    activePin,
    settingSize,
    isStretch,
    level
}) => {
    const language = useAppSelector(currentUserLang);

    const contentClassName = cx(
        'table-funnel-body-row-item',
        {
            'sticky': activePin && activePin[name],
            'stretch': isStretch,
            [`level_${level}`]: level && name === 'name'
        },
        className
    )
    return (
        <div
             className={contentClassName}
             style={getStylesItem({
                 key: name,
                 settingSize,
                 activePin
             })}
        >
            {name === 'name' &&
            <>
                {type === 'sources' && <SourceIcon className={'table-funnel-body-row-item__icon'} />}
                {type === 'placements' && <PlacementIcon className={'table-funnel-body-row-item__icon'} />}
                {type === 'creatives' && <CreativeIcon className={'table-funnel-body-row-item__icon'} />}
            </>
            }
            <span>{value}</span>
        </div>
    )
}

export default TableFunnelBodyItem;
import React, {FC, useEffect, useRef, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import cx from 'classnames'
import { IBreadcrumbs } from './Breadcrumbs.d'
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {projectsData} from "../../redux/slices/projects";
import {downloadProjectsData} from "../../actions/projects";
import {currentUser, currentUserLang, userId} from "../../redux/slices/user";
import { ReactComponent as PlusIcon } from '../../assets/icons/icon-plus.svg'
import Btn from "../Btn";
import './style.scss';
import {useOnClickOutside} from "../../helpers";
import Select from "../../components/ui/Select";
import {trans} from "../../_locales";

const Breadcrumbs: FC<IBreadcrumbs> = ({ className, data}) => {
  const componentClassName = cx(
    'breadcrumbs__wrapper',
    className,
  );
  const dispatch = useAppDispatch();
  const reduxUserId = useAppSelector(userId);
  const language = useAppSelector(currentUserLang);
  const user = useAppSelector(currentUser);
  const reduxUserIdRef = useRef<number | null>(null);
  const reduxProjects = useAppSelector(projectsData);
  const projects = reduxProjects;
  const projectsActive = reduxProjects.filter((item:any) => typeof item ==='object' && item.status === 'active');
  const { projectId } = useParams();
  const navigate = useNavigate();
  const role = user && user.roles ? user.roles[`${projectId}`] : "";

  useEffect(() => {
    if (reduxUserId && reduxUserId !== reduxUserIdRef.current) {
      reduxUserIdRef.current = reduxUserId;
      dispatch(downloadProjectsData())
    }
  }, [data, reduxUserId, dispatch, projectId]);

  const [modelAttribution, setModelAttribution] = useState<string>('');
  const [showModelAttributions, setShowModelAttributions] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(() => setShowModelAttributions(false), [popupRef]);

  useEffect(() => {
    if(projects && projects.length > 0) {
      const item = projects.filter((item:any)=> item.id == projectId);
      item.length > 0 && setModelAttribution(item[0].name);
    }
  }, [projectId, projects]);

  const changeModelAttribution = (e:any, value:string, id:number) => {
    Object.values(e.target.parentNode.children).map((item:any)=> (
       item.classList.remove('active')
    ))
    e.target.classList.add('active');
    setModelAttribution(value);
    setShowModelAttributions(false);
    if(sessionStorage.getItem('settings')) sessionStorage.removeItem("settings");
    navigate(`/projects/${id}/`)
  }

  return (
    <div className='breadcrumbs'>
        {projects.length > 0 && modelAttribution && (data && data.filter(i => i.title == "Проект").length > 0) &&
        <div className="breadcrumbs__model" ref={popupRef}>
          <button className={`breadcrumbs__model_block ${showModelAttributions ? 'active' : ''}`} onClick={()=> setShowModelAttributions(!showModelAttributions)}>
            <span>{modelAttribution}</span> {role && role ==='view' && <span className='breadcrumbs__view'>{trans('View', language)}</span>}
          </button>
          <div className={`breadcrumbs__model_content ${showModelAttributions ? 'active' : ''}`}>
            {projectsActive.slice(0,3).map((item:any, index:number)=> (
              <button key={item.id} className={`breadcrumbs__model_content__btn ${item.id == projectId ? 'active' : ''}`} onClick={(e)=>{
                changeModelAttribution(e,item.name, item.id)
              }}>{item.name}</button>
            ))}

            {user.can_create_project && <Btn
                text={trans('Create project', language)}
                icon={{
                  Component: PlusIcon,
                  width: 12,
                  height: 12,
                }}
                color='light-blue'
                onClick={() => navigate(`/projects/create/1/`)}
              />
            }

            {projectsActive.length > 3 &&
              <div className={'breadcrumbs__btn'}>
                <Btn
                  text={trans('All projects', language)}
                  color='light-blue'
                  onClick={() => navigate(`/projects/`)}
                />
              </div>
            }
          </div>
        </div>}
      <div className={componentClassName}>
        {data && data?.length > 0 && data.map((item) => {
          if(item.title === 'Проект')
            return <div key={item.title} className="breadcrumbs__item"></div>;
          if(typeof item.dataset === 'object')
            return <div key={item.title} className="breadcrumbs__item"> <Select selectActive={item.title} dataset={item.dataset} onChangeActive={()=>null} isLink={true} widthAuto={true} colorText={'dark'}/></div>
          if(item.title === trans('Back', language))
            return (<Link key={item.title} to={item.href} className='breadcrumbs__back'>{'<-'}&nbsp;{item.title}</Link>);
          return (
            <div key={item.title} className="breadcrumbs__item">
              <Link to={item.href} className={`breadcrumbs__link ${item.active ? 'active' : ''}`}>
                {item.title + ' '}
                {item.subtitle && <span>{item.subtitle}</span>}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default Breadcrumbs
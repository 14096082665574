import { ReactComponent as HomeIcon } from '../../../assets/icons/home.svg'
import { ReactComponent as ListIcon } from '../../../assets/icons/icon-list.svg'
import { ReactComponent as PlacementsIcon } from '../../../assets/icons/icon-placements.svg'
import { ReactComponent as DashboardIcon } from '../../../assets/icons/icon-dashboard.svg'
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload.svg";
import { ReactComponent as ProjectSettingsIcon } from '../../../assets/icons/project_settings.svg'
import { ReactComponent as StarIcon } from '../../../assets/icons/icon-star.svg'
import { ReactComponent as ReportsIcon } from '../../../assets/icons/document-bold.svg'
import {trans} from "../../../_locales";
import {DefaultObject} from "../../../interfaces/common.d";


const getIndexPageByTitle = (list: Array<any>, title: string) => {
    let temp;
    list.map((item:any, index)=> {
        if(item.btn.ariaLabel === title) {
            temp = index;
        }
    })
    return temp;
}

export const getPages = ({
    projectId,
    access = [],
    language = 'ru',
    active
}:{
    projectId: number,
    access?: Array<string>,
    language?: string,
    active?: string
}) => {
    const list:DefaultObject<any> = {
        home: {
            btn: {
                ariaLabel: trans('Home', language),
                href: `/projects/${projectId}/`,
                icon: {
                    Component: HomeIcon,
                    width: 20,
                    height: 20,
                }
            }
        },
        campaigns: {
            btn: {
                ariaLabel: trans('Creating Pixels', language),
                href: `/projects/${projectId}/campaigns/`,
                icon: {
                    Component: ListIcon,
                    width: 20,
                    height: 20,
                }
            },
            subBtn: [
                {
                    title: trans('Creating Pixels', language),
                    href: `/projects/${projectId}/campaigns/`
                },
                {
                    title: trans('Creating Pixels (New)', language),
                    href: `/projects/${projectId}/marketing/`
                }
            ]
        },
        placements: {
            btn: {
                ariaLabel: trans('Creatives', language),
                href: '#',
                icon: {
                    Component: PlacementsIcon,
                    width: 20,
                    height: 20,
                }
            }
        },
        dashboards: {
            btn: {
                ariaLabel: trans('Reports', language),
                href: `/projects/${projectId}/reports/`,
                icon: {
                    Component: DashboardIcon,
                    width: 20,
                    height: 20,
                }
            },
            subBtn: []
        },
        custom_reports: {
            btn: {
                ariaLabel: trans('Custom reports', language),
                href: `/projects/${projectId}/custom-reports/`,
                icon: {
                    Component: ReportsIcon,
                    width: 20,
                    height: 20,
                }
            }
        },
        upload: {
            btn: {
                ariaLabel: trans('Data upload', language),
                href: `/projects/${projectId}/upload/`,
                icon: {
                    Component: UploadIcon,
                    width: 20,
                    height: 20,
                }
            },
        },
        events: {
            btn: {
                ariaLabel: trans('Events', language),
                href: `/projects/${projectId}/events/`,
                icon: {
                    Component: StarIcon,
                    width: 20,
                    height: 20,
                }
            }
        },
        settings: {
            btn: {
                ariaLabel: trans('Settings', language),
                href: `/projects/${projectId}/settings`,
                icon: {
                    Component: ProjectSettingsIcon,
                    width: 20,
                    height: 20,
                }
            }
        }
    }
    const result:any = [];
    const defaultPages = ['home'];
    const dopPagesSettings = [
        'settings_general', 'settings_attributions', 'settings_counter',
        'settings_user_control', 'settings_integrations', 'settings_api'
    ];

    Object.keys(list).map((key:string) => {
        if((defaultPages.includes(key) || access.includes(key)) && key !== 'dashboards' && key !== 'placements') {
            if (active === key) {
                list[key].isActive = true;
            }
            result.push(list[key]);
        }
        if (key === 'placements') {
            if (active === 'placements' && access.includes('campaigns')) {
                list[key].isActive = true;
                result.push(list[key]);
            }
        }
        if (key === 'dashboards') {
            if (active === key) {
                list[key].isActive = true;
            }
            if (access.includes('dashboard_general')) {
                const indexPage = getIndexPageByTitle(result, trans('Reports', language));
                if (indexPage) {
                    result[indexPage].subBtn.push({
                        title: trans('General indicators', language),
                        href: `/projects/${projectId}/reports/general/`
                    })
                } else {
                    list['dashboards'].subBtn.push({
                        title: trans('General indicators', language),
                        href: `/projects/${projectId}/reports/general/`
                    })
                    result.push(list['dashboards']);
                }
            }
            if (access.includes('dashboard_ecommerce')) {
                const indexPage = getIndexPageByTitle(result, trans('Reports', language));
                if (indexPage) {
                    result[indexPage].subBtn.push({
                        title: trans('E-Commerce Reports', language),
                        href: `/projects/${projectId}/reports/ecommerce/`
                    })
                } else {
                    list['dashboards'].subBtn.push({
                        title: trans('E-Commerce Reports', language),
                        href: `/projects/${projectId}/reports/ecommerce/`
                    })
                    result.push(list['dashboards']);
                }
            }
            if (access.includes('dashboard_user_behavior') || projectId === 12475) {
                const indexPage = getIndexPageByTitle(result, trans('Reports', language))
                if (indexPage) {
                    result[indexPage].subBtn.push({
                        title: trans('User behavior analysis', language),
                        href: `/projects/${projectId}/reports/user_behavior/`
                    })
                } else {
                    list['dashboards'].subBtn.push({
                        title: trans('User behavior analysis', language),
                        href: `/projects/${projectId}/reports/user_behavior/`
                    })
                    result.push(list['dashboards']);
                }
            }
            if (projectId === 12523) {
                const indexPage = getIndexPageByTitle(result, trans('Reports', language))
                if (indexPage) {
                    result[indexPage].subBtn.push({
                        title: trans('PostView Sessions', language),
                        href: `/projects/${projectId}/reports/postview_sessions/`
                    })
                } else {
                    list['dashboards'].subBtn.push({
                        title: trans('PostView Sessions', language),
                        href: `/projects/${projectId}/reports/postview_sessions/`
                    })
                    result.push(list['dashboards']);
                }
            }

            if(projectId === 12712) {
                const indexPage = getIndexPageByTitle(result, trans('Reports', language))
                if (indexPage) {
                    result[indexPage].subBtn.push({
                        title: trans('Channel sequence efficiency', language),
                        href: `/projects/${projectId}/reports/gac_user_behavior/`
                    })
                    result[indexPage].subBtn.push({
                        title: trans('Digital attribution modeling', language),
                        href: `/projects/${projectId}/reports/gac_media_performance/`
                    })
                    result[indexPage].subBtn.push({
                        title: trans('Plan fact', language),
                        href: `/projects/${projectId}/reports/plan_fact/`
                    })
                } else {
                    list['dashboards'].subBtn.push({
                        title: trans('Channel sequence efficiency', language),
                        href: `/projects/${projectId}/reports/gac_user_behavior/`
                    })
                    list['dashboards'].subBtn.push({
                        title: trans('Digital attribution modeling', language),
                        href: `/projects/${projectId}/reports/gac_media_performance/`
                    })
                    list['dashboards'].subBtn.push({
                        title: trans('Plan fact', language),
                        href: `/projects/${projectId}/reports/plan_fact/`
                    })
                    result.push(list['dashboards']);
                }
            }
            if(projectId === 12777) {
                const indexPage = getIndexPageByTitle(result, trans('Reports', language))
                if (indexPage) {
                    result[indexPage].subBtn.push({
                        title: trans('Plan fact', language),
                        href: `/projects/${projectId}/reports/plan_fact/`
                    })
                } else {
                    list['dashboards'].subBtn.push({
                        title: trans('Plan fact', language),
                        href: `/projects/${projectId}/reports/plan_fact/`
                    })
                    result.push(list['dashboards']);
                }
            }
        }
    })
    let isIncludeSettings = false;
    dopPagesSettings.map((item:string)=> {
        if(access.includes(item)) {
            isIncludeSettings = true;
        }
    })
    if(isIncludeSettings) {
        result.push(list['settings']);
    }

    return result;
}


export const getSettingsPages = ({
    access = [],
    language = 'ru'
}:{
    access?: Array<string>,
    language?: string,
}) => {
    const list:DefaultObject<any> = {
        'settings_general': {
            title: trans('General', language),
            path: 'settings',
        },
        'settings_attributions': {
            title: trans('Attributions', language),
            path: 'attributions',
        },
        'settings_counter': {
            title: trans('Counter', language),
            path: 'counter',
        },
        'settings_user_control': {
            title: trans('Users management', language),
            path: 'user_control',
        },
        'settings_integrations': {
            title: trans('Integrations', language),
            path: 'integrations',
        },
        'settings_api': {
            title: trans('API', language),
            path: 'api',
        },
        'settings_roles': {
            title: trans('Roles', language),
            path: 'roles',
        }
    }
    const result:any = [];
    const defaultPages:string[] = []
    Object.keys(list).map((key:string) => {
        if(access.includes(key)) {
            result.push(list[key]);
        }
    })

    return result;
}

export const Project = (projectId:number, language?:string) => {
  const temp = [
    {
      btn: {
        ariaLabel: trans('Home', language),
        href: '#',
        icon: {
          Component: HomeIcon,
          width: 20,
          height: 20,
        }
      },
      isActive: true,
    },
    {
      btn: {
        ariaLabel: trans('Media campaigns', language),
        href: `/projects/${projectId}/campaigns/`,
        icon: {
          Component: ListIcon,
          width: 20,
          height: 20,
        }
      }
    },
    {
      btn: {
        ariaLabel: trans('Reports', language),
        href: `/projects/${projectId}/reports/`,
        icon: {
          Component: DashboardIcon,
          width: 20,
          height: 20,
        }
      },
      subBtn: [
        {
          title: trans('General indicators', language),
          href: `/projects/${projectId}/reports/general/`
        },
        {
          title: trans('E-Commerce Reports', language),
          href: `/projects/${projectId}/reports/ecommerce/`
        }
      ]
    },
    {
      btn: {
        ariaLabel: trans('Data upload', language),
        href: `/projects/${projectId}/upload/`,
        icon: {
          Component: UploadIcon,
          width: 20,
          height: 20,
        }
      },
    },
    {
      btn: {
        ariaLabel: trans('Events', language),
        href: `/projects/${projectId}/events/`,
        icon: {
          Component: StarIcon,
          width: 20,
          height: 20,
        }
      },
    },
    {
      btn: {
        ariaLabel: trans('Settings', language),
        href: `/projects/${projectId}/settings`,
        icon: {
          Component: ProjectSettingsIcon,
          width: 20,
          height: 20,
        }
      }
    }
  ]

    temp.splice(3, 0, {
      btn: {
        ariaLabel: trans('Custom reports', language),
        href: `/projects/${projectId}/custom-reports/`,
        icon: {
          Component: ReportsIcon,
          width: 20,
          height: 20,
        }
      },
    })

  if(projectId === 12475 && temp[2] && temp[2].subBtn) {
    temp[2].subBtn.push({
          title: trans('User behavior analysis', language),
          href: `/projects/${projectId}/reports/user_behavior/`
    })
  }
  return temp;
}

export const Campaigns = (projectId:number, language?:string) => {
    const temp = [
        {
          btn: {
            ariaLabel: trans('Home', language),
            href: `/projects/${projectId}/`,
            icon: {
              Component: HomeIcon,
              width: 20,
              height: 20,
            }
          },
        },
        {
          btn: {
            ariaLabel: trans('Media campaigns', language),
            href: `/projects/${projectId}/campaigns/`,
            icon: {
              Component: ListIcon,
              width: 20,
              height: 20,
            }
          },
          isActive: true,
        },
        {
          btn: {
            ariaLabel: trans('Reports', language),
            href: `/projects/${projectId}/reports/`,
            icon: {
              Component: DashboardIcon,
              width: 20,
              height: 20,
            }
          },
          subBtn: [
            {
              title: trans('General indicators', language),
              href: `/projects/${projectId}/reports/general/`
            },
            {
              title: trans('E-Commerce Reports', language),
              href: `/projects/${projectId}/reports/ecommerce/`
            }
          ]
        },
        {
          btn: {
            ariaLabel: trans('Data upload', language),
            href: `/projects/${projectId}/upload/`,
            icon: {
              Component: UploadIcon,
              width: 20,
              height: 20,
            }
          },
        },
        {
          btn: {
            ariaLabel: trans('Events', language),
            href: `/projects/${projectId}/events/`,
            icon: {
              Component: StarIcon,
              width: 20,
              height: 20,
            }
          },
        },
        {
          btn: {
            ariaLabel: trans('Settings', language),
            href: `/projects/${projectId}/settings`,
            icon: {
              Component: ProjectSettingsIcon,
              width: 20,
              height: 20,
            }
          },
        },
    ]

    temp.splice(3, 0, {
      btn: {
        ariaLabel: trans('Custom reports', language),
        href: `/projects/${projectId}/custom-reports/`,
        icon: {
          Component: ReportsIcon,
          width: 20,
          height: 20,
        }
      },
    })

  if(projectId === 12475 && temp[2] && temp[2].subBtn) {
    temp[2].subBtn.push({
          title: trans('User behavior analysis', language),
          href: `/projects/${projectId}/reports/user_behavior/`
    })
  }
  return temp;
}

export const Placements = (projectId:number, language?:string) => {
    const temp = [
        {
          btn: {
            ariaLabel: trans('Home', language),
            href: `/projects/${projectId}/`,
            icon: {
              Component: HomeIcon,
              width: 20,
              height: 20,
            }
          },
        },
        {
          btn: {
            ariaLabel: trans('Media campaigns', language),
            href: `/projects/${projectId}/campaigns/`,
            icon: {
              Component: ListIcon,
              width: 20,
              height: 20,
            }
          }
        },
        {
          btn: {
            ariaLabel: trans('Creatives', language),
            href: '#',
            icon: {
              Component: PlacementsIcon,
              width: 20,
              height: 20,
            }
          },
          isActive: true,
        },
        {
          btn: {
            ariaLabel: trans('Reports', language),
            href: `/projects/${projectId}/reports/`,
            icon: {
              Component: DashboardIcon,
              width: 20,
              height: 20,
            }
          },
          subBtn: [
            {
              title: trans('General indicators', language),
              href: `/projects/${projectId}/reports/general/`
            },
            {
              title: trans('E-Commerce Reports', language),
              href: `/projects/${projectId}/reports/ecommerce/`
            }
          ]
        },
        {
          btn: {
            ariaLabel: trans('Data upload', language),
            href: `/projects/${projectId}/upload/`,
            icon: {
              Component: UploadIcon,
              width: 20,
              height: 20,
            }
          },
        },
        {
          btn: {
            ariaLabel: trans('Events', language),
            href: `/projects/${projectId}/events/`,
            icon: {
              Component: StarIcon,
              width: 20,
              height: 20,
            }
          },
        },
        {
          btn: {
            ariaLabel: trans('Settings', language),
            href: `/projects/${projectId}/settings`,
            icon: {
              Component: ProjectSettingsIcon,
              width: 20,
              height: 20,
            }
          },
        },
    ]

    temp.splice(4, 0, {
      btn: {
        ariaLabel: trans('Custom reports', language),
        href: `/projects/${projectId}/custom-reports/`,
        icon: {
          Component: ReportsIcon,
          width: 20,
          height: 20,
        }
      },
    })

  if(projectId === 12475 && temp[2] && temp[2].subBtn) {
    temp[2].subBtn.push({
          title: trans('User behavior analysis', language),
          href: `/projects/${projectId}/reports/user_behavior/`
    })
  }
  return temp;
}

export const Settings = (projectId:number, language?:string) => {
    const temp = [
        {
          btn: {
            ariaLabel: trans('Home', language),
            href: `/projects/${projectId}/`,
            icon: {
              Component: HomeIcon,
              width: 20,
              height: 20,
            }
          },
        },
        {
          btn: {
            ariaLabel: trans('Media campaigns', language),
            href: `/projects/${projectId}/campaigns/`,
            icon: {
              Component: ListIcon,
              width: 20,
              height: 20,
            }
          }
        },
        {
          btn: {
            ariaLabel: trans('Reports', language),
            href: `/projects/${projectId}/reports/`,
            icon: {
              Component: DashboardIcon,
              width: 20,
              height: 20,
            }
          },
          subBtn: [
            {
              title: trans('General indicators', language),
              href: `/projects/${projectId}/reports/general/`
            },
            {
              title: trans('E-Commerce Reports', language),
              href: `/projects/${projectId}/reports/ecommerce/`
            }
          ]
        },
        {
          btn: {
            ariaLabel: trans('Data upload', language),
            href: `/projects/${projectId}/upload/`,
            icon: {
              Component: UploadIcon,
              width: 20,
              height: 20,
            }
          },
        },
        {
          btn: {
            ariaLabel: trans('Events', language),
            href: `/projects/${projectId}/events/`,
            icon: {
              Component: StarIcon,
              width: 20,
              height: 20,
            }
          },
        },
        {
          btn: {
            ariaLabel: trans('Settings', language),
            href: `/projects/${projectId}/settings`,
            icon: {
              Component: ProjectSettingsIcon,
              width: 20,
              height: 20,
            }
          },
          isActive: true,
        },
    ]

    temp.splice(3, 0, {
      btn: {
        ariaLabel: trans('Custom reports', language),
        href: `/projects/${projectId}/custom-reports/`,
        icon: {
          Component: ReportsIcon,
          width: 20,
          height: 20,
        }
      },
    })

  if(projectId === 12475 && temp[2] && temp[2].subBtn) {
    temp[2].subBtn.push({
          title: trans('User behavior analysis', language),
          href: `/projects/${projectId}/reports/user_behavior/`
    })
  }
  return temp;
}

export const Upload = (projectId:number, language?:string) => {
  const temp = [
    {
      btn: {
        ariaLabel: trans('Home', language),
        href: `/projects/${projectId}/`,
        icon: {
          Component: HomeIcon,
          width: 20,
          height: 20,
        }
      }
    },
    {
      btn: {
        ariaLabel: trans('Media campaigns', language),
        href: `/projects/${projectId}/campaigns/`,
        icon: {
          Component: ListIcon,
          width: 20,
          height: 20,
        }
      }
    },
    {
      btn: {
        ariaLabel: trans('Reports', language),
        href: `/projects/${projectId}/reports/`,
        icon: {
          Component: DashboardIcon,
          width: 20,
          height: 20,
        }
      },
      subBtn: [
        {
          title: trans('General indicators', language),
          href: `/projects/${projectId}/reports/general/`
        },
        {
          title: trans('E-Commerce Reports', language),
          href: `/projects/${projectId}/reports/ecommerce/`
        }
      ]
    },
    {
      btn: {
        ariaLabel: trans('Data upload', language),
        href: '#',
        icon: {
          Component: UploadIcon,
          width: 20,
          height: 20,
        }
      },
      isActive: true,
    },
    {
      btn: {
        ariaLabel: trans('Events', language),
        href: `/projects/${projectId}/events/`,
        icon: {
          Component: StarIcon,
          width: 20,
          height: 20,
        }
      },
    },
    {
      btn: {
        ariaLabel: trans('Settings', language),
        href: `/projects/${projectId}/settings`,
        icon: {
          Component: ProjectSettingsIcon,
          width: 20,
          height: 20,
        }
      },

    },
  ]

    temp.splice(3, 0, {
      btn: {
        ariaLabel: trans('Custom reports', language),
        href: `/projects/${projectId}/custom-reports/`,
        icon: {
          Component: ReportsIcon,
          width: 20,
          height: 20,
        }
      },
    })

  if(projectId === 12475 && temp[2] && temp[2].subBtn) {
    temp[2].subBtn.push({
          title: trans('User behavior analysis', language),
          href: `/projects/${projectId}/reports/user_behavior/`
    })
  }
  return temp;
}

export const Events = (projectId:number, language?:string) => {
  const temp = [
    {
      btn: {
        ariaLabel: trans('Home', language),
        href: `/projects/${projectId}/`,
        icon: {
          Component: HomeIcon,
          width: 20,
          height: 20,
        }
      }
    },
    {
      btn: {
        ariaLabel: trans('Media campaigns', language),
        href: `/projects/${projectId}/campaigns/`,
        icon: {
          Component: ListIcon,
          width: 20,
          height: 20,
        }
      }
    },
    {
      btn: {
        ariaLabel: trans('Reports', language),
        href: `/projects/${projectId}/reports/`,
        icon: {
          Component: DashboardIcon,
          width: 20,
          height: 20,
        }
      },
      subBtn: [
        {
          title: trans('General indicators', language),
          href: `/projects/${projectId}/reports/general/`
        },
        {
          title: trans('E-Commerce Reports', language),
          href: `/projects/${projectId}/reports/ecommerce/`
        }
      ]
    },
    {
      btn: {
        ariaLabel: trans('Data upload', language),
        href: `/projects/${projectId}/upload/`,
        icon: {
          Component: UploadIcon,
          width: 20,
          height: 20,
        }
      },

    },
    {
      btn: {
        ariaLabel: trans('Events', language),
        href: `#`,
        icon: {
          Component: StarIcon,
          width: 20,
          height: 20,
        }
      },
      isActive: true,
    },
    {
      btn: {
        ariaLabel: trans('Settings', language),
        href: `/projects/${projectId}/settings`,
        icon: {
          Component: ProjectSettingsIcon,
          width: 20,
          height: 20,
        }
      },

    },
  ]

    temp.splice(3, 0, {
      btn: {
        ariaLabel: trans('Custom reports', language),
        href: `/projects/${projectId}/custom-reports/`,
        icon: {
          Component: ReportsIcon,
          width: 20,
          height: 20,
        }
      },
    })

  if(projectId === 12475 && temp[2] && temp[2].subBtn) {
    temp[2].subBtn.push({
          title: trans('User behavior analysis', language),
          href: `/projects/${projectId}/reports/user_behavior/`
    })
  }
  return temp;
}

export const Dashboards = (projectId:number, language?:string) => {
  const temp = [
    {
      btn: {
        ariaLabel: trans('Home', language),
        href: `/projects/${projectId}/`,
        icon: {
          Component: HomeIcon,
          width: 20,
          height: 20,
        }
      }
    },
    {
      btn: {
        ariaLabel: trans('Media campaigns', language),
        href: `/projects/${projectId}/campaigns/`,
        icon: {
          Component: ListIcon,
          width: 20,
          height: 20,
        }
      }
    },
    {
      btn: {
        ariaLabel: trans('Reports', language),
        href: `/projects/${projectId}/reports/`,
        icon: {
          Component: DashboardIcon,
          width: 20,
          height: 20,
        }
      },
      subBtn: [
        {
          title: trans('General indicators', language),
          href: `/projects/${projectId}/reports/general/`
        },
        {
          title: trans('E-Commerce Reports', language),
          href: `/projects/${projectId}/reports/ecommerce/`
        }
      ],
      isActive: true,
    },
    {
      btn: {
        ariaLabel: trans('Data upload', language),
        href: `/projects/${projectId}/upload/`,
        icon: {
          Component: UploadIcon,
          width: 20,
          height: 20,
        }
      },

    },
    {
      btn: {
        ariaLabel: trans('Events', language),
        href: `/projects/${projectId}/events/`,
        icon: {
          Component: StarIcon,
          width: 20,
          height: 20,
        }
      },
    },
    {
      btn: {
        ariaLabel: trans('Settings', language),
        href: `/projects/${projectId}/settings`,
        icon: {
          Component: ProjectSettingsIcon,
          width: 20,
          height: 20,
        }
      },

    },
  ]

    temp.splice(3, 0, {
      btn: {
        ariaLabel: trans('Custom reports', language),
        href: `/projects/${projectId}/custom-reports/`,
        icon: {
          Component: ReportsIcon,
          width: 20,
          height: 20,
        }
      },
    })

  if(projectId === 12475 && temp[2] && temp[2].subBtn) {
    temp[2].subBtn.push({
          title: trans('User behavior analysis', language),
          href: `/projects/${projectId}/reports/user_behavior/`
    })
  }
  return temp;
}

export const Reports = (projectId:number, language?:string) => {
  const temp:any = [
    {
      btn: {
        ariaLabel: trans('Home', language),
        href: `/projects/${projectId}/`,
        icon: {
          Component: HomeIcon,
          width: 20,
          height: 20,
        }
      }
    },
    {
      btn: {
        ariaLabel: trans('Media campaigns', language),
        href: `/projects/${projectId}/campaigns/`,
        icon: {
          Component: ListIcon,
          width: 20,
          height: 20,
        }
      }
    },
    {
      btn: {
        ariaLabel: trans('Reports', language),
        href: `/projects/${projectId}/reports/`,
        icon: {
          Component: DashboardIcon,
          width: 20,
          height: 20,
        }
      },
      subBtn: [
        {
          title: trans('General indicators', language),
          href: `/projects/${projectId}/reports/general/`
        },
        {
          title: trans('E-Commerce Reports', language),
          href: `/projects/${projectId}/reports/ecommerce/`
        }
      ]
    },
    {
      btn: {
        ariaLabel: trans('Data upload', language),
        href: `/projects/${projectId}/upload/`,
        icon: {
          Component: UploadIcon,
          width: 20,
          height: 20,
        }
      },

    },
    {
      btn: {
        ariaLabel: trans('Events', language),
        href: `/projects/${projectId}/events/`,
        icon: {
          Component: StarIcon,
          width: 20,
          height: 20,
        }
      },
    },
    {
      btn: {
        ariaLabel: trans('Settings', language),
        href: `/projects/${projectId}/settings`,
        icon: {
          Component: ProjectSettingsIcon,
          width: 20,
          height: 20,
        }
      },

    },
  ]

    temp.splice(3, 0, {
      btn: {
        ariaLabel: trans('Custom reports', language),
        href: `/projects/${projectId}/custom-reports/`,
        icon: {
          Component: ReportsIcon,
          width: 20,
          height: 20,
        }
      },
      isActive: true,
    })

  if(projectId === 12475 && temp[2] && temp[2].subBtn) {
    temp[2].subBtn.push({
          title: trans('User behavior analysis', language),
          href: `/projects/${projectId}/reports/user_behavior/`
    })
  }
  return temp;
}
import './style.scss';
import iconEmpty from "../../assets/icons/empty.svg";
import Btn from "../../ui/Btn";
import {trans} from "../../_locales";
import {useAppSelector} from "../../redux/hooks";
import {currentUserLang} from "../../redux/slices/user";
import {ReactComponent as PlusIcon} from '../../assets/icons/icon-plus.svg'
import cx from "classnames";
import {IIcon} from "../../ui/Btn/Btn.d";

const Empty = ({
    title,
    description,
    btnCallback,
    btnText='Retry',
    className,
    icon,
    isBig,
    isCreate
}:{
    title:string,
    description?:{
        first: string,
        second?: string
    },
    btnText?:string,
    btnCallback?:()=>void,
    className?:string,
    icon?: IIcon
    isBig?: boolean
    isCreate?: boolean
}) => {
    const language = useAppSelector(currentUserLang);
    return (
        <div className={cx('empty', className)}>
            {icon && <icon.Component className={'empty__img'} width={icon.width} height={icon.height} />}
            {!icon && <img className='empty__img' src={iconEmpty} alt="empty"/> }
            <span className={isBig ? 'empty__title' : 'empty__text'}>{title}</span>
            {description && <span className='empty__description'>
                {trans(description.first, language)}{description.second && <><br/>{trans(description.second, language)}</>}
            </span> }
            {btnCallback && isCreate && <Btn
                text={trans(btnText, language)}
                color='blue'
                icon={btnText==='Create' ? {
                    Component: PlusIcon,
                    width: 12,
                    height: 12,
                } : undefined}
                size={isBig?'md':'sm'}
                onClick={btnCallback}
            />}
        </div>
    )
}

export default Empty;
export const datasetName = {
    'marketing': 'Имя кампании',
    'campaign': 'Имя размещения',
    'purchase_type': 'Тип закупки',
    'source': 'Источник кампании',
    'tags': 'Теги кампании',
    'name_creative': 'Название креатива',
    'geo': 'Гео креатива',
    'format': 'Формат креатива',
    'targeting': 'Таргетинги креатива',
    'size': 'Размер креатива',
    'creative': 'Креатив',
    'event_target_domain': 'Домен целевого действия',
    'impression_platform': 'Платформа показа',
    'event_target_platform': 'Платформа целевого действия'
}

export const datasetConditions = {
    'marketing': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'campaign': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'purchase_type': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'source': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'geo': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'format': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'targeting': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'name_creative': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'creative': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'size': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'tags': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'event_target_domain': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'impression_platform': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'event_target_platform': {
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
}

export const datasetValue = {
    'marketing': {},
    'campaign': {},
    'purchase_type': {
        'CPM': 'CPM',
        'CPC': 'CPC',
        'CPV': 'CPV',
        'FIX': 'FIX'
    },
    'source': {},
    'geo': {},
    'format': {},
    'targeting': {},
    'name_creative': {},
    'creative': {},
    'size': {},
    'tags': {},
    'event_target_domain': '',
    'impression_platform': {
        'Web': 'Web',
        'App': 'App',
        'TV': 'TV',
    },
    'event_target_platform': {
        'Web': 'Web',
        'App': 'App',
        'API': 'Api'
    },
}

export const datasetNameEcom = {
    'product_name': 'Имя товара',
    'brand': 'Бренд',
    'category_1': 'Категория 1',
    'category_2': 'Категория 2'
}

export const datasetConditionsEcom = {
    'product_name': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'brand': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'category_1': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
    'category_2': {
        'contain': 'Содержит',
        'not_contain': 'Не содержит',
        'equal': 'Равно',
        'not_equal': 'Не равно'
    },
}

export const datasetValueEcom = {
    'product_name': '',
    'brand': '',
    'category_1': '',
    'category_2': ''
}
import {
    colors,
    filterAndSortDataByMaxValues,
    formatDate,
    formattingValues,
    isNumber,
    stringifyFilter
} from "../../../../utils";
import {getDateAgo, getMonday} from "../../../../components/ui/TimeFilter/functions";
import {IFilterMediaArray} from "../../../../components/Tables/interfaces";
import {ICustomReport} from "../../../../components/ReportsUpdate/interfaces.d";
import {ModelsArr, WindowsArr} from "../../../../data";
import {IGroupDash, IModelAttribution, IWindowAttribution} from "../../../../interfaces/common.d";

export const tooltipLinear = (type?:string, currency?: 'USD' | 'EUR' | 'RUB', otherType?:string, otherIndex?:number):any => {
    const temp:any = {
        callbacks: {
            labelColor: function (context: any) {
                return {
                    borderColor: 'transparent',
                    backgroundColor: context.dataset.backgroundColor,
                    borderWidth: 0,
                };
            },
        },
        intersect: false,
        mode: "index",
        backgroundColor: '#F9FAFF',
        cornerRadius: 10,
        borderColor: '#eff0f9',
        borderWidth: 1,
        titleColor: '#8E9EB5',
        bodyColor: '#435875',
        padding: {
            top: 12,
            right: 22,
            bottom: 12,
            left: 22,
        },
        position: 'bottom'
    }
    if(type) {
        temp.callbacks.label = function(context:any) {
            const label = context.dataset.label || '';
            const value = context.raw ? context.raw : 0;
            if(label) {
                if(context.datasetIndex === otherIndex && otherType) {
                    if(otherType === 'percent') {
                        return `${label} - ${formattingValues(value, 'float')}%`;
                    }
                    return `${label} - ${formattingValues(value, otherType, currency)}`;
                }
                if(type === 'percent') {
                    return `${label} - ${formattingValues(value, 'float')}%`;
                }
                return `${label} - ${formattingValues(value, type, currency)}`;
            }
            return '';
        }
    }
    return temp;
}
export const tooltipBar = (type?:string, currency?: 'USD' | 'EUR' | 'RUB', otherType?:string, otherIndex?:number, mode: boolean = true):any => {
    const temp: any = {
        callbacks: {
            labelColor: function (context: any) {
                return {
                    borderColor: 'transparent',
                    backgroundColor: context.dataset.backgroundColor,
                    borderWidth: 0,
                };
            },
        },
        intersect: false,
        mode: mode ? "index" : undefined,
        backgroundColor: '#F9FAFF',
        cornerRadius: 10,
        borderColor: '#eff0f9',
        borderWidth: 1,
        titleColor: '#8E9EB5',
        bodyColor: '#435875',
        padding: {
            top: 12,
            right: 22,
            bottom: 12,
            left: 22,
        },
        position: 'bottom'
    }
    if(type) {
        temp.callbacks.label = function(context:any) {
            const label = context.dataset.label || '';
            const value = context.raw ? context.raw : 0;
            if(label) {
                if(context.datasetIndex === otherIndex && otherType) {
                    if(otherType === 'percent') {
                        return `${label} - ${formattingValues(value, 'float')}%`;
                    }
                    return `${label} - ${formattingValues(value, otherType, currency)}`;
                }
                if(type === 'percent') {
                    return `${label} - ${formattingValues(value, 'float')}%`;
                }
                return `${label} - ${formattingValues(value, type, currency)}`;
            }
            return '';
        }
    }
    return temp;
}

export const onHandlerChart = (response:any, setShowLoader:any,setData:any, setOptions:any, currency?: 'USD' | 'EUR' | 'RUB', settings?:any, labelIndex=0, dataIndexes=[1], positionLabels:'top'|'right'|'left'|'bottom'='top', containerRef?:any) => {
    setShowLoader(false);
    if (response && response.data) {
        if(settings && settings.pie) {
            const tempNames:Array<any> = [];
            let tempValues:Array<any> = [];
            const tempData = response.data.dataset
            tempData.slice(0,10).map((item:any)=> {
                tempNames.push(item[0]);
                tempValues.push(item[1]);
            })
            if(tempData.length > 10) {
                const tempOther = tempData.slice(10).reduce((partialSum: number, a: any) => partialSum + +a[1], 0);
                tempNames.push(`other`);
                tempValues.push(tempOther);
            }
            const allSum = tempData.reduce((partialSum: number, a: any) => partialSum + +a[1], 0);
            if(allSum === 0)
                tempValues = [];
            else tempValues = tempValues.map((item:any)=> ((item/allSum)*100).toFixed(2));

            const temp:any = {
                labels: tempNames,
                datasets: [
                    {
                        label: '# of Percent',
                        data: tempValues,
                        backgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1,
                    },
                ],
            }
            setData(temp);
            setShowLoader(false);
            setOptions({
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: {
                        callbacks: {
                            title: function () {
                                return '';
                            },
                            label: function (context: any) {
                                let label = context.dataset.label || '';

                                if (label) {
                                    label = `${context.label} - ${formattingValues(context.parsed, 'float')}%`;
                                }
                                return label;
                            },
                            labelColor: function (context: any) {
                                return {
                                    borderColor: 'transparent',
                                    backgroundColor: context.dataset.backgroundColor[context.dataIndex],
                                    borderWidth: 0,
                                    borderRadius: 5,
                                };
                            },
                        },
                        backgroundColor: '#F9FAFF',
                        cornerRadius: 10,
                        borderColor: '#eff0f9',
                        borderWidth: 1,
                        titleColor: '#8E9EB5',
                        bodyColor: '#435875',
                        padding: {
                            top: 12,
                            right: 22,
                            bottom: 12,
                            left: 22,
                        }
                    },
                },
            })
        } else if(settings && settings.bar_linear) {
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            const labels: any = [];
            const linear: any = [];
            const bar: any = [];
            const barIndex = 1;
            const lineIndex = 2;
            const typeBar = types[barIndex];
            const typeLine = types[lineIndex];
            const onHandleValue = (value:number, type?:string) => {
                const temp = isNumber(value) ? +value : 0;
                if(type === 'percent') {
                    return temp * 100;
                }
                return temp;
            }
            response.data.dataset.map((list: any) => {
                labels.push(list[0]);
                bar.push(onHandleValue(list[barIndex], typeBar));
                linear.push(onHandleValue(list[lineIndex], typeLine));
            })
            const temp = {
                labels: labels,
                datasets: [
                    {
                        type: 'line' as const,
                        label: response.data.column[lineIndex].title,
                        borderColor: `${colors[0]}`,
                        backgroundColor: colors[0],
                        borderWidth: 2,
                        fill: false,
                        data: linear,
                        yAxisID: 'y1',
                    },
                    {
                        type: 'bar' as const,
                        label: response.data.column[barIndex].title,
                        backgroundColor: settings.bar_transparent ? `${colors[1]}40` : colors[1],
                        data: bar,
                        yAxisID: 'y'
                    },
                ],
            }
            setData(temp);
            setOptions({
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}`,
                    },
                    title: {
                        display: true,
                        text: response.item_title
                    },
                    tooltip: tooltipLinear(typeLine, currency, typeBar, 1)
                },
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                }
            })
            setShowLoader(false);
        } else if(settings && settings.linear_multi_bar) {
            const data: any = {};
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            const nameIndex = settings.name_index ? settings.name_index : 0;
            let typeValue = '';
            const labelX:any = {};
            const bar: any = {};
            const barIndex = 3;
            const barType = types[barIndex];

            response.data.dataset.map((list: any) => {
                // if(list[nameIndex]) {
                    if (!(data[list[nameIndex]])) {
                        data[list[nameIndex]] = {}
                    }
                    const temp: any = {}
                    list.map((item: any, index: number) => {
                        if (index === labelIndex) {
                            temp['x'] = list[labelIndex]
                            labelX[list[labelIndex]] = 1;
                        }
                        if (index !== nameIndex && index !== labelIndex && index !== barIndex) {
                            if (types[index])
                                typeValue = types[index];
                            let value = item ? item : 0;
                            types[index] === 'percent' && (value = +value * 100);
                            temp['y'] = value;
                        }
                    })
                    if (typeof temp.x !== 'undefined' && typeof temp.y !== 'undefined')
                        data[list[nameIndex]][temp.x] = temp.y;
                    const impressions = list[barIndex] ? list[barIndex] : 0;
                    if(bar[list[labelIndex]]) {
                        bar[list[labelIndex]].impressions += impressions;
                    } else bar[list[labelIndex]] = {impressions};
                // }
            })
            const barDataset:number[] = [];
            Object.keys(bar).map((key)=> {
                const a = bar[key].impressions;
                barDataset.push(a?a:0);
            })
            const temp: any = {
                labels: Object.keys(labelX),
                datasets: []
            }

            const sortedKeys = filterAndSortDataByMaxValues(data, temp.labels);

            sortedKeys.map((key, index)=> {
                const fillValue = !!settings.fill;
                const dataset:Array<string|number> = [];
                temp.labels.map((item:any) => {
                    if(typeof data[key][item] === 'undefined')
                        data[key][item] = null;
                    dataset.push(data[key][item]);
                })
                if(temp.datasets.length === 11) {
                    temp.datasets[temp.datasets.length-1].label = 'Остальные...';
                    temp.datasets[temp.datasets.length-1].data = temp.datasets[temp.datasets.length-1].data.map(function (num:number, idx:number) {
                        if(isNumber(dataset[idx]))
                            return num + +dataset[idx];
                        else return num;
                    });
                } else {
                    temp.datasets.push({
                        type: 'line' as const,
                        label: key,
                        data: dataset,
                        backgroundColor: settings.fill ? `${colors[index+2]}65` : colors[index+2],
                        borderColor: colors[index+2],
                        borderWidth: 2,
                        fill: fillValue,
                        yAxisID: 'y'
                    })
                }
            })
            temp.datasets.push({
                type: 'bar' as const,
                label: response.data.column[3].title,
                backgroundColor: `${colors[1]}40`,
                data: barDataset,
                yAxisID: 'y1',
            })
            setData(temp);
            const tempOptions: any = {
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: tooltipLinear(typeValue,currency, barType, barIndex),
                },
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                }
            }
            setOptions(tempOptions)
        } else if(settings && settings.bar_stack_linear) {
            const data: any = {};
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            const nameIndex = settings.name_index ? settings.name_index : 0;
            let typeValue = '';
            const labelX:any = {};
            const linear: any = {};
            const linearIndex = 3;
            const linearType = types[linearIndex];

            response.data.dataset.map((list: any) => {
                // if(list[nameIndex]) {
                    if(!(data[list[nameIndex]])) {
                        data[list[nameIndex]] = {}
                    }
                    const temp: any = {}
                    list.map((item: any, index: number) => {
                        if (index === labelIndex) {
                            temp['x'] = list[labelIndex]
                            labelX[list[labelIndex]] = 1;
                        }
                        if (index !== nameIndex && index !== labelIndex && index !== linearIndex) {
                            if (types[index])
                                typeValue = types[index];
                            let value = item ? item : 0;
                            types[index] === 'percent' && (value = +value * 100);
                            temp['y'] = value;
                        }
                    })
                    if (typeof temp.x !== 'undefined' && typeof temp.y !== 'undefined')
                        data[list[nameIndex]][temp.x] = temp.y;

                    const impression = list[linearIndex] ? list[linearIndex] : 0;
                    const click = list[2] ? list[2] : 0;
                    if(linear[list[labelIndex]]) {
                        linear[list[labelIndex]].clicks += click;
                        linear[list[labelIndex]].impressions += impression;
                    } else linear[list[labelIndex]] = {
                        clicks: click,
                        impressions: impression
                    }
                // }
            })
            const linearDataset:number[] = [];
            Object.keys(linear).map((key)=> {
                const a = linear[key].clicks;
                const b = linear[key].impressions;
                if(b > 0) {
                    const value = (a / b) * 100;
                    linearDataset.push(value<100?value:100);
                } else {
                    linearDataset.push(0);
                }
            })
            const temp: any = {
                labels: Object.keys(labelX),
                datasets: [
                    {
                        type: 'line' as const,
                        label: response.data.column[3].title,
                        borderColor: `${colors[0]}`,
                        backgroundColor: colors[0],
                        borderWidth: 2,
                        fill: false,
                        data: linearDataset,
                        yAxisID: 'y1',
                    },
                ]
            }

            const sortedKeys = filterAndSortDataByMaxValues(data, temp.labels);

            sortedKeys.map((key, index)=> {
                const dataset:Array<string|number> = [];
                temp.labels.map((item:any) => {
                    if(typeof data[key][item] === 'undefined')
                        data[key][item] = null;
                    dataset.push(data[key][item]);
                })

                if(temp.datasets.length === 11) {
                    temp.datasets[temp.datasets.length-1].label = 'Остальные...';
                    temp.datasets[temp.datasets.length-1].data = temp.datasets[temp.datasets.length-1].data.map(function (num:number, idx:number) {
                        if(isNumber(dataset[idx]))
                            return num + +dataset[idx];
                        else return num;
                    });
                } else {
                    temp.datasets.push({
                        label: key,
                        data: dataset,
                        backgroundColor: colors[index+1],
                        stack: 'Stack 0',
                    })
                }
            })
            setData(temp);
            setOptions({
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: tooltipBar(typeValue,currency, linearType, 0)
                },
                interaction: {
                    mode: 'index' as const,
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                },
            })
        } else if(settings && settings.funnel) {
            const data: any = {};
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            const nameIndex = settings.name_index ? settings.name_index : 0;


            let tempData: number[] = [];
            response.data.dataset.map((list: any) => {
                if(Array.isArray(list) && list.length === 5) {
                    tempData = list;
                }
            })
            const temp: any = {
                labels: ['Показы рекламы', 'Охват', 'Клики', 'PostView лиды', 'PostClick лиды'],
                datasets: [{
                    data: tempData,
                    dataLabels: {
                        anchor: (context:any) => {
                            const value = context.dataset.data[context.dataIndex];
                            return value < 0.02 ? 'end' : 'start';
                        },
                        align: (context:any) => {
                            const value = context.dataset.data[context.dataIndex];
                            return value < 0.02 ? 'end' : 'center';
                        },
                        textAlign: 'center',
                        color: (context:any) => {
                          return '#FFCE56';
                        },
                        font: {
                            size: 20,
                        },
                        formatter: (value:any, context:any) => {
                            const label = context.chart.data.labels[context.dataIndex];
                            return `${label}\n${(value * 100).toLocaleString()}%`;
                        }
                    }
                    // backgroundColor: colors[0],
                }]
            }

            setData(temp);
            setOptions({
                indexAxis: 'y',
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: tooltipBar(undefined, undefined, undefined, undefined, false)
                }
            })
        } else if(settings && settings.bar_stack) {
            const data: any = {};
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            const nameIndex = settings.name_index ? settings.name_index : 0;
            let typeValue = '';
            const labelX:any = {}

            response.data.dataset.map((list: any) => {
                // if(list[nameIndex]) {
                    if(!(data[list[nameIndex]])) {
                        data[list[nameIndex]] = {}
                    }
                    const temp: any = {}
                    list.map((item: any, index: number) => {
                        if (index === labelIndex) {
                            temp['x'] = list[labelIndex]
                            labelX[list[labelIndex]] = 1;
                        }
                        if (index !== nameIndex && index !== labelIndex) {
                            if (types[index])
                                typeValue = types[index];
                            let value = item ? item : 0;
                            types[index] === 'percent' && (value = +value * 100);
                            temp['y'] = value;
                        }
                    })
                    if (typeof temp.x !== 'undefined' && typeof temp.y !== 'undefined')
                        data[list[nameIndex]][temp.x] = temp.y;
                // }
            })
            const temp: any = {
                labels: Object.keys(labelX),
                datasets: []
            }

            const sortedKeys = filterAndSortDataByMaxValues(data, temp.labels);

            sortedKeys.map((key, index)=> {
                const dataset:Array<string|number> = [];
                temp.labels.map((item:any) => {
                    if(typeof data[key][item] === 'undefined')
                        data[key][item] = null;
                    dataset.push(data[key][item]);
                })

                if(temp.datasets.length === 11) {
                    temp.datasets[temp.datasets.length-1].label = 'Остальные...';
                    temp.datasets[temp.datasets.length-1].data = temp.datasets[temp.datasets.length-1].data.map(function (num:number, idx:number) {
                        if(isNumber(dataset[idx]))
                            return num + +dataset[idx];
                        else return num;
                    });
                } else {
                    temp.datasets.push({
                        label: key,
                        data: dataset,
                        backgroundColor: colors[index],
                        stack: 'Stack 0',
                    })
                }
            })
            setData(temp);
            setOptions({
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: tooltipBar(typeValue,currency)
                },
                interaction: {
                    mode: 'index' as const,
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                },
            })
        } else if(settings && settings.linear_multi) {
            const data: any = {};
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            const nameIndex = settings.name_index ? settings.name_index : 0;
            let typeValue = '';
            const labelX:any = {}

            response.data.dataset.map((list: any) => {
                // if(list[nameIndex]) {
                    if (!(data[list[nameIndex]])) {
                        data[list[nameIndex]] = {}
                    }
                    const temp: any = {}
                    list.map((item: any, index: number) => {
                        if (index === labelIndex) {
                            temp['x'] = list[labelIndex]
                            labelX[list[labelIndex]] = 1;
                        }
                        if (index !== nameIndex && index !== labelIndex) {
                            if (types[index])
                                typeValue = types[index];
                            let value = item ? item : 0;
                            types[index] === 'percent' && (value = +value * 100);
                            temp['y'] = value;
                        }
                    })
                    if (typeof temp.x !== 'undefined' && typeof temp.y !== 'undefined')
                        data[list[nameIndex]][temp.x] = temp.y;
                // }
            })
            const temp: any = {
                labels: Object.keys(labelX),
                datasets: []
            }

            const sortedKeys = filterAndSortDataByMaxValues(data, temp.labels);

            sortedKeys.map((key, index)=> {
                const fillValue = !!settings.fill;
                const dataset:Array<string|number> = [];
                temp.labels.map((item:any) => {
                    if(typeof data[key][item] === 'undefined')
                        data[key][item] = null;
                    dataset.push(data[key][item]);
                })
                if(temp.datasets.length === 11) {
                    temp.datasets[temp.datasets.length-1].label = 'Остальные...';
                    temp.datasets[temp.datasets.length-1].data = temp.datasets[temp.datasets.length-1].data.map(function (num:number, idx:number) {
                        if(isNumber(dataset[idx]))
                            return num + +dataset[idx];
                        else return num;
                    });
                } else {
                    temp.datasets.push({
                        label: key,
                        data: dataset,
                        backgroundColor: settings.fill ? `${colors[index]}65` : colors[index],
                        borderColor: colors[index],
                        borderWidth: 2,
                        fill: fillValue
                    })
                }
            })
            setData(temp);
            const tempOptions: any = {
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: tooltipLinear(typeValue,currency),
                }
            }
            setOptions(tempOptions)
        } else if(settings && settings.multi) {
            const priority = settings.priority;
            const background_type = priority === 'bar' ? 'line' : 'bar';
            const columns:Array<{name:string, title:string, type: string}> = response.data.column;
            const rows:Array<Array<string|number>> = response.data.dataset;

            const types = columns.map(col => col.type);
            const labels = rows.map(row => row[labelIndex]);
            const isBackgroundIndex = isNumber(settings.background_index);
            let mainType = 'float';
            let otherType = undefined;

            // Формируем пустые наборы данных (datasets) для каждой колонки,
            // кроме нулевой (которая метка X).
            const datasets:any[] = [];
            const backgroundColors = [ '#9c6cf3', '#ffa600', '#007bff', '#4D6569', '#dd5ad8',
                '#ff4f8a',  '#FCDA61', '#C06471', '#F4CAC6'
            ]
            const listColors = isBackgroundIndex && priority === 'bar' ? backgroundColors : colors;
            for (let col = 1; col < columns.length; col++) {
                const columnInfo = columns[col];
                if(isBackgroundIndex && settings.background_index === col - 1) {
                    const tempDataset: any = {
                        type: background_type,
                        label: columnInfo.title,
                        backgroundColor: `${listColors[col - 1 % listColors.length]}40`,
                        data: [],
                        yAxisID: 'y1',
                        order: priority === 'bar' && background_type === 'line' ? 99 : 0,
                        z: priority === 'bar' && background_type === 'line' ? 99 : 0
                    }
                    if(background_type === 'line') {
                        tempDataset.backgroundColor = `${listColors[col - 1 % listColors.length]}`;
                        tempDataset.borderColor = listColors[col - 1 % listColors.length];
                        tempDataset.borderWidth = 2;
                    }
                    datasets.push(tempDataset)
                    otherType = columnInfo.type;
                } else {
                    const tempDataset: any = {
                        type: priority,
                        label: columnInfo.title,
                        data: [],                // заполним чуть ниже
                        backgroundColor: priority === 'bar' ? `${listColors[col - 1 % listColors.length]}80` : settings.fill
                            ? `${listColors[col - 1 % listColors.length]}65` // цвет + прозрачность, если fill=true
                            : listColors[col - 1 % listColors.length],
                        borderColor: listColors[col - 1 % listColors.length],
                        borderWidth: 2,
                        fill: !!settings.fill,
                        yAxisID: 'y',
                        // Для столбиков приоритет (order) ниже, чем у линии
                        // order: priority === 'bar' ? 1 : 2,
                        // z: priority === 'bar' ? 1 : 2,
                    }
                    if(background_type === 'line') {
                        delete tempDataset.borderColor;
                        delete tempDataset.borderWidth;
                    }
                    datasets.push(tempDataset);
                    mainType = columnInfo.type;
                }
            }

            // Заполняем массивы data в каждом наборе
            rows.forEach((row) => {
                for (let col = 1; col < row.length; col++) {
                  let value = row[col] ?? 0;
                  if (types[col] === 'percent') {
                    value = +value * 100;
                  }
                  datasets[col - 1].data.push(value);
                }
            });

            const chartData = {
                labels,
                datasets
            };
            console.log(chartData)
            setData(chartData);
            const tempOptions: any = {
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: tooltipLinear(mainType,currency, otherType, settings.background_index),
                }
            }
            if(isBackgroundIndex) {
                tempOptions['scales'] = {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                }
            }
            setOptions(tempOptions)
        } else {
            const labels: any = [];
            const data: any = [];
            const types: Array<string> = response.data.column.map((item:any) => item.type);
            let typeValue = '';
            response.data.dataset.map((list: any) => {
                list.map((item: any, index: number) => {
                    if (index === labelIndex) {
                        labels.push(list[labelIndex])
                    }
                    if (dataIndexes.includes(index, 0)) {
                        if (types[index])
                            typeValue = types[index];
                        const tempIndex = dataIndexes.indexOf(index);
                        let value = list[index] ? list[index] : 0;
                        types[index] === 'percent' && (value = +value * 100);
                        data[tempIndex] ? data[tempIndex].push(value) : data.push([value]);
                    }
                })
            })
            const temp: any = {
                labels: labels,
                datasets: []
            }
            dataIndexes.map((item: any, index: number) => {
                temp.datasets.push({
                    label: response.data.column[item].title,
                    data: data[index],
                    backgroundColor: `${colors[index]}`,
                })
                if (settings && settings.linear) {
                    temp.datasets[index].borderColor = `${colors[index]}`;
                    temp.datasets[index].borderWidth = 2;
                }
                if (settings && settings.fill) {
                    temp.datasets[index].fill = true;
                    temp.datasets[index].backgroundColor = `${colors[index]}65`;
                }
            })
            setData(temp);
            const tempOptions: any = {
                responsive: true,
                plugins: {
                    htmlLegend: {
                        containerRef: containerRef
                    },
                    legend: {
                        display: false,
                        position: `${positionLabels}` as const,
                    },
                    title: {
                        display: true,
                        text: response.item_title,
                    },
                    tooltip: tooltipLinear(typeValue,currency),
                }
            }
            if (settings && settings.linear) tempOptions.plugins.tooltip = tooltipLinear(typeValue,currency);
            if (settings && settings.bar) tempOptions.plugins.tooltip = tooltipBar(typeValue, currency);
            setOptions(tempOptions)
        }
    }
}

export const onLoadingSelectDate = ({
    period,
    setPeriodStart,
    setPeriodEnd,
    setValue,
    theDate,
    type
}: {
    period: ICustomReport['period'],
    setPeriodStart?: any,
    setPeriodEnd?: any,
    setValue?: any,
    theDate?: Date,
    type?: 'return' | 'set'
}) => {
    const date = theDate ? theDate : new Date();
    const data = {
        start: formatDate(getDateAgo(date, 1)),
        end: formatDate(getDateAgo(date, 1))
    }
    switch (period) {
        case "today": {
            data.start = formatDate(date);
            data.end = formatDate(date)
            break;
        }
        case "yesterday": {
            break;
        }
        case 'month': {
            let count_month_ago = 0;
            if (date.getDate() === 1)
                count_month_ago = 1;
            data.start = formatDate(new Date(date.getFullYear(), date.getMonth() - count_month_ago));
            break;
        }
        case 'previous_month': {
            const year = date.getFullYear(),
                month = date.getMonth();
            let count_month_ago = 1;
            if (date.getDate() === 1)
                count_month_ago = 2;
            data.start = formatDate(new Date(year, month - count_month_ago));
            data.end = formatDate(new Date(year, month - count_month_ago, 33 - new Date(year, month - count_month_ago, 33).getDate()));
            break;
        }
        case 'week': {
            data.start = date.getDay() === 1 ?
                formatDate(getDateAgo(getMonday(date), 7)) :
                formatDate(getMonday(date));
            break;
        }
        case 'previous_week': {
            let start_day_ago = 7,
                end_day_ago = 1;
            if (date.getDay() === 1) {
                start_day_ago = 14;
                end_day_ago = 8;
            }
            data.start = formatDate(getDateAgo(getMonday(date), start_day_ago));
            data.end = formatDate(getDateAgo(getMonday(date), end_day_ago));
            break;
        }
        case "year": {
            const year = getDateAgo(date, 1).getFullYear();
            data.start = formatDate(new Date(year, 0));
            break;
        }
        default:
            return;
    }
    if(type === 'return') return data;

    setPeriodStart(data.start);
    setPeriodEnd(data.end);
    setValue(`${data.start} - ${data.end}`);
}

export const onChangeFilterQuery = ({
        periodStart, periodEnd, model, group, attr_window, scale, eventTargetActive, filter, sepValue=','
    }:{
        periodStart?: string,
        periodEnd?: string,
        model?: IModelAttribution,
        group?: IGroupDash,
        attr_window?: IWindowAttribution,
        scale?: string,
        eventTargetActive?: Array<string>,
        filter?: IFilterMediaArray,
        sepValue?:string
    }) => {
    const newQueryParams = new URLSearchParams(window.location.search);
    if (periodStart && periodEnd) {
        newQueryParams.set('ps', periodStart);
        newQueryParams.set('pe', periodEnd);
    }
    if (model) {
        newQueryParams.set('m', model);
    }
    if (attr_window && WindowsArr(model).includes(attr_window)) {
        newQueryParams.set('w', String(attr_window));
    }
    if (scale) {
        newQueryParams.set('s', scale);
    }
    if(group) {
        newQueryParams.set('g', group);
    }
    if (Array.isArray(eventTargetActive) && eventTargetActive.length > 0) {
        newQueryParams.set('et', eventTargetActive.join(","));
    }
    if (Array.isArray(filter) && filter.length > 0) {
        const value = stringifyFilter(filter, '^*^', sepValue);
        newQueryParams.set('f', value);
    }

    return newQueryParams.toString();
};

export const validateModel = (value: string, defaultValue:any = '') => {
    if (value && ModelsArr().includes(value))
        return value;
    return defaultValue
}
export const validateScale = (value: string, defaultValue:any = '') => {
    if (value && ['day', 'week', 'quarter', 'month', 'year'].includes(value))
        return value;
    return defaultValue;
}
export const validateGroup = (value: string, defaultValue:any = '') => {
    if (value && ['campaign', 'source', 'placement'].includes(value))
        return value;
    return defaultValue;
}
export const validateWindow = (value: string|number, defaultValue?:number, model: IModelAttribution = 'mli'): IWindowAttribution|undefined => {
    if (isNumber(value)) {
        let temp = value;
        if(typeof temp === 'string') temp = +value;
        if(WindowsArr(model).includes(temp as IWindowAttribution))
            return temp as IWindowAttribution;
    }
    if(defaultValue) return validateWindow(defaultValue);
    return undefined;
}
import { FC, FormEvent, FocusEvent, Fragment, useCallback, useState } from 'react'
import { format, isValid as isValidDate, parse } from 'date-fns'
import { Form } from '../index'
import { Input, Select as SelectOld, Accordion } from '../../ui'
import Select from '../ui/Select'
import { getInitialState, validateAll, validateOne, validateUrlValue, formatDate } from '../../utils'
import {DefaultObject, IField, ISelectOption} from '../../interfaces/common.d'
import { IAccordion, IModalForm } from './ModalForm.d'
import './ModalForm.scss'
import {useAppSelector} from "../../redux/hooks";
import {currentUserLang} from "../../redux/slices/user";
import {trans} from "../../_locales";
import TagsAdd from "../Tags/Add";
import {Tag} from "../../redux/slices/tags";

const ModalForm: FC<IModalForm> = ({
  fields,
  initialValues,
  cb= (data) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const [name, value] of data) {
      console.log(`${name}: ${value}`);
    }
  },
  resetText,
  submitText,
  hasJsonFormat = false,
  onReset,
  hasScrollBlock,
  projectId,
  campaignId
}) => {
  const language = useAppSelector(currentUserLang);
  const [errors, setErrors] = useState(getInitialState<(IField | IAccordion)[], boolean>(
    fields as (IField | IAccordion)[],
    false
  ));
  const [disabled, setDisabled] = useState(getInitialState<(IField | IAccordion)[], boolean>(
    fields as (IField | IAccordion)[],
    false
  ));
  const [form, setForm] = useState(initialValues ? initialValues :
    getInitialState<(IField | IAccordion)[], string | any>(
      fields as (IField | IAccordion)[],
      ''
    ));

  const handleFormReset = (evt: any) => {
    evt.preventDefault();
    setForm(getInitialState<(IField | IAccordion)[], string>(
      fields as (IField | IAccordion)[],
      ''
    ));
    setErrors(getInitialState<(IField | IAccordion)[], boolean>(
      fields as (IField | IAccordion)[],
      false
    ))
  }

  const handleUrlFieldBlur = useCallback((
    evt: FocusEvent<HTMLInputElement>
  ) => {
    const name = evt.target.name;
    const value = evt.target.value;

    if (!errors[name] && Boolean(value)) {
      const { errorsUrl } =  validateUrlValue(
        fields as (IField | IAccordion)[],
        { [name]: value }
      );
      setErrors({ ...errors, ...errorsUrl })
    }

    const currentField: IField = fields?.filter((field) =>
      (field.tag === 'input' && field.name === name))[0] as IField;

    if (!currentField.isRequired && !value) {
      setErrors({ ...errors, ...{ [name]: false } })
    }
  }, [errors, fields])

  const handleFieldChange = useCallback((data: {[index: string]: string }, insertInOtherField?:DefaultObject<DefaultObject<string>>) => {
    const name = Object.keys(data)[0];
    let currentForm = { ...form, ...data };

    if(insertInOtherField) {
      const keyInsert = Object.values(data)[0] || '0';

      const temp = insertInOtherField[keyInsert];
      if(temp) {
        Object.keys(temp).map((key)=> {
          const field = fields?.filter((field) =>
              (field.tag === 'input' && field.name === key))[0] as IField;
          if(field) {
            currentForm = {...currentForm, ...{[field.name]: temp[key] ? temp[key] : ''}}
          }
        })
      }
    }
    setForm(currentForm);

    if (errors[name]) {
      const { errorsUrl } =  validateUrlValue(fields as (IField | IAccordion)[], currentForm);
      setErrors({ ...errors, ...validateOne(name, data[name]), ...errorsUrl })
    }
  }, [form, errors, fields]);

  const handleFormSubmit = useCallback((evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    const isValid = validateAll(form, errors, fields as (IField | IAccordion)[], setErrors);

    if (!isValid) return;

    if (hasJsonFormat) {
      let currentData = {};
      Object.entries(form).forEach(([name, value]) =>
        currentData = value ?
          Object.assign({}, currentData, {[name]: value}) :
          currentData
      );

      cb(currentData as unknown as JSON);
      return;
    }

    if ('start_date' in form || 'end_date' in form) {
      let formData = {};
      Object.entries(form)
        .forEach(([name, value]) => {
          if (name === 'start_date' || name === 'end_date') {
            const dateToForm = parse(value, 'y-MM-dd', new Date());

            if (!value && !isValidDate(dateToForm)) {
              formData = Object.assign(
                {},
                formData,
                { [name]: formatDate(new Date()) }
              )
            } else {
              formData = Object.assign(
                {},
                formData,
                { [name]: formatDate(dateToForm) }
              )
            }
            return;
          }

          if (value) {
            formData = Object.assign(
              {},
              formData,
              { [name]: value }
            )
          }
        });
      formData = JSON.stringify(formData);
      cb(formData as JSON);
    } else {
      let formData =  {};
      Object.entries(form)
        .forEach(([name, value]) => {
          formData = Object.assign(
              {},
              formData,
              { [name]: value }
            )
        });
      cb(formData as JSON);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, errors, fields, cb]);

  return (
    <Form
      resetText={resetText ? resetText : trans('Clear', language)}
      submitText={submitText ? submitText : trans('Create', language)}
      onSubmit={handleFormSubmit}
      onReset={onReset? onReset : handleFormReset}
      hasScrollBlock={hasScrollBlock}
    >
      {
        fields &&
        fields.length > 0 &&
        fields.map((field, i) => {
          const Wrap = field.tag === 'accordion' ? Accordion : Fragment;
          const wrapProps = field.tag === 'accordion' ?
            { title: field.title } : {};
          return (
            <Wrap
              key={`${field.tag}-${i}`}
              {...wrapProps}
            >
              <Fragment key={`Fragment-${i}`}>
                {
                  (field.tag === 'input' || field.tag === 'date') &&
                  <Input
                    key={`${field.name}-${i}`}
                    type={field.type}
                    name={field.name}
                    value={form[field.name]}
                    placeholder={field.placeholder}
                    label={field.label}
                    tooltip={field.tooltip}
                    tooltipSide={field.tooltipSide}
                    errorLabel={field.errorLabel}
                    required={field.isRequired}
                    isInvalid={errors[field.name]}
                    onChange={handleFieldChange}
                    onBlur={field.isUrl ? handleUrlFieldBlur : undefined}
                    isDateField={field.tag === 'date'}
                    isPasswordSwitch={field.isPasswordSwitch}
                    subBtn={field.subBtn}
                    tip={field.tip}
                    limitNumber={field.limitNumber}
                    limitSymbols={field.limitSymbols}
                    beta={field.beta}
                    isDisabled={disabled[field.name]}
                  />
                }
                {
                  field.tag === 'select' &&
                  <SelectOld
                    key={`${field.name}-${i}`}
                    name={field.name}
                    value={form[field.name]}
                    label={field.label}
                    tooltip={field.tooltip}
                    isInvalid={errors[field.name]}
                    required={field.isRequired}
                    isOpenUp={field.isOpenUp}
                    errorLabel={field.errorLabel}
                    hasAddButton={field.hasAddButton}
                    activeOption={field.activeOption}
                    options={field.options as ISelectOption[]}
                    onChange={handleFieldChange}
                    beta={field.beta}
                    projectId={projectId}
                  />
                }
                {
                  field.tag === 'select_new' &&
                  <Select
                    key={`${field.name}-${i}`}
                    className={'custom-select'}
                    name={field.name}
                    selectActive={form[field.name]}
                    placeholder={field.placeholder}
                    label={field.label}
                    tooltip={field.tooltip}
                    isInvalid={errors[field.name]}
                    required={field.isRequired}
                    errorLabel={field.errorLabel}
                    dataset={field.dataset as DefaultObject<string | number>}
                    onChangeActive={(data: {[index: string]: string })=>handleFieldChange(data, field.insertInOtherField)}
                    response={'name_key'}
                    colorText={'dark'}
                    resetBtn={field.resetBtn}
                    isDisabled={field.isDisabled}
                    search={field.isSearch}
                  />
                }
                {
                  field.tag === 'tags' &&
                  <TagsAdd
                    key={`${field.name}-${i}`}
                    name={field.name}
                    value={form[field.name]}
                    label={field.label}
                    tooltip={field.tooltip}
                    placeholder={field.placeholder}
                    isInvalid={errors[field.name]}
                    isRequired={field.isRequired}
                    errorLabel={field.errorLabel}
                    cb={(data: {[index: string]: Tag[] })=>{
                      const currentForm = { ...form, ...data };
                      setForm(currentForm)
                    }}
                    beta={field.beta}
                    projectId={projectId}
                    campaignId={campaignId}
                  />
                }
                {
                  field.tag === 'accordion' && field.content.map((field, i) =>
                    <Fragment key={`Fragment-${i}-${field}`}>
                      {
                        (field.tag === 'input' || field.tag === 'date') &&
                        <Input
                          key={`${field.name}-${i}`}
                          type={field.type}
                          name={field.name}
                          value={form[field.name]}
                          placeholder={field.placeholder}
                          required={field.isRequired}
                          label={field.label}
                          tooltip={field.tooltip}
                          tooltipSide={field.tooltipSide}
                          errorLabel={field.errorLabel}
                          isInvalid={errors[field.name]}
                          onChange={handleFieldChange}
                          isDateField={field.tag === 'date'}
                          beta={field.beta}
                        />
                      }
                      {
                        field.tag === 'select' &&
                        <SelectOld
                          key={`${field.name}-${i}`}
                          name={field.name}
                          value={form[field.name]}
                          label={field.label}
                          tooltip={field.tooltip}
                          isInvalid={errors[field.name]}
                          required={field.isRequired}
                          isOpenUp={field.isOpenUp}
                          errorLabel={field.errorLabel}
                          hasAddButton={field.hasAddButton}
                          activeOption={field.activeOption}
                          options={field.options as ISelectOption[]}
                          onChange={handleFieldChange}
                          beta={field.beta}
                          projectId={projectId}
                        />
                      }
                      {
                        field.tag === 'tags' &&
                        <TagsAdd
                          key={`${field.name}-${i}`}
                          name={field.name}
                          value={form[field.name]}
                          label={field.label}
                          tooltip={field.tooltip}
                          placeholder={field.placeholder}
                          isInvalid={errors[field.name]}
                          isRequired={field.isRequired}
                          errorLabel={field.errorLabel}
                          cb={(data: {[index: string]: Tag[] })=>{
                            const currentForm = { ...form, ...data };
                            setForm(currentForm)
                          }}
                          beta={field.beta}
                          projectId={projectId}
                          campaignId={campaignId}
                        />
                      }
                    </Fragment>
                  )
                }
              </Fragment>
            </Wrap>
          );
        })
      }
    </Form>
  );
};

export default ModalForm;

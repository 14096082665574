import { FC, useState, useRef, useEffect } from 'react'
import cx from 'classnames'
import { IFilter } from './Filter.d'
import { Btn, Toggle, Input } from '../../ui'
import { useOnClickOutside } from '../../helpers'
import { ReactComponent as FilterIcon } from '../../assets/icons/icon-filter.svg'
import './Filter.scss'
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {currentUserLang, userId} from "../../redux/slices/user";
import {trans} from "../../_locales";
import {sources} from "../../redux/slices/sources";
import {onChangeValueCheckbox, sendSdk} from "../../utils";
import Select from "../ui/Select";
import {getDashboardsFilterValues} from "../../actions/dashboards";
import {DefaultObject} from "../../interfaces/common.d";

export interface IFilterState {
  status: string[];
  status_creative: string[],
  tags: string[];
  sources: string[];
  purchaseTypes: string[];
  formats: string[];
  type_creative: string[];
  geo: string[];
  targeting: string[];
  dateRange: string;
}

const FilterUpgraded: FC<IFilter> = ({
  className,
  applyCallback,
  type,
  geoList={},
  targetingList={},
  projectId,
  campaignId,
  defaultData={},
  isDefaultSubmit=true,
  isOld
}) => {
  const language = useAppSelector(currentUserLang);
  const reduxSources = useAppSelector(sources);
  const reduxUserId = useAppSelector(userId);
  const dispatch = useAppDispatch();
  const [isActive, setIsActive] = useState(false);
  const [filterState, setFilterState] = useState<IFilterState>({
    status: defaultData.status || [],
    status_creative: defaultData.status_creative || [],
    tags: defaultData.tags || [],
    sources: defaultData.sources || [],
    purchaseTypes: [],
    formats: [],
    type_creative: defaultData.type_creative || [],
    geo: defaultData.geo || [],
    targeting: defaultData.targeting || [],
    dateRange: '',
  });
  const [datasetTags, setDatasetTags] = useState<DefaultObject<string>>({});
  const labels = {
    status: type && ['projects', 'campaigns', 'creatives'].includes(type) ? 'Status' : 'Placement status',
    status_creative: 'Creative status',
    tags: 'Tags',
    sources: 'Source',
    purchaseTypes: 'Purchase type',
    formats: 'Format',
    type_creative: 'Pixel type',
    geo: 'Geo',
    targeting: 'Targeting',
    dateRange: 'Date range',
  }
  const typeDataset = {
      'tracking': trans('Tracking pixel', language),
      'adserving_video': trans('Adserving video', language),
      'adserving_static': trans('Adserving static', language)
  }

  const filterRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(() => setIsActive(false), [filterRef]);

  useEffect(() => {
    if (isDefaultSubmit) applyCallback?.(filterState);

    if (type === 'placements' && projectId) {
      dispatch(getDashboardsFilterValues({ projectId, name: 'tags' })).then((r) => {
        setDatasetTags(r.payload || {});
      });
    }
  }, []);

  const handleToggle = (group: keyof IFilterState, value: string) => {
    setFilterState((prev) => {
      const prevGroupValue = prev[group];
      if (Array.isArray(prevGroupValue)) {
        return {
          ...prev,
          [group]: prevGroupValue.includes(value)
              ? prevGroupValue.filter((v) => v !== value)
              : [...prevGroupValue, value],
        };
      }
      return prev;
    });
  };


  const handleApply = () => {
    applyCallback?.(filterState);
    setIsActive(false);

    sendSdk('event', {
      event_type: 'filter',
      event_category: type || 'projects',
      event_name: 'active',
      event_value: '10',
      event_params: {
        user_id: reduxUserId,
        project_id: projectId,
        campaign_id: campaignId,
      },
    });
  };

  const handleClear = () => {
    const cleared = {
      status: [],
      status_creative: [],
      tags: [],
      sources: [],
      purchaseTypes: [],
      formats: [],
      type_creative: [],
      geo: [],
      targeting: [],
      dateRange: '',
    };
    setFilterState(cleared);
    applyCallback?.(cleared);
    setIsActive(false);
  };

  const renderToggleGroup = (group: keyof IFilterState, options: DefaultObject<string>) => (
    <fieldset>
      <legend className="filter__subtitle">{trans(labels[group], language)}</legend>
      {Object.entries(options).map(([key, label]) => {
        if (group === 'status' && key === 'hold') return null;
        return(
        <Toggle
            key={key}
            type="checkbox"
            label={label}
            name={`filter-${key}`}
            value={`filter-${key}`}
            isChecked={filterState[group].includes(key)}
            onChange={() => handleToggle(group, key)}
        />
        )
      })}
    </fieldset>
  );

  return (
    <div className={cx('filter', className, { 'is-active': isActive })} ref={filterRef}>
      <Btn icon={{ Component: FilterIcon, width: 16, height: 18 }} dot={true} active={Boolean(filterState.status.length)} isIconBtn={true} size="lg" onClick={() => setIsActive(!isActive)} />
      <div className="filter__wrapper scrolled">
        <h2 className="title title--uppercase">{trans('Filter', language)}</h2>
        <div className="form">
          {(type !== 'creatives' || (type==='creatives' && isOld)) && renderToggleGroup(
              'status',
              {
                active: trans('Actives', language),
                // hold: trans('Hold', language),
                archive: trans('Archived', language)
              }
          )}
          {type !== 'campaigns' && !isOld && renderToggleGroup(
              'status_creative',
              {
                active: trans('Actives', language),
                archive: trans('Archived', language)
              }
          )}

          {type === 'placements' && (
              <>
                {/*<Input*/}
                {/*  type="text"*/}
                {/*  name="dateRange"*/}
                {/*  value={filterState.dateRange}*/}
                {/*  placeholder={trans('Date range', language)}*/}
                {/*  onChange={({ target }) => setFilterState((prev) => ({ ...prev, dateRange: target.value }))}*/}
                {/*  isDateRange={true}*/}
                {/*/>*/}
                <fieldset>
                <Select
                    label={trans('Tags', language)}
                    color='light-blue'
                    colorText='dark'
                    widthAuto={false}
                    checkbox={true}
                    selectActive={filterState.tags.join(', ')}
                    dataset={datasetTags}
                    onChangeActive={(res: any) => {
                      const {activeList} = onChangeValueCheckbox(res);
                      setFilterState(prev => ({...prev, tags: activeList}));
                    }}
                    listActive={filterState.tags}
                    search={true}
                    className={'custom-input--search'}
                    position={'right'}
                    maxChecked={5}
                />
                </fieldset>
                <fieldset>
                <Select
                    label={trans('Source', language)}
                    color='light-blue'
                    colorText='dark'
                    widthAuto={false}
                    checkbox={true}
                    selectActive={filterState.sources.join(', ')}
                    dataset={reduxSources.reduce((acc, source) => ({...acc, [source.name]: source.name}), {})}
                    onChangeActive={(res: any) => {
                      const {activeList} = onChangeValueCheckbox(res);
                      setFilterState(prev => ({...prev, sources: activeList}));
                    }}
                    listActive={filterState.sources}
                    search={true}
                    className={'custom-input--search'}
                    position={'right'}
                    maxChecked={5}
                />
                </fieldset>
                {/*{renderToggleGroup('purchaseTypes', { cpm: 'CPM', cpc: 'CPC', cpv: 'CPV', fix: 'FIX' })}*/}
              </>
          )}

          {((type == 'placements' && !isOld) || type === 'creatives') && (
              <>
                {renderToggleGroup('type_creative', typeDataset)}
              </>
          )}
          {(type === 'creatives') && (
              <>
                {Object.keys(geoList).length > 0 && renderToggleGroup('geo', geoList)}
                {Object.keys(targetingList).length > 0 && renderToggleGroup('targeting', targetingList)}
              </>
          )}

          <div className={'filter__bottom'}>
            <Btn text={trans('Apply', language)} color="blue" size="sm" onClick={handleApply}/>
            <Btn text={trans('Clear', language)} size="sm" onClick={handleClear}/>
          </div>
        </div>
      </div>
    </div>
  );
};


export default FilterUpgraded;
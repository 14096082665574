import { configureStore } from '@reduxjs/toolkit'
import { createAPI } from '../services/api'
import { showPopup } from '../utils'
import { ERROR_NETWORK_MESSAGE, PopupType, ERROR_DETAIL_MESSAGE } from '../data'

import counterReducer from './slices/counter'
import modalsReducer from './slices/modals'
import userReducer, { clearUser } from './slices/user'
import projectReducer, {clearProject, clearProjectAttributions, clearProjectGeneral} from './slices/project'
import projectsReducer, { clearProjects } from './slices/projects'
import eventsReducer, { clearEvents } from './slices/events'
import mediaReducer, { clearCampaigns } from './slices/media'
import sourcesReducer, {clearSources} from './slices/sources'
import placementsReducer, { clearPlacements } from './slices/placements'
import breadcrumbsReducer, {clearBreadcrumbs} from './slices/breadcrumbs'
import sidebarSlice from './slices/sidebar'
import popupReducer from './slices/popup'
import accessReducer from './slices/access'
import warningsSlice from "./slices/warnings";
import uploadSlice from "./slices/upload";
import dashboardsSlice from "./slices/dashboards";
import pagesSlice, {
    clearProjects as clearPageProjects,
    clearCampaigns as clearPageCampaigns,
    clearPlacement as clearPagePlacement,
    clearCreatives as clearPageCreatives
} from "./slices/pages";
import reportsSlice from "./slices/reports";
import tagsSlice, {clearTags} from "./slices/tags";
import tokensSlice from "./slices/tokens";
import instructionsSlice from "./slices/instructions";
import rolesSlice from "./slices/roles";
import logsSlice from "./slices/logs";
import {setLogData} from "../actions/logs";
import marketingReducer, {clearMarketing} from "./slices/marketing";


export const API = createAPI(
  () => {
    store.dispatch(clearUser());
    store.dispatch(clearProject());
    store.dispatch(clearProjects());
    store.dispatch(clearMarketing());
    store.dispatch(clearCampaigns());
    store.dispatch(clearPlacements());
    store.dispatch(clearProjectGeneral());
    store.dispatch(clearProjectAttributions());
    store.dispatch(clearTags());
    store.dispatch(clearSources());
    store.dispatch(clearBreadcrumbs());
    store.dispatch(clearPageProjects());
    store.dispatch(clearPageCampaigns());
    store.dispatch(clearPagePlacement());
    store.dispatch(clearPageCreatives());
  },
  () => showPopup(
    store.dispatch,
    ERROR_NETWORK_MESSAGE,
    PopupType.ERROR
  ),

  (data) => {
      if (window.location.hostname.includes('localhost')) {
          //skip
      } else if (window.location.hostname.includes('dev')) {
          //skip
      } else if (window.location.hostname.includes('app')) {
          store.dispatch(setLogData({data}));
      }
  },
  () => showPopup(
    store.dispatch,
    ERROR_DETAIL_MESSAGE,
    PopupType.ERROR
  )
);



export const store = configureStore({
  reducer: {
    counter: counterReducer,
    modals: modalsReducer,
    user: userReducer,
    projects: projectsReducer,
    project: projectReducer,
    events: eventsReducer,
    marketing: marketingReducer,
    media: mediaReducer,
    sources: sourcesReducer,
    placements: placementsReducer,
    breadcrumbs: breadcrumbsReducer,
    sidebar: sidebarSlice,
    popup: popupReducer,
    access: accessReducer,
    warnings: warningsSlice,
    upload: uploadSlice,
    dashboards: dashboardsSlice,
    pages: pagesSlice,
    reports: reportsSlice,
    tags: tagsSlice,
    tokens: tokensSlice,
    instructions: instructionsSlice,
    logs: logsSlice,
    roles: rolesSlice
  },



  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: API,
      },
      serializableCheck: false,
    }),
  devTools: true
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

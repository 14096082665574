import {FC, useEffect, useState} from 'react'
import {Outlet, useNavigate, useParams} from "react-router-dom";
import { IBase } from './Base.d'
import {Header, Loader, SideMenu} from '../../components'
import cls from "./Base.module.scss";
import {useAppSelector} from "../../redux/hooks";
import {getIsLoading as userIsLoading} from "../../redux/slices/user";
import Notification from "../../components/Notification";
import "../style.scss";
import {marketingCurrent} from "../../redux/slices/marketing";
// import ErrorBoundary from "../../components/ErrorBoundary";

const headerDefaultData = {
  breadcrumbsData:{
    data: [
      {
        href: '#',
        title: 'Проект',
      }
    ]
  },
  userMenuData: {
    notify: 2,
    userName: 'Michael James',
  },
}

const Base: FC<IBase> = ({
  children,
  headerData=headerDefaultData,
  sidebarData,
  border,
  label
}) => {
  const navigate = useNavigate();
  const userDataIsLoading = useAppSelector(userIsLoading);
  const reduxCurrentMarketing = useAppSelector(marketingCurrent);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isOpenNote, setIsOpenNote] = useState(false);
  const {projectId, marketingId, sourceId, placementId} = useParams();
  const [name, setName] = useState('');
  useEffect(() => {
    setIsFirstLoading(false);
    if(sessionStorage.getItem('note')) {
      setIsOpenNote(false);
    } else setIsOpenNote(true);
  }, []);
  useEffect(() => {
    if (localStorage.getItem('token') === null) {
      navigate('/');
    }
  }, [navigate]);
  useEffect(() => {
    let currentSource, currentPlacement;
    if(sourceId&&reduxCurrentMarketing?.sources) {
      currentSource = reduxCurrentMarketing.sources.filter((item)=>item.id === +sourceId)[0];
      if(currentSource && placementId && currentSource.placements) {
        currentPlacement= currentSource.placements.filter((item)=>item.id === +placementId)[0];
      }
    }
    if(currentPlacement?.name) {
      setName(currentPlacement.name)
    }else if(currentSource?.name) {
      setName(currentSource.name)
    }
  }, [reduxCurrentMarketing, sourceId, placementId]);

  const [isHovered, setIsHovered] = useState(false);
  const notification = "";
  // const notification = "2 Августа с 10:00 до 12:00 МСК на сайте пройдут технические работы. Приносим извинения за доставленные неудобства."
  const onCloseNote = () => {
    sessionStorage.setItem('note', '1');
    setIsOpenNote(false);
  }
  return(
    // <ErrorBoundary>
      <div className='wrapper'>
        {
          isOpenNote && notification && <Notification text={notification} onClose={onCloseNote}/>
        }
        <Header {...headerData}/>
        {sidebarData ?
          <div className={`${cls.page_wrapper} page-content`}>
            <h1 className="visually-hidden">Проекты</h1>
            <div className="container">
              <div className={`page-content__nav ${isHovered ? 'active': ''}`}
                // onMouseEnter={()=>setIsHovered(true)}
                // onMouseLeave={()=>setIsHovered(false)}
              >
                <SideMenu btnsList={sidebarData} hideFullVersion={()=>setIsHovered(false)} alt={true}/>
              </div>
              {
                !isFirstLoading &&
                <div className={`${cls.settings_container}`}>
                  { label &&
                    <div className={'base-label'}>
                      {label.title && <h1>{label.title}</h1>}
                      {label.typeId === 'project' && <p>ID проекта: {projectId}</p>}
                      {label.typeId === 'campaign' && <>
                        <h1>{reduxCurrentMarketing?.name}</h1>
                        <p>ID кампании: {marketingId}</p>
                      </>}
                      {label.typeId === 'source' && <>
                        {name && <h1>{name}</h1>}
                        <p>ID источника: {sourceId}</p>
                      </>}
                      {label.typeId === 'placement' && <>
                        {name && <h1>{name}</h1>}
                        <p>ID размещения: {placementId}</p>
                      </>}
                    </div>
                  }
                  <div className={`base__wrapper ${cls.settings_wrapper} ${border && cls.wrapper_border}`}>
                    {children ? children : <Outlet />}
                  </div>
                </div>
              }
            </div>
          </div>
          :
          <div className={`base__wrapper ${cls.settings_wrapper} ${border && cls.wrapper_border}`}>
            {children ? children : <Outlet />}
          </div>
        }
        {(userDataIsLoading) &&
          <Loader isNotTransparent={true}/>}
      </div>
    // </ErrorBoundary>
  )
}

export default Base

import React, {FC, useEffect, useRef, useState} from "react";
import {
    ITableCheckedTypes,
    ITableFunnelData,
    ITableFunnelFilter,
    ITableFunnelSize,
    ITableFunnelStatus
} from "../../index";

import "../style.scss";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {currentUser, currentUserLang} from "../../../../../redux/slices/user";
import {DefaultObject, ICampaign, IPlacement, ISources, ItemStatus} from "../../../../../interfaces/common.d";
import {IPinColumns} from "../../../interfaces";
import {IMarketingItem, setCurrentMarketing} from "../../../../../redux/slices/marketing";
import {getColorValue} from "../../../TablePagination/functions";
import {keyToSkip} from "../../../../../data";
import {getStylesItem} from "../../functions";
import {Toggle} from "../../../../../ui";
import TableFunnelBodyItem from "../Item";
import {trans} from "../../../../../_locales";
import {ModalName, openModal} from "../../../../../redux/slices/modals";
import {sendSdk} from "../../../../../utils";
import {changeCampaignStatus} from "../../../../../actions/media";
import {Popup} from "../../../../index";
import { ReactComponent as DotsIcon } from '../../../../../assets/icons/icon-dots.svg'
import {useNavigate, useParams} from "react-router-dom";
import cx from "classnames";
import {IViewMode} from "../../../../../pages/Projects/Project/MarketingCampaigns/Item";

interface ITableFunnelBodyRow {
    type: ITableCheckedTypes,
    onCreate: ()=>void
    onCreateBased?: (id: number)=>void
    onCreateDaughter?: (id: number)=>void
    onUpload?: (event: any)=>void
    isDisabled?: boolean,

    activePin?: IPinColumns,
    choiceActive?: string[],
    settingSize?: ITableFunnelSize,
    onClearFilter?: ()=>void,
    onEdit: (id:number, data: IMarketingItem|ICampaign|IPlacement|ISources)=>void,
    onChangeStatus: (id:number, status: ITableFunnelStatus, type?: ITableCheckedTypes)=>void,
    isActiveChecked?: boolean
    onChecked: (id:number, flag: boolean, type: ITableCheckedTypes) => void
    item: IMarketingItem|ICampaign|IPlacement|ISources
    link?: string,
    onChangeParent?: (parent: IViewMode, id?: number) => void,
    level:number,
    isCreate?: boolean,
    isEdit?: boolean,
}

const TableFunnelBodyRow:FC<ITableFunnelBodyRow> = ({
    type,
    activePin,
    choiceActive,
    settingSize,
    onEdit,
    onChangeStatus,
    onCreate,
    onCreateBased,
    onCreateDaughter,
    onUpload,
    isActiveChecked=false,
    onChecked,
    item,
    link,
    onChangeParent,
    level,
    isCreate,
    isEdit
}) => {
    const language = useAppSelector(currentUserLang);
    const reduxUser = useAppSelector(currentUser);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {projectId, marketingId, sourceId} = useParams();
    const [isActiveSetting, setIsActiveSetting] = useState(false);

    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;

    const [currentIsCreate, setCurrentIsCreate] = useState(isCreate);
    const [currentIsEdit, setCurrentIsEdit] = useState(isEdit);

    const refChoice = useRef<HTMLDivElement>(null);
    const refSetting = useRef<HTMLDivElement>(null);

    const onRowClickHandler: React.MouseEventHandler<HTMLTableRowElement> = (evt) => {
        if(link) {
            const target = evt.target;
            const selection = document.getSelection();
            const isCheckbox = target === refChoice.current || refChoice.current?.contains(target as Node);
            const isSetting = target === refSetting.current || refSetting.current?.contains(target as Node);
            if ((!selection || selection.toString().length === 0) && !isCheckbox && !isSetting) {
                if(item.data_type === 'campaigns') {
                    dispatch(setCurrentMarketing(item));
                    navigate(link);
                }
                if(item.data_type === 'sources' && projectId && marketingId) {
                    // if(typeof onChangeParent === 'function') {
                    //     onChangeParent('sources', item.id);
                    // }
                    navigate(`/projects/${projectId}/marketing/${marketingId}/${item.id}/`)
                }
                if(item.data_type === 'placements' && projectId && marketingId) {
                    if(typeof onChangeParent === 'function') {
                        onChangeParent('placements', item.id);
                    }
                    navigate(`/projects/${projectId}/marketing/${marketingId}/${item.source_id}/${item.id}/`)
                    // navigate(`/projects/12780/campaigns/${item.id}/platforms`)
                }
            }
        }
    }

    const contentClassName = cx(
        'table-funnel-body-row',
        {
            'archive': "status" in item && item.status === ItemStatus.ARCHIVE,
            'is-link': !!link,
        }
    )

    useEffect(()=> {
        if(projectId && permissions) {
            let resource = 'campaigns';
            if(item.data_type === 'campaigns') {
                resource = 'marketing';
            }
            if(item.data_type === 'sources') {
                resource = 'sources';
            }
            const tempIsCreate = permissions && permissions['can_create'] && permissions['can_create'].includes(resource);
            const tempIsEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes(resource);
            setCurrentIsCreate(tempIsCreate);
            setCurrentIsEdit(tempIsEdit)
        }
    }, [permissions, projectId])

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
            className={contentClassName}
            onClick={onRowClickHandler}
        >
            <div className='table-funnel-choice' ref={refChoice}>
                <Toggle
                    name='all-choice'
                    borderMod={true}
                    onChange={(evt: any) => {
                        onChecked(item.id, evt.currentTarget.checked, type);
                    }}
                    isChecked={isActiveChecked}
                />
            </div>
            {/*{(choiceActive as Array<keyof (IMarketingItem|ICampaign|IPlacement|ISources)>).map((key, itemIndex: number) => {*/}
            {(choiceActive as Array<keyof IMarketingItem|keyof ICampaign|keyof IPlacement|keyof ISources>).map((key, itemIndex: number) => {
                    return (
                        <TableFunnelBodyItem
                            key={itemIndex}
                            name={key}
                            index={itemIndex}
                            value={key !== 'tags' && key in item ? (item as any)[key] : ''}
                            activePin={activePin}
                            settingSize={settingSize}
                            isStretch={key === 'name'}
                            level={level}
                            type={item.data_type || 'campaigns'}
                        />
                    )
                }
            )}
            <div className={`table-funnel-setting ${isActiveSetting ? 'active' : ''}`} ref={refSetting}>
                {item.data_type !== 'sources' && (currentIsCreate || currentIsEdit) && <Popup
                    className={'table-funnel-setting__popup'}
                    btn={{
                      icon: {
                        Component: DotsIcon,
                        width: 5,
                        height: 20
                      },
                      isIconBtn: true,
                      size: 'lg'
                    }}
                    onChangeActive={setIsActiveSetting}
                    dropdownList={(()=>{
                        const temp = [];
                        if(typeof onCreateBased === 'function' && currentIsCreate) {
                            temp.push({
                                text: trans('Create based on', language),
                                onClick: () => onCreateBased(item.id)
                            })
                        }
                        if(typeof onCreateDaughter === 'function' && item.data_type === 'placements' && currentIsCreate) {
                            temp.push({
                                text: trans('Add a creative pixel', language),
                                onClick: () => {
                                    onCreateDaughter(item.id);
                                }
                            })
                        }
                        if(typeof onEdit === 'function' && currentIsEdit) {
                            temp.push({
                                text: trans('Edit', language),
                                onClick: () => {
                                    onEdit(item.id, item);
                                }
                            })
                        }
                        if(typeof onChangeStatus === 'function' && "status" in item && currentIsEdit) {
                            temp.push({
                                text: item.status === ItemStatus.ARCHIVE ? trans('Activate', language) : trans('Archive', language),
                                onClick: () => {
                                    if (item.status === ItemStatus.ARCHIVE) {
                                        onChangeStatus(item.id, 'active', item.data_type);
                                    } else {
                                        onChangeStatus(item.id, 'archive', item.data_type);
                                    }
                                }
                            })
                        }
                        return {
                            items: temp,
                            isNew: true
                        };
                    })()}
                  />}
            </div>
        </div>
    )
}

export default TableFunnelBodyRow;
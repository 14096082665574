import {FC} from "react";
import {trans} from "../../_locales";
import {Btn} from "../../ui";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {currentUserLang} from "../../redux/slices/user";
import {ReactComponent as PlusIcon} from '../../assets/icons/icon-plus.svg'
import "./style.scss";


interface INotFoundElements {
    text: string
    onCreate: ()=>void
    onUpload?: (event: any)=>void
    isCreate?: boolean
}

const NotFoundElements:FC<INotFoundElements> = ({
    text='Objects',
    onCreate=()=>null,
    onUpload,
    isCreate=false
}) => {
    const language = useAppSelector(currentUserLang);
    return(
        <div className="not-founds-elements">
            <h2 className="not-founds-elements__title">
                {trans('Create your', language)}<br/>{trans(text, language)}
            </h2>
            {isCreate && <Btn
                className={'not-founds-elements__action'}
                text={trans('Create', language)}
                icon={{
                    Component: PlusIcon,
                    width: 12,
                    height: 12,
                }}
                color='blue'
                size='md'
                onClick={onCreate}
                disabled={!isCreate}
            />}
            {typeof onUpload === 'function' && <Btn
                className={'not-founds-elements__link'}
                text={trans('Upload Excel', language)}
                color='light-blue'
                onFileChosen={onUpload}
                isDownloadBtn={true}
                fileType={'xlsx'}
                disabled={!isCreate}
            />}
        </div>
    )
}

export default NotFoundElements;
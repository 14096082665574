import {ITableFunnelDefaultSize, ITableFunnelSize} from "./index";
import {IPinColumns} from "../interfaces";
import {DefaultObject, ICampaign, IPlacement, ISources} from "../../../interfaces/common.d";
import {isNumber} from "../../../utils";
import {IMarketingItem} from "../../../redux/slices/marketing";

export const getStylesItem = ({key, settingSize, activePin}:{key: string, settingSize?: ITableFunnelSize, activePin?: IPinColumns}) => {
    const size = settingSize && settingSize[key] ? settingSize[key] : undefined;
    const left = activePin && activePin[key] && activePin[key].left ? `${activePin[key].left}px` : undefined;
    return {
        minWidth: size ?? '150',
        width: size,
        maxWidth: size,
        left: left
    }
}

/**
 * Функция принимает:
 *  - defaultSettingSize: настройки по умолчанию (например { "name": "auto", "description": 200, "other": "40%", ... })
 *  - tableWidth: общая ширина блока (например, ширина контейнера)
 *  - minWidth: минимальная ширина для каждой колонки (по умолчанию 0)
 *  - settingSize: объект с уже «зафиксированными» ширинами (например { "name": 400, "description": 200, "other": 300, ... })
 *  - choice: список имён полей, участвующих в расчёте `auto` (например ["name", "other"])
 *
 * Возвращает объект, в котором:
 *  1. Все значения переведены в пиксели и не меньше `minWidth`.
 *  2. Поля из `choice` с `auto` получают остаток по формуле leftover / countAuto,
 *     а поля не из `choice` с `auto` получают просто minWidth.
 *  3. Если в settingSize для поля указано число больше рассчитанного, берётся это значение.
 */

interface IComputedTableSizes {
    defaultSettingSize?: ITableFunnelDefaultSize,
    width?: number,
    minWidth: number,
    settingSize?: ITableFunnelSize,
    choice?: string[]
}


/**
 * Превращает число или процентную строку в пиксели,
 * если это число => просто возвращает,
 * если строка вида "40%" => переводит в px на основе containerWidth.
 * Если ничего не подошло — возвращается fallback.
 */
const toPx = (
  value: string | number,
  containerWidth: number,
  fallback = 0
): number => {
  if (typeof value === 'number') {
    return value;
  }

  const trimmed = value.trim();
  if (trimmed.endsWith('%')) {
    const percentNumber = parseFloat(trimmed);
    if (!isNaN(percentNumber)) {
      return (percentNumber / 100) * containerWidth;
    }
  }

  return fallback;
}

/**
 * Зажимает значение (valuePx) в диапазон [minPx, maxPx].
 */
const clampPx = (
    valuePx: number,
    minPx: number,
    maxPx: number
): number => {
  if (valuePx < minPx) return minPx;
  if (valuePx > maxPx) return maxPx;
  return valuePx;
}

/**
 * Функция рассчитывает размеры колонок в пикселях.
 *
 * - defaultSettingSize: объект вида {"name": {default:'auto', min:20,max:'70%'}, ... }
 * - width: общая ширина контейнера
 * - minWidth: минимальное значение для каждой колонки
 * - settingSize: объект с «зафиксированными» значениями (число в пикселях),
 *                которые могут перекрыть расчёт, если они больше.
 * - choice: список имён полей, среди которых распределяются «auto».
 *
 * Возвращает объект, где каждое поле — число пикселей в границах [min, max].
 */
export const computeTableSizes = ({
  defaultSettingSize,
  width,
  minWidth,
  settingSize,
  choice = [],
}: IComputedTableSizes): ITableFunnelSize => {
  // Если нет корректных данных, возвращаем пустой объект
  if (!defaultSettingSize || typeof defaultSettingSize !== 'object' || !width) {
    return {};
  }

  const choiceSet = new Set(choice);
  const fixBorderWidth = 26+28 +96+26;

  const result: ITableFunnelSize = {};

  // Эти массив/счётчики нужны для расчёта leftover среди выбранных полей (choice).
  const autoKeysInChoice: string[] = [];
  let usedWidthInChoice = 0;
  let autoCountInChoice = 0;

  // 1. Проходимся по ключам defaultSettingSize
  for (const key in defaultSettingSize) {
    const { default: defVal, min: minVal, max: maxVal } =
      defaultSettingSize[key];

    // Преобразуем minVal, maxVal в пиксели
    // (если там строка "%", переведётся в px)
    const minPx = Math.max(toPx(minVal, width), minWidth); // учитываем глобальный minWidth
    // Можно ещё дополнительно Math.max(minPx, minWidth), если требуется
    const maxPx = toPx(maxVal, width, width); // если не парсится, пусть будет как максимум = width

    // Проверяем, что minPx не больше maxPx (редкий случай: "90%" min, "50%" max),
    // но если такое случится, можно зафиксировать maxPx = minPx
    const realMinPx = Math.min(minPx, maxPx);
    const realMaxPx = Math.max(minPx, maxPx);

    // --- Если ключ входит в choice ---
    if (choiceSet.has(key)) {
      if (isNumber(defVal)) {
        // 1.a Число
        let widthPx = +defVal;
        widthPx = clampPx(widthPx, realMinPx, realMaxPx);
        result[key] = widthPx;
        usedWidthInChoice += widthPx;
      } else if (
        typeof defVal === 'string' &&
        defVal.trim().endsWith('%')
      ) {
        // 1.b Процент
        let widthPx = toPx(defVal, width);
        widthPx = clampPx(widthPx, realMinPx, realMaxPx);
        result[key] = widthPx;
        usedWidthInChoice += widthPx;
      } else if (defVal === 'auto') {
        // 1.c auto => отложим
        autoKeysInChoice.push(key);
        autoCountInChoice++;
      } else {
        // На случай, если что-то другое (не число, не %, не 'auto')
        // по логике, наверное, трактуем как 0 или min
        result[key] = realMinPx; // Или clampPx(0, realMinPx, realMaxPx)
        usedWidthInChoice += result[key];
      }
    }
    // --- Если ключ НЕ входит в choice ---
    else {
      // Все поля вне choice считаем сразу, но не участвуют в leftover
      if (isNumber(defVal)) {
        let widthPx = +defVal;
        widthPx = clampPx(widthPx, realMinPx, realMaxPx);
        result[key] = widthPx;
      } else if (
        typeof defVal === 'string' &&
        defVal.trim().endsWith('%')
      ) {
        let widthPx = toPx(defVal, width);
        widthPx = clampPx(widthPx, realMinPx, realMaxPx);
        result[key] = widthPx;
      } else if (defVal === 'auto') {
        // Полям auto вне choice ставим их минимальное значение (realMinPx)
        result[key] = realMinPx;
      } else {
        result[key] = realMinPx;
      }
    }
  }

  // 2. Распределяем leftover среди полей-автоматов, входящих в choice
  if (autoCountInChoice > 0) {
    const leftover = Math.max(width - usedWidthInChoice - fixBorderWidth, 0);
    const autoWidth = leftover / autoCountInChoice;

    // Для каждого auto-ключа в choice зажимаем значение в [min, max]
    for (const key of autoKeysInChoice) {
      const { min: minVal, max: maxVal } = defaultSettingSize[key];
      const minPx = toPx(minVal, width);
      const maxPx = toPx(maxVal, width, width);
      const realMinPx = Math.min(minPx, maxPx);
      const realMaxPx = Math.max(minPx, maxPx);

      result[key] = clampPx(autoWidth, realMinPx, realMaxPx);
    }
  }

  // 3. Учитываем settingSize: если в нём для поля число, которое
  //    больше рассчитанного, берём его, но тоже зажимаем по [min, max].
  if (settingSize && typeof settingSize === 'object') {
    for (const key in settingSize) {
      const val = settingSize[key];
      if (isNumber(val)) {
        if (key in defaultSettingSize) {
          const { min: minVal, max: maxVal } = defaultSettingSize[key];
          const minPx = toPx(minVal, width);
          const maxPx = toPx(maxVal, width, width);
          const realMinPx = Math.min(minPx, maxPx);
          const realMaxPx = Math.max(minPx, maxPx);

          // Если val > уже рассчитанного, то берём val (с clamping)
          if (val > (result[key] ?? 0)) {
            result[key] = clampPx(val, realMinPx, realMaxPx);
          }
        } else {
          // Если такого ключа нет в defaultSettingSize, берём только с учётом global minWidth
          result[key] = Math.max(val, minWidth);
        }
      }
    }
  }

  return result;
};


interface IHandleMouseMove {
    e:any,
    current:any,
    settingSize?:ITableFunnelSize,
    defaultSettingSize?: ITableFunnelDefaultSize,
    width: number
}

export const handleMouseMove = ({
    e,
    current,
    settingSize = {},
    defaultSettingSize = {},
    width
}:IHandleMouseMove) => {
    if (!current)
        return;
    e = e || window.event;
    const minWidthCol = toPx(defaultSettingSize[current.name].min, width);
    const maxWidthCol = toPx(defaultSettingSize[current.name].max, width, width);
    const value = clampPx(e.clientX - current.x, minWidthCol, maxWidthCol)
    const temp = JSON.parse(JSON.stringify(settingSize));
    temp[current.name] = value;

    return temp;
}

export interface IStretchSettingItem {
    name: string,
    el: Element | null,
    x: number,
    y: number,
}


export const onStretchBlock = ({
    e,
    name
}:{
    e: any,
    name: string
})=> {
    e = e || window.event;
    const el = (e.srcElement || e.target).parentNode;

    const result: IStretchSettingItem = {
        'name': name,
        'el': el,
        'x': e.clientX - el.offsetWidth,
        'y': e.clientY - el.offsetHeight
    };

    return result;
}


export const gatherIdsByType = (dataWrapper: (IMarketingItem|ICampaign|IPlacement|ISources)[]) => {
  const campaignIds: number[] = [];
  const sourceIds: number[] = [];
  const placementIds: number[] = [];
  const creativeIds: number[] = [];

  dataWrapper.forEach((item) => {
    campaignIds.push(item.id);
    if(item.data_type === 'campaigns') {
        item.sources?.forEach((src: any) => {
            sourceIds.push(src.id);

            src.placement?.forEach((pl: any) => {
                placementIds.push(pl.id);

                pl.creatives?.forEach((cr: any) => {
                    creativeIds.push(cr.id);
                });
            });
        });
    }
    if(item.data_type === 'sources') {
        sourceIds.push(item.id);
        item.placements?.forEach((pl: any) => {
            placementIds.push(pl.id);

            pl.creatives?.forEach((cr: any) => {
                creativeIds.push(cr.id);
            });
        });
    }
    if(item.data_type === 'placements') {
        placementIds.push(item.id);

        item.creatives?.forEach((cr: any) => {
            creativeIds.push(cr.id);
        });
    }
    if(item.data_type === 'creatives') {
        creativeIds.push(item.id);
    }
  });

  return { campaignIds, sourceIds, placementIds, creativeIds };
}
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {ICampaign} from '../../interfaces/common.d'
import { RootState } from '..'
import {
  downloadCurrentCampaignData
} from '../../actions/breadcrumbs';
import {IBreadcrumbsItem} from "../../ui/Breadcrumbs/Breadcrumbs.d";

interface BreadcrumbsState {
  data: IBreadcrumbsItem[]
  currentCampaign: ICampaign | null
  isLoading: boolean
  error: string | null
}

const initialState: BreadcrumbsState = {
  data: [],
  currentCampaign: null,
  isLoading: false,
  error: null,
}

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setCurrentCampaign: (state, action) => {
      state.currentCampaign = action.payload;
    },
    clearCurrentCampaign: (state) => {
      state.currentCampaign = null;
    },
    setBreadcrumbs: (state, action: PayloadAction<IBreadcrumbsItem[]>) => {
      state.data = action.payload;
    },
    clearBreadcrumbs: (state) => {
      state.data = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadCurrentCampaignData.fulfilled, (state, action: PayloadAction<ICampaign>) => {
      state.currentCampaign = action.payload;
    });
  }
});

export const { setCurrentCampaign, clearCurrentCampaign, setBreadcrumbs, clearBreadcrumbs } = breadcrumbsSlice.actions

export const currentCampaign = createSelector(
  (state: RootState) => state.breadcrumbs,
  (breadcrumbs) => breadcrumbs.currentCampaign
)

export const currentBreadcrumbs = createSelector(
  (state: RootState) => state.breadcrumbs,
  (breadcrumbs) => breadcrumbs.data
)


export default breadcrumbsSlice.reducer
import {trans} from "../../../_locales";
import {Accordion, Btn, Input, Toggle} from "../../../ui";
import Select from "../../ui/Select";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";
import {FocusEvent, FormEvent, Fragment, useCallback, useEffect, useState} from "react";
import {
    getInitialState,
    isNumber,
    validateAll,
    validateDomain,
    validateOne,
    validateUrl,
    validateUrlValue
} from "../../../utils";
import {DefaultObject, IField} from "../../../interfaces/common.d";
import {IAccordion} from "../../ModalForm/ModalForm.d";
import {getDashboardsFilterValues} from "../../../actions/dashboards";
import {newPlatformForm, PopupType} from "../../../data";
import {ReactComponent as UploadIcon} from '../../../assets/icons/upload.svg';

import './style.scss';
import {currentCampaign} from "../../../redux/slices/breadcrumbs";
import {openPopup} from "../../../redux/slices/popup";
import UploadFile from "../../ui/UploadFile";
import InputValues from "../../ui/InputValues";
import {downloadCurrentCampaignData} from "../../../actions/breadcrumbs";
import {getIsSubmitLoading, setPlacementsSubmitLoading} from "../../../redux/slices/placements";
import Loader from "../../Loader";

interface INewPlacement {
    projectId:number,
    campaignId?:number,
    cb:(data: FormData) => any,
    initialValues?: DefaultObject<string>,
    type: 'create' | 'edit'
}

const NewPlacement = ({
  projectId,
  campaignId,
  cb = (data) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      for (const [name, value] of data) {
          console.log(`${name}: ${value}`);
      }
  },
  initialValues,
  type = 'create'
}:INewPlacement)=> {
    const dispatch = useAppDispatch();
    const language = useAppSelector(currentUserLang);
    const reduxCurrentCampaign = useAppSelector(currentCampaign);
    const isSubmitLoading = useAppSelector(getIsSubmitLoading);

    const [targeting, setTargeting] = useState<any>({})
    const [geo, setGeo] = useState<any>({})
    const [creative, setCreative] = useState<any>({})
    const scrollBlockProps = { 'data-name': 'scroll-block' };
    const [videoDuration, setVideoDuration] = useState<number>(initialValues && isNumber(initialValues.video_duration) ? +initialValues.video_duration : 0);
    useEffect(()=> {
        if(campaignId) {
            dispatch(downloadCurrentCampaignData({campaignId: campaignId}));
        }
    }, [campaignId])

    useEffect(()=> {
        dispatch(getDashboardsFilterValues({projectId: projectId, name: 'targeting'})).then((r=> setTargeting(r.payload)));
        dispatch(getDashboardsFilterValues({projectId: projectId, name: 'geo'})).then((r=> setGeo(r.payload)));
        dispatch(getDashboardsFilterValues({projectId: projectId, name: 'creative'})).then((r=> setCreative(r.payload)));
        if(!initialValues) {
            if(reduxCurrentCampaign?.onboarding_url) {
                setCampaignUrlDisabled(false);
                setCampaignUrlChecked(true);
                handleFieldChange({'onboarding_url': reduxCurrentCampaign.onboarding_url})
            } else {
                setCampaignUrlDisabled(true);
                handleFieldChange({'onboarding_url': ''});
            }
        }
    }, [dispatch, projectId, reduxCurrentCampaign?.onboarding_url])

    const [errorlabels, setErrorLabels] = useState<DefaultObject<string>>({});
    const [errors, setErrors] = useState(getInitialState<(IField | IAccordion)[], boolean>(
      newPlatformForm() as (IField | IAccordion)[],
      false
    ));
    const [form, setForm] = useState(initialValues ? initialValues :
      getInitialState<(IField | IAccordion)[], any>(
        newPlatformForm() as (IField | IAccordion)[],
        ''
      ));

    const handleFieldChange = useCallback((data: {[index: string]: string|number }) => {
      const name = Object.keys(data)[0];
      const currentForm = {...form, ...data};
      setForm(currentForm)

      if (errors[name]) {
        const {errorsUrl} = validateUrlValue(newPlatformForm() as (IField | IAccordion)[], currentForm);
        setErrors({...errors, ...validateOne(name, data[name]), ...errorsUrl})
      }
    }, [form, errors]);
    const handleUrlFieldBlur = useCallback((
      evt: FocusEvent<HTMLInputElement>
    ) => {
      const name = evt.target.name;
      const value = evt.target.value;
      const temp = JSON.parse(JSON.stringify(errors));
      if (!temp[name] && Boolean(value)) {
        const {errorsUrl} = validateUrlValue(
            newPlatformForm() as (IField | IAccordion)[],
            {[name]: value}
        );
        setErrors({...temp, ...errorsUrl})
      }

      const currentField: IField = newPlatformForm()?.filter((field) =>
          (field.tag === 'input' && field.name === name))[0] as IField;
      if (!currentField.isRequired && !value) {
        setErrors({...temp, ...{[name]: false}})
      }
    }, [errors])

    const handleFormReset = (evt: any) => {
        evt.preventDefault();
        setForm(getInitialState<(IField | IAccordion)[], string>(
            newPlatformForm() as (IField | IAccordion)[],
            ''
        ));
        setErrors(getInitialState<(IField | IAccordion)[], boolean>(
            newPlatformForm() as (IField | IAccordion)[],
            false
        ))
    }
    const handleFormSubmit = useCallback((evt: FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        const isValid = validateAll(form, errors, newPlatformForm() as (IField | IAccordion)[], setErrors);
        if (!isValid) return;
        const formData = new FormData();
        Object.entries(form).forEach(([name, value]) => {
            if(value && name !== 'format') {
                if(name === 'type') {
                    formData.append('format', 'Other')
                }
                formData.append(name, value)
            }
            if(!value && name === 'not_clickable') {
                formData.append(name, 'false');
            }
        });
        dispatch(setPlacementsSubmitLoading(true));
        cb(formData);
        return;
    }, [form, errors, cb]);

    const changeSelect = (response:any, name:string, otherChanges: DefaultObject<any> = {}) => {
        for (const key in response) {
            const data:any = {};
            data[name] = key;
            handleFieldChange({...data, ...otherChanges});
        }
    }

    const formatDataset = {
        'JPEG': trans('JPEG', language),
        'GIF': trans('GIF', language),
        'HTML': trans('HTML', language),
        'Video': trans('Video', language),
        'Other': trans('Other', language),
    }
    const typeDataset = {
        'tracking': trans('Tracking pixel', language),
        'adserving_video': trans('Adserving video', language),
        'adserving_static': trans('Adserving static', language)
    }

    const [campaignUrlChecked, setCampaignUrlChecked] = useState(false);
    const [campaignUrlDisabled, setCampaignUrlDisabled] = useState(false);
    const changeCheckbox = (checked:boolean) => {
        if(campaignUrlDisabled) {
            dispatch(openPopup({
                type: PopupType.WARNING,
                description: trans('В данной кампании не указана посадочная страница', language),
                icon: 'setting'
            }))
            return;
        }
        setCampaignUrlChecked(checked);
        if(checked && reduxCurrentCampaign?.onboarding_url) {
            handleFieldChange({'onboarding_url': reduxCurrentCampaign.onboarding_url})
            return;
        }
        handleFieldChange({'onboarding_url': ''})
    }

    return(
        <div className='new-placement'>
            <div className="new-placement__wrapper" {...scrollBlockProps}>
                <Input
                    type={'text'}
                    name={'name'}
                    value={form['name']}
                    placeholder={''}
                    label={trans('Creative name', language)}
                    errorLabel={trans('The field is required', language)}
                    isInvalid={errors['name']}
                    onChange={handleFieldChange}
                    required={true}
                />
                <Input
                    type={'text'}
                    className={`new-placement__url ${campaignUrlDisabled ? 'disabled':''}`}
                    name={'onboarding_url'}
                    value={form['onboarding_url']}
                    placeholder={''}
                    label={trans('Onboarding url', language)}
                    errorLabel={trans('Enter the correct url', language)}
                    isInvalid={errors['onboarding_url']}
                    onChange={handleFieldChange}
                    onBlur={handleUrlFieldBlur}
                    required={true}
                    tooltip={trans('This page will be redirected after clicking on the clickable pixel', language)}
                />
                {!campaignUrlDisabled && <Toggle
                    className='new-placement__checkbox custom-toggle--no-padding'
                    name='row-choice'
                    borderMod={true}
                    isChecked={campaignUrlChecked}
                    onChange={(evt)=>changeCheckbox(evt.currentTarget.checked)}
                    label={'Cкопировать страницу из кампании'}
                    // disabled={campaignUrlDisabled}
                    size={'small'}
                    color={'gray'}
                />}

                <Select
                    selectActive={(typeDataset as any)[form['type']]}
                    dataset={typeDataset}
                    onChangeActive={(r:any)=>{
                        let not_clickable = false;
                        for (const key in r) {
                            if(key === 'adserving_static') {
                                not_clickable = true;
                            }
                        }
                        changeSelect(r, 'type', {
                            'width': undefined,
                            'height': undefined,
                            'video_duration': undefined,
                            'video': undefined,
                            'image': undefined,
                            'skiptime': undefined,
                            'progress_offset': undefined,
                            'progress': undefined,
                            'cv_delay': undefined,
                            'adv_delay': undefined,
                            'not_clickable': not_clickable,
                            'impression': ''
                        });
                        setErrors({...errors, ...{'video': false, 'image': false}});
                    }}
                    label={trans('Pixel type', language)}
                    isInvalid={errors['type']}
                    errorLabel={trans('Select the pixel type', language)}
                    // tooltip={trans('The field is used to filter and group the results. It will help you better look for insights in the data.', language)}
                    colorText={'dark'}
                    required={true}
                />

                {/*<Select*/}
                {/*    selectActive={(formatDataset as any)[form['format']]}*/}
                {/*    dataset={formatDataset}*/}
                {/*    onChangeActive={(r:any)=>changeSelect(r, 'format')}*/}
                {/*    label={trans('Format', language)}*/}
                {/*    isInvalid={errors['format']}*/}
                {/*    errorLabel={trans('Select the format', language)}*/}
                {/*    tooltip={trans('The field is used to filter and group the results. It will help you better look for insights in the data.', language)}*/}
                {/*    colorText={'dark'}*/}
                {/*/>*/}

                {
                    form['type'] === 'tracking' && <>
                        <Accordion title={trans('Tracking Settings', language)}>
                            <InputValues
                                label={'Impression'}
                                name={'impression'}
                                value={form['impression'] ? form['impression'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'impression': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                                isEncodeValue={true}
                            />
                        </Accordion>
                    </>
                }

                {
                    form['type'] === 'adserving_video' && <>
                        <UploadFile
                            text={trans('Upload your video', language)}
                            uploadedFilename={(form['video_url'] || form['video']) ? 'video.mp4':undefined}
                            fileType={'video'}
                            onChange={(data:any)=> {
                                if(data && data.file) {
                                    // Создаем элемент video
                                    const video = document.createElement('video');
                                    video.preload = 'metadata';

                                    // При загрузке метаданных получаем необходимые данные
                                    video.onloadedmetadata = () => {
                                      // Освобождаем объект URL, так как он больше не нужен
                                      URL.revokeObjectURL(video.src);
                                      if(data.file.size > 20000000) {
                                          setErrors({...errors, ...{'video': true}});
                                          setErrorLabels({...errorlabels, ...{'video': trans('The file is more than 20 MB', language)}})
                                          handleFieldChange({
                                              'video_url': '',
                                              'width': '',
                                              'height': '',
                                              'video_duration': '',
                                              'video': '',
                                              'image': '',
                                              'skiptime': '',
                                              'progress_offset': '',
                                              'progress': '',
                                              'cv_delay': '',
                                              'adv_delay': ''
                                          })
                                          return;
                                      }
                                      setErrors({...errors, ...{'video': false}});
                                      setErrorLabels({...errorlabels, ...{'video': ''}})
                                      handleFieldChange({
                                          'width': video.videoWidth,
                                          'height': video.videoHeight,
                                          'video_duration': Math.round(video.duration),
                                          'video': data.file
                                      });
                                      setVideoDuration(Math.round(video.duration))
                                    };

                                    // Устанавливаем src для загрузки видео
                                    video.src = URL.createObjectURL(data.file);
                                }
                            }}
                            onRemove={()=> {
                                handleFieldChange({
                                    'video_url': '',
                                    'width': '',
                                    'height': '',
                                    'video_duration': '',
                                    'video': '',
                                    'image': '',
                                    'skiptime': '',
                                    'progress_offset': '',
                                    'progress': '',
                                    'cv_delay': '',
                                    'adv_delay': ''
                                })
                            }}
                            isInvalid={errors['video']}
                            errorText={errorlabels['video']? errorlabels['video'] : trans('The field is required', language)}
                        />
                        <Input
                            type={'number'}
                            name={'skiptime'}
                            value={form['skiptime']}
                            placeholder={''}
                            label={trans('Skip time', language)}
                            errorLabel={trans('The field is required', language)}
                            isInvalid={errors['skiptime']}
                            onChange={handleFieldChange}
                            limitNumber={videoDuration}
                        />
                        <Accordion title={trans('Tracking Settings', language)}>
                            <InputValues
                                label={'Impression'}
                                name={'impression'}
                                value={form['impression'] ? form['impression'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'impression': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'Click'}
                                name={'click'}
                                value={form['click'] ? form['click'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'click': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'Start'}
                                name={'start'}
                                value={form['start'] ? form['start'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'start': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'FirstQuartile'}
                                name={'first_quartile'}
                                value={form['first_quartile'] ? form['first_quartile'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'first_quartile': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'Midpoint'}
                                name={'midpoint'}
                                value={form['midpoint'] ? form['midpoint'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'midpoint': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'ThirdQuartile'}
                                name={'third_quartile'}
                                value={form['third_quartile'] ? form['third_quartile'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'third_quartile': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'Complete'}
                                name={'complete'}
                                value={form['complete'] ? form['complete'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'complete': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'CreativeView (MRC)'}
                                name={'creative_view'}
                                value={form['creative_view'] ? form['creative_view'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'creative_view': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'CreativeView (Custom)'}
                                name={'custom_view'}
                                value={form['custom_view'] ? form['custom_view'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'custom_view': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'Mute'}
                                name={'mute'}
                                value={form['mute'] ? form['mute'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'mute': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'Unmute'}
                                name={'unmute'}
                                value={form['unmute'] ? form['unmute'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'unmute': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'Pause'}
                                name={'pause'}
                                value={form['pause'] ? form['pause'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'pause': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'Resume'}
                                name={'resume'}
                                value={form['resume'] ? form['resume'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'resume': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'Skip'}
                                name={'skip'}
                                value={form['skip'] ? form['skip'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'skip': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'PlayerExpand'}
                                name={'player_expand'}
                                value={form['player_expand'] ? form['player_expand'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'player_expand': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'PlayerCollapse'}
                                name={'player_collapse'}
                                value={form['player_collapse'] ? form['player_collapse'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'player_collapse': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'Progress'}
                                name={'progress'}
                                value={form['progress'] ? form['progress'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'progress': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <Input
                                type={'number'}
                                name={'progress_offset'}
                                value={form['progress_offset']}
                                placeholder={''}
                                label={trans('ProgressOffset (сек)', language)}
                                errorLabel={trans('The field is required', language)}
                                isInvalid={errors['progress_offset']}
                                onChange={handleFieldChange}
                                required={false}
                                tooltip={''}
                                limitNumber={videoDuration}
                            />
                            <InputValues
                                label={'Error'}
                                name={'error'}
                                value={form['error'] ? form['error'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'error': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                        </Accordion>
                        <Accordion title={trans('Advertiser', language)}>
                            <Input
                                type={'text'}
                                name={'adv_erid'}
                                value={form['adv_erid']}
                                placeholder={''}
                                label={trans('ERID', language)}
                                errorLabel={trans('The field is required', language)}
                                isInvalid={errors['adv_erid']}
                                onChange={handleFieldChange}
                                required={false}
                                tooltip={''}
                            />
                            {/*<Input*/}
                            {/*    type={'text'}*/}
                            {/*    name={'adv_title'}*/}
                            {/*    value={form['adv_title']}*/}
                            {/*    placeholder={''}*/}
                            {/*    label={trans('Title', language)}*/}
                            {/*    errorLabel={trans('The field is required', language)}*/}
                            {/*    isInvalid={errors['adv_title']}*/}
                            {/*    onChange={handleFieldChange}*/}
                            {/*    required={false}*/}
                            {/*    tooltip={''}*/}
                            {/*/>*/}
                            {/*<Input*/}
                            {/*    type={'text'}*/}
                            {/*    name={'adv_name'}*/}
                            {/*    value={form['adv_name']}*/}
                            {/*    placeholder={''}*/}
                            {/*    label={trans('Advertiser name', language)}*/}
                            {/*    errorLabel={trans('The field is required', language)}*/}
                            {/*    isInvalid={errors['adv_name']}*/}
                            {/*    onChange={handleFieldChange}*/}
                            {/*    required={false}*/}
                            {/*    tooltip={''}*/}
                            {/*/>*/}
                            {/*<Input*/}
                            {/*    type={'text'}*/}
                            {/*    name={'adv_address'}*/}
                            {/*    value={form['adv_address']}*/}
                            {/*    placeholder={''}*/}
                            {/*    label={trans('Address', language)}*/}
                            {/*    errorLabel={trans('The field is required', language)}*/}
                            {/*    isInvalid={errors['adv_address']}*/}
                            {/*    onChange={handleFieldChange}*/}
                            {/*    required={false}*/}
                            {/*    tooltip={''}*/}
                            {/*/>*/}
                            {/*<Input*/}
                            {/*    type={'text'}*/}
                            {/*    name={'adv_inn'}*/}
                            {/*    value={form['adv_inn']}*/}
                            {/*    placeholder={''}*/}
                            {/*    label={trans('INN', language)}*/}
                            {/*    errorLabel={trans('The field is required', language)}*/}
                            {/*    isInvalid={errors['adv_inn']}*/}
                            {/*    onChange={handleFieldChange}*/}
                            {/*    required={false}*/}
                            {/*    tooltip={''}*/}
                            {/*/>*/}
                            {/*<Input*/}
                            {/*    type={'text'}*/}
                            {/*    name={'adv_ogrn'}*/}
                            {/*    value={form['adv_ogrn']}*/}
                            {/*    placeholder={''}*/}
                            {/*    label={trans('OGRN', language)}*/}
                            {/*    errorLabel={trans('The field is required', language)}*/}
                            {/*    isInvalid={errors['adv_ogrn']}*/}
                            {/*    onChange={handleFieldChange}*/}
                            {/*    required={false}*/}
                            {/*    tooltip={''}*/}
                            {/*/>*/}
                            {/*<Input*/}
                            {/*    type={'text'}*/}
                            {/*    name={'adv_url'}*/}
                            {/*    value={form['adv_url']}*/}
                            {/*    placeholder={''}*/}
                            {/*    label={trans('Advertiser url', language)}*/}
                            {/*    errorLabel={trans('Enter the correct url', language)}*/}
                            {/*    isInvalid={errors['adv_url']}*/}
                            {/*    onChange={handleFieldChange}*/}
                            {/*    onBlur={handleUrlFieldBlur}*/}
                            {/*    required={false}*/}
                            {/*    tooltip={''}*/}
                            {/*/>*/}
                            {/*<Input*/}
                            {/*    type={'number'}*/}
                            {/*    name={'adv_delay'}*/}
                            {/*    value={form['adv_delay']}*/}
                            {/*    placeholder={''}*/}
                            {/*    label={trans('Advertiser delay', language)}*/}
                            {/*    errorLabel={trans('The field is required', language)}*/}
                            {/*    isInvalid={errors['adv_delay']}*/}
                            {/*    onChange={handleFieldChange}*/}
                            {/*    required={false}*/}
                            {/*    tooltip={''}*/}
                            {/*    limitNumber={videoDuration * 1000}*/}
                            {/*/>*/}
                        </Accordion>
                        {/*<Accordion title={trans('Black list', language)}>*/}
                        {/*    <InputValues*/}
                        {/*        label={'Domain'}*/}
                        {/*        name={'error'}*/}
                        {/*        value={form['black_list_domain'] ? form['black_list_domain'].split(',') : []}*/}
                        {/*        cb={(data)=> {*/}
                        {/*            handleFieldChange({'black_list_domain': data.join(',')})*/}
                        {/*        }}*/}
                        {/*        isValidValue={(value:string)=> validateDomain(value)}*/}
                        {/*        errorLabel={trans('Enter the correct domain', language)}*/}
                        {/*    />*/}
                        {/*    <UploadFile*/}
                        {/*        text={trans('Upload your blacklist of domains', language)}*/}
                        {/*        uploadedFilename={(form['black_list_domain'] || form['black_list_domain_url']) ? 'domains.txt':undefined}*/}
                        {/*        fileType={'txt'}*/}
                        {/*        onChange={(data:any)=> {*/}
                        {/*            if(data && data.file) {*/}
                        {/*              handleFieldChange({*/}
                        {/*                  'black_list_domain': data.file*/}
                        {/*              });*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/*        onRemove={()=> {*/}
                        {/*            handleFieldChange({'black_list_domain': '', 'black_list_domain_url': ''})*/}
                        {/*        }}*/}
                        {/*        isInvalid={errors['black_list_domain']}*/}
                        {/*    />*/}
                        {/*</Accordion>*/}
                    </>
                }
                {
                    form['type'] === 'adserving_static' && <>
                        <UploadFile
                            text={trans('Upload your image', language)}
                            uploadedFilename={(form['image'] || form['image_url']) ? 'image':undefined}
                            fileType={'image'}
                            onChange={(data:any)=> {
                                if(data && data.file) {
                                    // Создаем объект Image
                                    const img = new Image();

                                    // При загрузке изображения получаем ширину и высоту
                                    img.onload = () => {
                                      // Освобождаем объект URL
                                      URL.revokeObjectURL(img.src);
                                      if(data.file.size > 20000000) {
                                          setErrors({...errors, ...{'image': true}});
                                          setErrorLabels({...errorlabels, ...{'image': trans('The file is more than 20 MB', language)}})
                                          handleFieldChange({
                                              'image': '', 'image_url': ''
                                          })
                                          return;
                                      }
                                      setErrors({...errors, ...{'image': false}});
                                      setErrorLabels({...errorlabels, ...{'image': ''}})
                                      handleFieldChange({
                                          'width': img.width,
                                          'height': img.height,
                                          'image': data.file
                                      });
                                    };

                                    // Устанавливаем src для загрузки изображения
                                    img.src = URL.createObjectURL(data.file);
                                }
                            }}
                            onRemove={()=> {
                                handleFieldChange({'image': '', 'image_url': ''})
                            }}
                            isInvalid={errors['image']}
                            errorText={errorlabels['image']?errorlabels['image'] : trans('The field is required', language)}
                        />
                        <Toggle
                            label={trans('Not clickable on', language)}
                              name={'not_clickable'}
                              isInvalid={errors['not_clickable']}
                              onChange={(event)=>{
                                if(event.target) {
                                    const checked = event.target.checked ? 0 : 1;
                                    handleFieldChange({['not_clickable']: checked});
                                }
                              }}
                              isChecked={!form['not_clickable']}
                        />
                        <Accordion title={trans('Tracking Settings', language)}>
                            <InputValues
                                label={'Impression'}
                                name={'impression'}
                                value={form['impression'] ? form['impression'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'impression': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'CreativeView (MRC)'}
                                name={'creative_view'}
                                value={form['creative_view'] ? form['creative_view'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'creative_view': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                            <InputValues
                                label={'CreativeView (Custom)'}
                                name={'custom_view'}
                                value={form['custom_view'] ? form['custom_view'].split(',') : []}
                                cb={(data)=> {
                                    handleFieldChange({'custom_view': data.join(',')})
                                }}
                                isValidValue={(value:string)=> validateUrl(value)}
                                errorLabel={trans('Enter the correct url', language)}
                            />
                        </Accordion>
                    </>
                }

                <Accordion title={trans('Advanced Settings', language)}>
                    <Fragment>
                        <Input
                            type={'text'}
                            name={'adv_id'}
                            value={form['adv_id']}
                            placeholder={''}
                            label={trans('ID in the advertising system', language)}
                            errorLabel={''}
                            onChange={handleFieldChange}
                            tooltip={trans('If your advertising system is present in the list of integrations, and you have connected it, then add the creative ID to this field, and expenses will be raised automatically.', language)}
                        />
                    </Fragment>
                    <Fragment>
                        <Input
                            type={'text'}
                            name={'geo'}
                            value={form['geo']}
                            placeholder={''}
                            label={trans('Geo', language)}
                            errorLabel={trans('The field is required', language)}
                            isInvalid={errors['geo']}
                            onChange={handleFieldChange}
                            tip={typeof geo === 'object' ? geo : {}}
                            tooltip={trans(`A free text field. It is needed to filter and group the results. It will help you better look for insights in the data. For example, "Moscow", "UFA", "Moscow and St. Petersburg", "regions"`, language)}
                        />
                    </Fragment>
                    <Fragment>
                        <Input
                            type={'text'}
                            name={'targeting'}
                            value={form['targeting']}
                            placeholder={''}
                            label={trans('Targeting', language)}
                            errorLabel={trans('The field is required', language)}
                            isInvalid={errors['targeting']}
                            onChange={handleFieldChange}
                            tip={typeof targeting === 'object' ? targeting : {}}
                            tooltip={trans(`A free text field. It is needed to filter and group the results. It will help you better look for insights in the data. For example: "wealthy audience", "audience of TV series fans".`, language)}
                        />
                    </Fragment>
                    <Fragment>
                        {(form['format'] === 'Video' || form['type'] === 'adserving_video') && <Select
                            selectActive={form['video_orientation']}
                            dataset={{
                              'vertical': 'vertical',
                              'horizontal': 'horizontal',
                            }}
                            onChangeActive={(r:any)=>changeSelect(r, 'video_orientation')}
                            label={trans('Video orientation', language)}
                            isInvalid={errors['video_orientation']}
                            errorLabel={trans('Select the orientation of the video', language)}
                            colorText={'dark'}
                        />}
                    </Fragment>
                    <Fragment>
                        {form['type'] === 'adserving_video' && <Toggle
                            label={trans('Not clickable', language)}
                              name={'not_clickable'}
                              isInvalid={errors['not_clickable']}
                              onChange={(event)=>{
                                if(event.target)
                                  handleFieldChange({['not_clickable']: event.target.checked});
                              }}
                              isChecked={!!form['not_clickable']}
                        />}
                    </Fragment>
                </Accordion>
            </div>
            <div className="new-placement__btns">
              <Btn
                type='reset'
                text={trans('Clear', language)}
                size='lg'
                widthAuto={false}
                onClick={handleFormReset}
              />
              <Btn
                type='submit'
                text={(initialValues && type === 'edit') ? trans('Update', language) : trans('Create', language)}
                color='blue'
                widthAuto={false}
                size='lg'
                onClick={handleFormSubmit}
              />
            </div>
            {isSubmitLoading && <Loader />}
        </div>
    )
}

export default NewPlacement;
import React, {
  FC,
  memo,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef
} from "react";
import {
  ITableCheckedTypes,
  ITableCheckedWithType,
  ITableFunnelSize,
  ITableFunnelStatus
} from "../index";

import "./style.scss";
import { useAppSelector } from "../../../../redux/hooks";
import { currentUserLang } from "../../../../redux/slices/user";
import {
  DefaultObject,
  ICampaign,
  IPlacement,
  ISources
} from "../../../../interfaces/common.d";
import { IPinColumns } from "../../interfaces";
import { IMarketingItem } from "../../../../redux/slices/marketing";
import { IViewMode } from "../../../../pages/Projects/Project/MarketingCampaigns/Item";
import TableFunnelBodyRow from "./Row";

interface ITableFunnelBody {
  onCreate: () => void;
  onCreateBased?: (id: number) => void;
  onCreateDaughter?: (id: number) => void;
  onUpload?: (event: any) => void;
  isDisabled?: boolean;

  columnPreferences: Array<DefaultObject<any>>;
  data: Array<IMarketingItem | ICampaign | IPlacement | ISources>;
  activePin?: IPinColumns;
  choiceActive?: string[];
  settingSize?: ITableFunnelSize;
  onClearFilter?: () => void;
  onEdit: (id: number, data: IMarketingItem | ICampaign | IPlacement | ISources) => void;
  onChangeStatus: (
    id: number,
    status: ITableFunnelStatus,
    type?: ITableCheckedTypes
  ) => void;
  activeChecked?: ITableCheckedWithType;
  onChecked: (id: number, flag: boolean, type: ITableCheckedTypes) => void;
  parentType: ITableCheckedTypes;
  isOnlyParent?: boolean;
  onChangeParent?: (parent: IViewMode, id?: number) => void;
  level?: number;
  viewMode?: IViewMode;
  isCreate?: boolean;
  isEdit?: boolean;
}

/**
 * Компонент для пакетной отрисовки элементов (BatchRenderer).
 * Помогает рендерить большие списки «порциями», чтобы избежать
 * долгих синхронных отрисовок, особенно заметных в Safari.
 */
const BatchRenderer = <T,>({
  items,
  batchSize = 20,
  renderItem
}: {
  items: T[];
  batchSize?: number;
  renderItem: (item: T, index: number) => React.ReactNode;
}) => {
  const [renderedItems, setRenderedItems] = useState<T[]>([]);
  const [index, setIndex] = useState<number>(0);
  useEffect(() => {
    setRenderedItems([]);
    setIndex(0);
  }, [items]);
  useEffect(() => {
    // Если отрендерили все элементы, останавливаемся
    if (index >= items.length) {
      return;
    }
    // Пакетная отрисовка (порция за «тик»)
    const timer = setTimeout(() => {
      setRenderedItems((prev) => [
        ...prev,
        ...items.slice(index, Math.min(index + batchSize, items.length))
      ]);
      setIndex(index + batchSize);
    }, 0);

    return () => clearTimeout(timer);
  }, [items, index, batchSize]);

  return <>{renderedItems.map(renderItem)}</>;
};

/**
 * TableRow — отдельная строка, которая отложенно подгружает
 * свои дочерние элементы, используя IntersectionObserver и requestAnimationFrame.
 */
const TableRow = memo(function TableRow(props: {
  item: IMarketingItem | ICampaign | IPlacement | ISources;
  parentType: ITableCheckedTypes;
  level: number;
  columnPreferences: Array<DefaultObject<any>>;
  activePin?: IPinColumns;
  choiceActive?: string[];
  settingSize?: ITableFunnelSize;
  onClearFilter?: () => void;
  onEdit: (id: number, data: any) => void;
  onChangeStatus: (id: number, status: ITableFunnelStatus, type?: ITableCheckedTypes) => void;
  onCreate: () => void;
  onCreateBased?: (id: number) => void;
  onCreateDaughter?: (id: number) => void;
  onChangeParent?: (parent: IViewMode, id?: number) => void;
  onUpload?: (event: any) => void;
  activeChecked?: ITableCheckedWithType;
  onChecked: (id: number, flag: boolean, type: ITableCheckedTypes) => void;
  viewMode?: IViewMode;
  isCreate?: boolean;
  isEdit?: boolean;
  isOnlyParent?: boolean;
  shouldRenderChildren: boolean; // Управление отображением детей
}) {
  const {
    item,
    parentType,
    level,
    columnPreferences,
    activePin,
    choiceActive,
    settingSize,
    onClearFilter,
    onEdit,
    onChangeStatus,
    onCreate,
    onCreateBased,
    onCreateDaughter,
    onChangeParent,
    onUpload,
    activeChecked = {},
    onChecked,
    viewMode,
    isCreate,
    isEdit,
    isOnlyParent = false,
    shouldRenderChildren
  } = props;

  const [renderChildren, setRenderChildren] = useState(false);
  const rowRef = useRef<HTMLDivElement | null>(null);

  // IntersectionObserver для ленивой загрузки потомков
  useEffect(() => {
    if (!rowRef.current || renderChildren || !shouldRenderChildren) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          requestAnimationFrame(() => {
            setRenderChildren(true);
            observer.disconnect();
          });
        }
      },
      { rootMargin: "200px" }
    );

    observer.observe(rowRef.current);
    return () => observer.disconnect();
  }, [renderChildren, shouldRenderChildren]);

  /**
   * Здесь можно рендерить саму «строку».
   * Например, ваш TableFunnelBodyRow (либо дополнительную логику).
   * Для упрощения ниже — пример «только строка», без детальной вёрстки.
   */
  const isRowChecked = activeChecked[parentType]?.[item.id] ?? false;
  const rowKey = item.id ?? Math.random();

  // Проверяем дочерние данные
  const marketingItem = item as IMarketingItem;
  const sources = marketingItem.sources;
  const placements = (item as ISources).placements;
  const campaign = item as ICampaign;

  // Условно смотрим, нужны ли нам «дети»
  const hasSources = parentType === "campaigns" && !isOnlyParent && sources?.length;
  const hasPlacements = parentType === "sources" && !isOnlyParent && placements?.length;
  const hasCreatives =
    parentType === "placements" &&
    !isOnlyParent &&
    viewMode !== "sources_placements" &&
    viewMode !== "only_placements" &&
    campaign.creatives?.length;

  return (
      <div
          ref={rowRef}
          className="table-row"
          style={{
            // Safari-оптимизации
            // transform: "translateZ(0)",
            // willChange: "transform",
            // WebkitOverflowScrolling: 'touch',
          }}
      >
        {/* Пример «тело строки» */}
        <TableFunnelBodyRow
            type={parentType}
            activePin={activePin}
            choiceActive={choiceActive}
            settingSize={settingSize}
            onCreate={onCreate}
            onEdit={onEdit}
            onChangeStatus={onChangeStatus}
            onCreateBased={onCreateBased}
            onChangeParent={onChangeParent}
            onCreateDaughter={onCreateDaughter}
            onUpload={onUpload}
            isActiveChecked={activeChecked[parentType]?.[item.id]}
            onChecked={onChecked}
            item={item}
            link={item.data_type !== "creatives" ? `${item.id}/` : undefined}
            level={level}
            isCreate={isCreate}
            isEdit={isEdit}
        />
        {/*<div className="row-content">*/}
        {/*  <span>Row: {rowKey}</span>*/}
        {/*  <button onClick={() => onChecked(Number(item.id), !isRowChecked, parentType)}>*/}
        {/*    {isRowChecked ? "Uncheck" : "Check"}*/}
        {/*  </button>*/}
        {/*</div>*/}

        {/* Если строка в зоне видимости — рендерим дочерние элементы */}
        {renderChildren && !!hasSources && (
            <ChildTableFunnelBody
                data={sources!}
                parentType="sources"
                level={level + 1}
                columnPreferences={columnPreferences}
                activePin={activePin}
                choiceActive={choiceActive}
                settingSize={settingSize}
                onClearFilter={onClearFilter}
                onEdit={onEdit}
                onChangeStatus={onChangeStatus}
                onCreate={onCreate}
                onCreateBased={onCreateBased}
                onCreateDaughter={onCreateDaughter}
                onChangeParent={onChangeParent}
                onUpload={onUpload}
                activeChecked={activeChecked}
                onChecked={onChecked}
                viewMode={viewMode}
                isCreate={isCreate}
                isEdit={isEdit}
                isOnlyParent={isOnlyParent}
            />
        )}

        {renderChildren && !!hasPlacements && (
            <ChildTableFunnelBody
                data={placements!}
                parentType="placements"
                level={level + 1}
                columnPreferences={columnPreferences}
                activePin={activePin}
                choiceActive={choiceActive}
                settingSize={settingSize}
                onClearFilter={onClearFilter}
                onEdit={onEdit}
                onChangeStatus={onChangeStatus}
                onCreate={onCreate}
                onCreateBased={onCreateBased}
                onCreateDaughter={onCreateDaughter}
                onChangeParent={onChangeParent}
                onUpload={onUpload}
                activeChecked={activeChecked}
                onChecked={onChecked}
                viewMode={viewMode}
                isCreate={isCreate}
                isEdit={isEdit}
                isOnlyParent={isOnlyParent}
            />
        )}

        {renderChildren && !!hasCreatives && (
            <ChildTableFunnelBody
                data={campaign.creatives!}
                parentType="creatives"
                level={level + 1}
                columnPreferences={columnPreferences}
                activePin={activePin}
                choiceActive={choiceActive}
                settingSize={settingSize}
                onClearFilter={onClearFilter}
                onEdit={onEdit}
                onChangeStatus={onChangeStatus}
                onCreate={onCreate}
                onCreateBased={onCreateBased}
                onCreateDaughter={onCreateDaughter}
                onChangeParent={onChangeParent}
                onUpload={onUpload}
                activeChecked={activeChecked}
                onChecked={onChecked}
                viewMode={viewMode}
                isCreate={isCreate}
                isEdit={isEdit}
                isOnlyParent={isOnlyParent}
            />
        )}
      </div>
  );
});

/**
 * ChildTableFunnelBody — упрощённая версия для рендеринга детей.
 * Для оптимизации в Safari можно также использовать BatchRenderer.
 */
const ChildTableFunnelBody = memo(function ChildTableFunnelBody(props: ITableFunnelBody) {
  const {
    data,
    parentType,
    level = 0,
    columnPreferences,
    activePin,
    choiceActive,
    settingSize,
    onClearFilter,
    onEdit,
    onChangeStatus,
    onCreate,
    onCreateBased,
    onCreateDaughter,
    onChangeParent,
    onUpload,
    activeChecked = {},
    onChecked,
    viewMode,
    isCreate,
    isEdit,
    isOnlyParent
  } = props;

  // Порционная отрисовка (BatchRenderer) для улучшения UX
  const renderItem = useCallback(
    (item: IMarketingItem | ICampaign | IPlacement | ISources, index: number) => {
      const key = item?.["id"] ?? `child-${parentType}-${index}`;
      return (
        <TableRow
          key={key}
          item={item}
          parentType={parentType}
          level={level}
          columnPreferences={columnPreferences}
          activePin={activePin}
          choiceActive={choiceActive}
          settingSize={settingSize}
          onClearFilter={onClearFilter}
          onEdit={onEdit}
          onChangeStatus={onChangeStatus}
          onCreate={onCreate}
          onCreateBased={onCreateBased}
          onCreateDaughter={onCreateDaughter}
          onChangeParent={onChangeParent}
          onUpload={onUpload}
          activeChecked={activeChecked}
          onChecked={onChecked}
          viewMode={viewMode}
          isCreate={isCreate}
          isEdit={isEdit}
          isOnlyParent={isOnlyParent}
          // Устанавливаем, нужно ли рендерить детей (ограничение глубины, если нужно)
          shouldRenderChildren={level < 4} // Пример: ограничили глубину, можно подправить
        />
      );
    },
    [
      parentType,
      level,
      columnPreferences,
      activePin,
      choiceActive,
      settingSize,
      onClearFilter,
      onEdit,
      onChangeStatus,
      onCreate,
      onCreateBased,
      onCreateDaughter,
      onChangeParent,
      onUpload,
      activeChecked,
      onChecked,
      viewMode,
      isCreate,
      isEdit,
      isOnlyParent
    ]
  );

  if (!data?.length) return null;

  return (
    <div
      className="child-table-container"
      style={{
        // contain: "content"
      }}
    >
      <BatchRenderer items={data} batchSize={1} renderItem={renderItem} />
    </div>
  );
});

/**
 * Основной компонент TableFunnelBody.
 * Здесь применяем те же принципы: chunk/limit, memo с compareProps,
 * и используем TableRow (которая сама lazy-load'ит своих детей).
 */
const TableFunnelBody: FC<ITableFunnelBody> = ({
  columnPreferences,
  data,
  activePin,
  choiceActive,
  settingSize,
  onClearFilter,
  onEdit,
  onChangeStatus,
  onCreate,
  onCreateBased,
  onCreateDaughter,
  onUpload,
  activeChecked = {},
  onChecked,
  parentType,
  isOnlyParent = false,
  onChangeParent,
  level = 0,
  viewMode,
  isCreate,
  isEdit
}) => {
  const language = useAppSelector(currentUserLang);

  // Ограничиваем максимальное количество видимых элементов (при необходимости)
  // Можно убрать, если хотим показывать все данные
  const MAX_ITEMS = 500;
  const limitedData = useMemo(() => {
    if (!data?.length) return [];
    return data;
    // return data.slice(0, MAX_ITEMS);
  }, [data]);

  // Порционная отрисовка на верхнем уровне (BatchRenderer)
  const renderItem = useCallback(
    (item: IMarketingItem | ICampaign | IPlacement | ISources, index: number) => {
      const key = item?.["id"] ?? `root-${index}`;
      return (
        <TableRow
          key={key}
          item={item}
          parentType={parentType}
          level={level}
          columnPreferences={columnPreferences}
          activePin={activePin}
          choiceActive={choiceActive}
          settingSize={settingSize}
          onClearFilter={onClearFilter}
          onEdit={onEdit}
          onChangeStatus={onChangeStatus}
          onCreate={onCreate}
          onCreateBased={onCreateBased}
          onCreateDaughter={onCreateDaughter}
          onChangeParent={onChangeParent}
          onUpload={onUpload}
          activeChecked={activeChecked}
          onChecked={onChecked}
          viewMode={viewMode}
          isCreate={isCreate}
          isEdit={isEdit}
          isOnlyParent={isOnlyParent}
          // Параметр управляет включением ленивой отрисовки детей
          shouldRenderChildren={true}
        />
      );
    },
    [
      data,
      parentType,
      level,
      columnPreferences,
      activePin,
      choiceActive,
      settingSize,
      onClearFilter,
      onEdit,
      onChangeStatus,
      onCreate,
      onCreateBased,
      onCreateDaughter,
      onChangeParent,
      onUpload,
      activeChecked,
      onChecked,
      viewMode,
      isCreate,
      isEdit,
      isOnlyParent
    ]
  );

  // Если нет данных — ничего не рисуем
  if (!limitedData.length || !columnPreferences.length) {
    return null;
  }

  return (
    <div
      className="table-funnel-body"
      style={{
        // position: "relative",
        // display: "block",
        // WebkitOverflowScrolling: "touch",
        // transform: "translateZ(0)"
      }}
    >
      <BatchRenderer items={limitedData} batchSize={100} renderItem={renderItem} />

      {/*{data.length > MAX_ITEMS && (*/}
      {/*  <div className="load-more-container">*/}
      {/*    <span className="load-more-text">*/}
      {/*      Показано {MAX_ITEMS} из {data.length} элементов*/}
      {/*    </span>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

/**
 * Функция сравнения пропсов (compareProps) для top-level компонента,
 * чтобы не перерисовывать TableFunnelBody, если пропсы не менялись.
 */
const compareProps = (prev: ITableFunnelBody, next: ITableFunnelBody): boolean => {
  if (prev.parentType !== next.parentType) return false;
  if (prev.isOnlyParent !== next.isOnlyParent) return false;
  if (prev.level !== next.level) return false;
  if (prev.viewMode !== next.viewMode) return false;
  if (prev.isCreate !== next.isCreate) return false;
  if (prev.isEdit !== next.isEdit) return false;
  // Ссылки
  if (JSON.stringify(prev.data) !== JSON.stringify(next.data)) {
    return false;
  }
  if (prev.columnPreferences !== next.columnPreferences) return false;
  if (prev.activePin !== next.activePin) return false;
  if (prev.choiceActive !== next.choiceActive) return false;
  if (prev.settingSize !== next.settingSize) return false;
  if (prev.activeChecked !== next.activeChecked) return false;

  // Функции (сравнение по ссылке)
  if (prev.onCreate !== next.onCreate) return false;
  if (prev.onCreateBased !== next.onCreateBased) return false;
  if (prev.onCreateDaughter !== next.onCreateDaughter) return false;
  if (prev.onChangeParent !== next.onChangeParent) return false;
  if (prev.onUpload !== next.onUpload) return false;
  if (prev.onEdit !== next.onEdit) return false;
  if (prev.onChangeStatus !== next.onChangeStatus) return false;
  if (prev.onChecked !== next.onChecked) return false;
  if (prev.onClearFilter !== next.onClearFilter) return false;

  return true;
};

export default memo(TableFunnelBody, compareProps);

import React, {FC, useEffect, useState} from "react";
import {ITableCheckedTypes, ITableFunnelData, ITableFunnelFilter, ITableFunnelSize} from "../index";

import "./style.scss";
import {useAppSelector} from "../../../../redux/hooks";
import {currentUserLang} from "../../../../redux/slices/user";
import TableLabelItem from "../../TableInteractive/TableLabelItem";
import TableFunnelLabelsItem from "./Item";
import {DefaultObject, ICampaign, IPlacement, ISources} from "../../../../interfaces/common.d";
import {
    IFilterColumns,
    IOnChangeFilter,
    IPinColumns,
    ISearchColumns,
    ISortColumns,
    IStretchList
} from "../../interfaces";
import {IMarketingItem} from "../../../../redux/slices/marketing";
import {Toggle} from "../../../../ui";

import {ReactComponent as SettingIcon} from '../../../../assets/icons/setting-fill.svg'
import { ReactComponent as CheckMinusIcon } from '../../../../assets/icons/minus.svg'
import Btn from "../../../../ui/Btn";
import Select from "../../../ui/Select";
import {onChangeGeneralCheckbox} from "../../../ui/Select/functions";
import {IStretchSettingItem} from "../functions";

interface ITableFunnelLabels {
    // projectId: number,
    // onCreate: ()=>void
    // onUpload?: (event: any)=>void
    // onFilter: (data: ITableFunnelFilter)=>void
    // onDownload: (mode: 'all' | 'active' | number[]) => void
    // isDisabled?: boolean,
    filter: ITableFunnelFilter,
    onFilter?: (r: IOnChangeFilter) => void,

    data: Array<IMarketingItem|ICampaign|IPlacement|ISources>,
    columnPreferences: Array<DefaultObject<any>>,
    stretch?: IStretchList,
    choiceIndexes?: Array<number>,
    choiceActive?: string[],
    choice?: string[],
    choiceFixed?: string[],
    onChangeChoice?: (value: string[]) => void,
    activePin?: IPinColumns,
    onChangeFilter?: (r: IOnChangeFilter) => void,
    onChangePin?:(i:number)=>void,
    onStretch?: (setting: IStretchSettingItem) => void,
    settingSize?:ITableFunnelSize,
    onAllChecked?: (flag:boolean, type: ITableCheckedTypes)=>void
    isSomeChecked?:boolean,
    isAllChecked?:boolean,
    width?:number,
    parentType: ITableCheckedTypes
}

const TableFunnelLabels:FC<ITableFunnelLabels> = ({
    filter,
    data,
    columnPreferences,
    choiceIndexes=[],
    choiceActive=[],
    choice=[],
    choiceFixed=[],
    onChangeChoice,
    activePin,
    onChangePin,
    stretch,
    onStretch,
    settingSize,
    onAllChecked=(flag:boolean, type: ITableCheckedTypes)=>null,
    isSomeChecked,
    isAllChecked,
    width,
    parentType
}) => {
    const language = useAppSelector(currentUserLang);
    const [selectActive, setSelectActive] = useState(columnPreferences.map(item=> {
        if (choice.includes(item.name) && !choiceFixed.includes(item.name))
            return item.name;
    }).filter(item=>item).join(', '));

    const handleChoice = (res:any) => {
        if(typeof onChangeChoice === 'function') {
            const {title, checked} = onChangeGeneralCheckbox(res);
            onChangeChoice(checked);
            setSelectActive(title);
        }
    }

    return (
        <>
            {data.length > 0 &&
                <div className="table-funnel-labels">
                    <div className='table-funnel-choice'>
                        <Toggle
                            name='all-choice'
                            borderMod={true}
                            onChange={(evt)=>{
                                if(isSomeChecked && !isAllChecked) {
                                    onAllChecked(false, parentType);
                                    return;
                                }
                                onAllChecked(evt.currentTarget.checked, parentType);
                            }}
                            isChecked={isAllChecked}
                            isSomeChecked={isSomeChecked}
                        />
                    </div>
                    {columnPreferences.filter((item)=> choiceActive?choiceActive.includes(item.name)||choiceFixed.includes(item.name):true).map((item:any, index:number)=> (
                            <TableFunnelLabelsItem
                                key={index}
                                title={item.title}
                                index={index}
                                name={item.name}
                                activePin={activePin}
                                onChangePin={onChangePin}
                                onStretch={onStretch}
                                settingSize={settingSize}
                                isStretch={item.name === 'name'}
                            />
                    ))}
                    <div className="table-funnel-setting">
                        <Select
                            className={'table-funnel-setting__metrics'}
                            selectActive={selectActive}
                            dataset={(()=>{
                                const temp: DefaultObject<string> = {};
                                columnPreferences.map(item=> {
                                    if(choice.includes(item.name))
                                        temp[item.name] = item.title;
                                })
                                return temp;
                            })()}
                            listActive={choiceActive}
                            onChangeActive={(res:any)=>{
                                handleChoice(res)
                            }}
                            icon={{
                                Component: SettingIcon,
                                width: 16,
                                height: 16
                            }}
                            onlyIcon={true}
                            checkbox={true}
                            position={'right'}
                            widthAuto={true}
                        />
                        {/*<SettingIcon />*/}
                    </div>
                </div>
            }
        </>
    )
}

export default TableFunnelLabels;
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {Tag} from "./tags";
import {createMarketingCampaign, getMarketingCampaign} from "../../actions/marketing";
import {ISources} from "../../interfaces/common.d";

export interface IMarketingItem {
  id: number,
  name: string,
  onboarding_url: string,
  project_id: number,
  author_id: number,
  author: string,
  status: string,
  tags?: Tag[]
  sources?: ISources[] | null;
  data_type?: 'campaigns'
}

interface IMarketingState {
  data: IMarketingItem[],
  current: IMarketingItem | null
  loaded: boolean
  isLoading: boolean
  error: string | null
}


const initialState: IMarketingState = {
  data: [],
  current: null,
  loaded: false,
  isLoading: false,
  error: null
}

export const marketingSlice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    clearMarketing: (state) => {
      state.data = [];
    },
    removeError: (state) => {
      state.error = null;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setCurrentMarketing: (state, action) => {
      state.current = action.payload
    },
    clearCurrentMarketing: (state) => {
      state.current = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMarketingCampaign.fulfilled, (state, action: PayloadAction<IMarketingItem[]>) => {
      state.data = action.payload ?? [];
      state.isLoading = false;
    });
    builder.addCase(getMarketingCampaign.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMarketingCampaign.rejected, (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
    builder.addCase(
      createMarketingCampaign.fulfilled,
      (state) => {
      state.isLoading = false;
    });
    builder.addCase(
      createMarketingCampaign.pending,
      (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      createMarketingCampaign.rejected,
      (state,  action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload
    });
  }
})

export const { removeError, clearMarketing, setIsLoading, setCurrentMarketing, clearCurrentMarketing } = marketingSlice.actions

export const marketingData = createSelector(
  (state: RootState) => state.marketing,
  (marketing) => marketing.data
)

export const marketingCurrent = createSelector(
  (state: RootState) => state.marketing,
  (marketing) => marketing.current
)

export const getIsLoading = createSelector(
  (state: RootState) => state.marketing,
  (marketing) => marketing.isLoading
)

export const errorValue = createSelector(
  (state: RootState) => state.marketing,
  (marketing) => marketing.error
)

export default marketingSlice.reducer

import {FC} from "react";

import "./style.scss";
import {useAppSelector} from "../../../../../redux/hooks";
import {currentUserLang} from "../../../../../redux/slices/user";
import cx from "classnames";
import {IPinColumns} from "../../../interfaces";
import {getStylesItem, IStretchSettingItem, onStretchBlock} from "../../functions";
import {ITableFunnelSize} from "../../index";

interface ITableFunnelLabelsItem {
    className?:string,
    index:number,
    title: string,
    name: string,
    activePin?:IPinColumns,
    onChangePin?:(i:number)=>void,
    onStretch?: (setting: IStretchSettingItem) => void,
    defaultWidth?: number,
    settingSize?: ITableFunnelSize
    isStretch?: boolean
}

const TableFunnelLabelsItem:FC<ITableFunnelLabelsItem> = ({
    className,
    index,
    title,
    name,
    onStretch,
    activePin,
    onChangePin,
    settingSize,
    isStretch
}) => {
    const language = useAppSelector(currentUserLang);

    const contentClassName = cx(
        'table-funnel-labels-item',
        {
            'sticky': activePin && activePin[name],
            'stretch': isStretch
        }
    )
    const textTitleClassName = cx(
        'table-label-item__title-text'
    )
    const stretchClassName = cx(
        'table-funnel__stretch'
    )
    return (
        <div
            className={contentClassName}
            style={getStylesItem({
                key: name,
                settingSize,
                activePin
            })}
        >
            <div className={textTitleClassName}>
                {title ? title : name}
            </div>
            {isStretch && typeof onStretch === 'function' && <button
                className={stretchClassName}
                onMouseDown={(e) => {
                    const result = onStretchBlock({
                        e,
                        name
                    });
                    onStretch(result)
                }}
            ></button>}
        </div>
    )
}

export default TableFunnelLabelsItem;
import cls from './PlainSettings.module.scss';
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {useNavigate, useParams} from "react-router-dom";
import {getProjectGeneral} from "../../../../../actions/project";
import {ChangeEvent, useEffect, useState} from "react";
import {updateProjectBySetting} from "../../../../../actions/projects";
import {projectGeneral} from "../../../../../redux/slices/project";
import {clearUnSaveData, setUnSaveData} from "../../../../../redux/slices/warnings";
import {useCallbackPrompt} from "../../../../../hooks/useCallbackPrompt";
import {ModalName, openModal} from "../../../../../redux/slices/modals";
import Select from "../../../../../components/ui/Select";

import './style.scss';
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {trans} from "../../../../../_locales";
import {Currency} from "../../../Create/FirstStep";
import {PopupType, TIMEZONES} from "../../../../../data";
import {getScreenResolution, sendSdk, showPopup} from "../../../../../utils";
import Btn from "../../../../../ui/Btn";
import {DefaultObject, IProject} from "../../../../../interfaces/common.d";
import Accordion from "../../../../../ui/Accordion";
import ChangeByBtn from "../../../../../components/ChangeByBtn";
import UploadFile from "../../../../../components/ui/UploadFile";


const PlainSettings = () => {

  const dispatch = useAppDispatch();
  const {projectId} = useParams();
  const reduxUser = useAppSelector(currentUser);
  const currentProject = useAppSelector(projectGeneral);
  const reduxUserId = useAppSelector(userId);
  const language = useAppSelector(currentUserLang);
  const navigate = useNavigate();
  const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
  const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('settings_general') || currentProject && currentProject.owner_id === reduxUserId;
  const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('settings_general') || currentProject && currentProject.owner_id === reduxUserId;
  const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('settings_general') || currentProject && currentProject.owner_id === reduxUserId;
  const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('settings_general') || currentProject && currentProject.owner_id === reduxUserId;

  useEffect(()=> {
    if (projectId && reduxUserId) {
      sendSdk('page_view', {
        'user': {
          'uid': reduxUserId
        },
        'event_params': {
          'project_id': projectId,
          'user_id': reduxUserId,
          'page': 'settings_general',
          'resolution': getScreenResolution()
        }
      })
      if(permissions && !isView) {
        if(Array.isArray(permissions['can_view'])) {
          if(permissions['can_view'].includes('settings_attributions')) {
            navigate(`/projects/${projectId}/attributions/`);
            return;
          }
          if(permissions['can_view'].includes('settings_counter')) {
            navigate(`/projects/${projectId}/counter/`);
            return;
          }
          if(permissions['can_view'].includes('settings_user_control')) {
            navigate(`/projects/${projectId}/user_control/`);
            return;
          }
          if(permissions['can_view'].includes('settings_integrations')) {
            navigate(`/projects/${projectId}/integrations/`);
            return;
          }
          if(permissions['can_view'].includes('settings_api')) {
            navigate(`/projects/${projectId}/api/`);
            return;
          }
          if(permissions['can_view'].includes('settings_roles')) {
            navigate(`/projects/${projectId}/roles/`);
            return;
          }
        }
        navigate(`/projects/${projectId}/settings/`);
        return;
      }
    }
  }, [projectId, reduxUserId])

  const [dataProject, setDataProject] = useState<IProject | null>(null);

  const [userData, setUserData] = useState({email: ''});
  const [statusData, setStatusData] = useState(currentProject!==null ? currentProject.status : '');
  const [restrictData, setRestrictData] = useState(currentProject ? currentProject.receive_data_only_from_domain : false);
  const [nameBtn, showNameBtn] = useState<boolean>(true)
  const [mailBtn, showMailBtn] = useState<boolean>(true)
  const [dataDomain, getDataDomain] = useState<boolean>(false)
  const [exitCounter, setExitCounter] = useState<boolean>(false)

  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(exitCounter)
  if(showPrompt) {
    dispatch(openModal({
      name:ModalName.CONFIRM_EXIT,
      data: {
        confirm: confirmNavigation,
        cancel: cancelNavigation,
      }
    }))
  }

  const showStatus = {
    'active': trans('Active', language),
    'inactive': trans('Inactive', language),
    'archive': trans('Archival', language),
    // 'DELETED': trans('Deleted', language),
  }
  const onChangeStatusValue = (response:object) => {
    for(const key in response) {
      changeStatus(key)
    }
  }
  const changeTimezone = (value:string):string => {
    let result: string = 'UTC';
    Object.entries(TIMEZONES).forEach(([key ]) => {
      if((TIMEZONES as any)[key] === value) {
        result = key;
      }
    });
    return result;
  }

  const changeName = () => {
    setExitCounter(true)
    dispatch(setUnSaveData());
    showNameBtn(!nameBtn)
    // setShowDialog(true)
  }
  const changeNameInput = (value:string) => {
    if(!exitCounter && dataProject === null && currentProject) {
      setDataProject({...currentProject, name: value});
    }
    if(dataProject !== null) {
      setDataProject({...dataProject, name: value})
    }
    // setShowDialog(true)
  }

  function changeStatus(status: string): void  {
    setStatusData(status);
    setExitCounter(true)
    dispatch(setUnSaveData());
    if(dataProject !== null)
      setDataProject({...dataProject, status: status});
  }
  const [activeStatus, setActiveStatus] = useState(cls.input_entity);
  const [flagStatus, setFlagStatus] = useState(true);
  const checkActiveStatus = (flag:boolean) => {
    setFlagStatus(!flagStatus);
    if(flag) {
      setActiveStatus(`${cls.input_entity} ${cls.active}`);
      return
    }
    setActiveStatus(cls.input_entity);
  }

  const changeRestrictData = (e: any) => {
    if(!exitCounter && dataProject === null) {
      setDataProject(currentProject);
    }

    if(dataProject !== null) {
      setExitCounter(true)
      dispatch(setUnSaveData());
      const result = !restrictData;
      setRestrictData(result);
      setDataProject({...dataProject, receive_data_only_from_domain: result});
    }
  }

  const changeDataDomain = () => {
    setExitCounter(true)
    dispatch(setUnSaveData());
    getDataDomain(prev => !prev)
  }

  const changeMail = () => {
    setExitCounter(true)
    dispatch(setUnSaveData());
    showMailBtn(!mailBtn)
    // if(dataProject !== null)
      // setDataProject({...dataProject, admin_email: userData.email})
  }
  const changeMailInput = (value:string) => {
    if(!exitCounter && dataProject === null && currentProject) {
      setDataProject({...currentProject, admin_email: value});
    }
    if(dataProject !== null) {
      setDataProject({...dataProject, admin_email: value})
    }
  }

  const onChangeCurrencyValue = (response:object) => {
    for(const key in response) {
      changeCurrency(key, (response as any)[key])
    }
  }
  const changeCurrency = (key: string, value:string): void => {
    if(key === 'RUB' || key === 'USD' || key === 'EUR') {
      setExitCounter(true)
      dispatch(setUnSaveData());
      if(dataProject !== null)
        setDataProject({...dataProject, currency: key})
    }
  }
  const [activeCurrency, setActiveCurrency] = useState(cls.input_entity);
  const [flagCurrency, setFlagCurrency] = useState(true);
  const checkActiveCurrency = (flag:boolean) => {
    setFlagCurrency(!flagCurrency);
    if(flag) {
      setActiveCurrency(`${cls.input_entity} ${cls.active}`);
      return
    }
    setActiveCurrency(cls.input_entity);
  }

  const onChangeTimezoneValue = (response:object) => {
    for(const key in response) {
      onChangeTimezone(key, (response as any)[key])
    }
  }
  const onChangeTimezone = (key:string, value: string): void => {
    setExitCounter(true)
    dispatch(setUnSaveData());
    if(dataProject !== null)
      setDataProject({...dataProject, timezone: key})
  }
  const [activeTimezone, setActiveTimezone] = useState(cls.input_entity);
  const [flagTimezone, setFlagTimezone] = useState(true);
  const checkActiveTimezone = (flag:boolean) => {
    setFlagTimezone(!flagTimezone);
    if(flag) {
      setActiveTimezone(`${cls.input_entity} ${cls.active}`);
      return
    }
    setActiveTimezone(cls.input_entity);
  }

  const handleFieldChange = (name: string, value?: string|number) => {
    setExitCounter(true)
    dispatch(setUnSaveData());
    if(dataProject !== null) {
      const extend:DefaultObject<string> = {};
      if(name === 'black_list_domain') {
        extend['black_list_domain_url'] = '';
      }
      setDataProject({...dataProject, ...{[name]: value, ...extend}});
    }
  }

  const onSubmit = () => {
    setExitCounter(false)
    dispatch(clearUnSaveData());
    if(dataProject !== null && dataProject.timezone) {
      const formData = new FormData();
      Object.entries(dataProject).forEach(([name, value]) => {
          if(value && name !== 'format') {
              formData.append(name, value)
          }
      });
      formData.append('timezone', changeTimezone(dataProject.timezone))
      dispatch(updateProjectBySetting({
        data: formData,
        projectId: Number(projectId), userId: reduxUser.id
      }));
      sendSdk('event', {
        'event_type': 'edit',
        'event_category': 'settings_general',
        'event_name': 'active',
        'event_value': '10',
        'event_params': {
          'project_id': projectId,
          'user_id': reduxUserId
        }
      });
    }
  }

  const onDelete = () => {
    const status = dataProject ?
                  dataProject.status :
                  currentProject?.status;
    const project = dataProject ?
                  dataProject :
                  currentProject;

    if(!project) {
      showPopup(dispatch, trans('Something went wrong', language), PopupType.ERROR)
      return;
    }
    const title = status && status === 'archive' ? trans('Confirmation of activation', language) : trans('Confirmation of archiving', language);
    const text = status && status === 'archive' ? trans('Confirm the activation of the project', language) : trans('Confirm archiving of the project', language);
    const textConfirm = status && status === 'archive' ? trans('Activate', language) : trans('Archive', language);
    dispatch(openModal({
      name:ModalName.CONFIRM_DELETE,
      data: {
        title: title,
        text: text,
        textConfirm: textConfirm,
        onConfirm: () => {
          const newStatus = status && status === 'archive' ? 'active' : 'archive';
          const formData = new FormData();
          Object.entries(project).forEach(([name, value]) => {
              if(value && name !== 'format') {
                  formData.append(name, value)
              }
          });
          formData.append('status', newStatus);
          dispatch(updateProjectBySetting({data: formData, projectId: Number(projectId), userId: reduxUser.id}));
          sendSdk('event', {
            'event_type': 'archive',
            'event_category': 'settings_general',
            'event_name': 'project',
            'event_value': '10',
            'event_params': {
              'project_id': projectId,
              'user_id': reduxUserId
            }
          });
          navigate('/projects');
        }
      }
    }))
  }

  useEffect(() => {
    dispatch(getProjectGeneral({currentUserId: reduxUser.id, projectId: Number(projectId)}));
  }, [dispatch,projectId, reduxUser.id])

  useEffect(() => {
    setDataProject(currentProject);
    setRestrictData(currentProject ? currentProject.receive_data_only_from_domain : false)
  }, [currentProject]);

  if(isEdit || isDelete) {
    return (
      <>
        <div className={`${cls.content} setting`}>
          <div className={cls.form}>
            <div className={cls.input_entity}>
              <div className={cls.info_wrapper}>
                <label htmlFor='project_name'>{trans('Project name', language)}</label>
                <span
                    className={cls.data}>{dataProject !== null && dataProject.name ? dataProject.name : currentProject?.name}</span>
              </div>
              {
                nameBtn
                    ?
                    <button
                        onClick={() => showNameBtn(!nameBtn)}
                        name='project_name'
                        className={cls.hide_button}
                    >
                      {trans('Change', language)}
                    </button>
                    :
                    <>
                      <input
                          className={cls.show_input}
                          type='text'
                          placeholder={trans('Input new name', language)}
                          value={dataProject !== null && dataProject.name ? dataProject.name : currentProject?.name}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => changeNameInput(e.target.value)}
                      />
                      <button
                          className={cls.btn_ok}
                          onClick={changeName}
                      >
                        {trans('Ok', language)}
                      </button>
                    </>
              }
            </div>
            <div className={cls.entity}>
              <div className={cls.info_wrapper}>
                <label htmlFor='_'>{trans('Website address', language)}</label>
                <span className={cls.data}>{currentProject?.site}</span>
              </div>
            </div>
            <div className={cls.entity}>
              <div className={cls.info_wrapper}>
                <label htmlFor='_'>{trans('Project Id', language)}</label>
                <span className={cls.data}>{projectId}</span>
              </div>
            </div>
            <div className={activeStatus}>
              <div className={cls.info_wrapper}>
                <label htmlFor='project_name'>{trans('Project status', language)}</label>
                <span className={cls.data}>
              {
                dataProject ?
                    (showStatus as any)[`${dataProject.status}`] :
                    (showStatus as any)[`${currentProject?.status}`]
              }
            </span>
              </div>
              <div className={`${cls.select_wrapper}  setting__select-wrapper`}>
                <Select selectActive={dataProject ? (showStatus as any)[`${dataProject.status}`] :
                    (showStatus as any)[`${currentProject?.status}`]} dataset={showStatus}
                        onChangeActive={onChangeStatusValue} widthAuto={true}/>
              </div>
            </div>
            {/*<div className={cls.entity}>*/}
            {/*  <div className={cls.info_wrapper}>*/}
            {/*    <label htmlFor='switcher'>{trans('Accept data only from this domain and subdomain', language)}</label>*/}
            {/*    <Toggle*/}
            {/*      className={cls.switch}*/}
            {/*      name={'status'}*/}
            {/*      isSlider={true}*/}
            {/*      isChecked={dataProject ? dataProject.receive_data_only_from_domain : currentProject?.receive_data_only_from_domain}*/}
            {/*      onChange={(e)=>changeRestrictData(e)}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className={cls.input_entity}>*/}
            {/*  <div className={cls.info_wrapper}>*/}
            {/*    <label htmlFor='project_name'>{trans('Mail for administrative documents', language)}</label>*/}
            {/*    <span className={cls.data}>{dataProject ? dataProject.admin_email : currentProject?.admin_email}</span>*/}
            {/*  </div>*/}
            {/*  {*/}
            {/*    mailBtn*/}
            {/*      ?*/}
            {/*      <button*/}
            {/*        onClick={() => showMailBtn(!mailBtn)}*/}
            {/*        name='project_name'*/}
            {/*        className={cls.hide_button}*/}
            {/*      >*/}
            {/*        {trans('Change', language)}*/}
            {/*      </button>*/}
            {/*      :*/}
            {/*      <>*/}
            {/*        <input*/}
            {/*          className={cls.show_input}*/}
            {/*          type='text'*/}
            {/*          placeholder={trans('Input new email', language)}*/}
            {/*          value={dataProject ? dataProject.admin_email : currentProject?.admin_email}*/}
            {/*          onChange={(e:ChangeEvent<HTMLInputElement>) => changeMailInput(e.target.value)}*/}
            {/*        />*/}
            {/*        <button*/}
            {/*          className={cls.btn_ok}*/}
            {/*          onClick={changeMail}*/}
            {/*        >*/}
            {/*          {trans('Ok', language)}*/}
            {/*        </button>*/}
            {/*      </>*/}
            {/*  }*/}
            {/*</div>*/}
            <div className={activeCurrency}>
              <div className={cls.info_wrapper}>
                <label htmlFor='project_name'>{trans('Currency', language)}</label>
                <span className={cls.data}>
              <span className={cls.data}>
                {(Currency as any)[dataProject !== null && dataProject.currency ? dataProject.currency : currentProject && currentProject.currency ? currentProject.currency : '']}
              </span>
            </span>
              </div>
              <div className={`${cls.select_wrapper}  setting__select-wrapper`}>
                <Select
                    selectActive={(Currency as any)[dataProject !== null && dataProject.currency ? dataProject.currency : currentProject && currentProject.currency ? currentProject.currency : '']}
                    dataset={Currency} onChangeActive={onChangeCurrencyValue}/>
              </div>
            </div>
            <div className={activeTimezone}>
              <div className={cls.info_wrapper}>
                <label htmlFor='project_name'>{trans('Project timezone', language)}</label>
                <span className={cls.data}>
                {(TIMEZONES as any)[dataProject !== null && dataProject.timezone ? dataProject.timezone : currentProject && currentProject.timezone ? currentProject.timezone : '']}
              </span>
              </div>
              <div className={`${cls.select_wrapper}  setting__select-wrapper`}>
                <Select
                    selectActive={(TIMEZONES as any)[dataProject !== null && dataProject.timezone ? dataProject.timezone : currentProject && currentProject.timezone ? currentProject.timezone : '']}
                    dataset={TIMEZONES}
                    onChangeActive={onChangeTimezoneValue}
                    widthAuto={true}
                    search={true}
                />
                {/*<select*/}
                {/*    value={dataProject !== null && dataProject.timezone ? dataProject.timezone : currentProject?.timezone}*/}
                {/*    onChange={onChangeTimezone}*/}
                {/*    onClick={()=>checkActiveTimezone(flagTimezone)}*/}
                {/*>*/}
                {/*  {Object.values(list).map((item, index)=> (*/}
                {/*      <option key={index} value={item}>{item}</option>*/}
                {/*  ))}*/}
                {/*</select>*/}
                {/*<div className={cls.select_arrow}></div>*/}
              </div>
            </div>
            <Accordion title={trans('Advertiser', language)} className={'setting__accordion'}>
              <div className={cls.block}>
                <div className={cls.input_entity}>
                  <div className={cls.info_wrapper}>
                    <label htmlFor='adv_name'>{trans('Advertiser name', language)}</label>
                    <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.adv_name ? dataProject.adv_name : 'Не установлено'}` :
                        `${currentProject && currentProject.adv_name ? currentProject.adv_name : ''}`}
                  </span>
                  </div>
                  <ChangeByBtn
                      classNameBtn={cls.hide_button}
                      classNameInput={`${cls.show_input} setting-item-change__input`}
                      classNameSubmit={`${cls.btn_ok} setting-item-change__submit`}
                      format={'input'}
                      type={'string'}
                      name={'adv_name'}
                      value={dataProject ?
                          `${dataProject.adv_name ? dataProject.adv_name : ''}` :
                          `${currentProject && currentProject.adv_name ? currentProject.adv_name : ''}`}
                      onChange={(value) => handleFieldChange('adv_name', value)}
                  />
                </div>
                <div className={cls.input_entity}>
                  <div className={cls.info_wrapper}>
                    <label htmlFor='adv_address'>{trans('Address', language)}</label>
                    <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.adv_address ? dataProject.adv_address : 'Не установлено'}` :
                        `${currentProject && currentProject.adv_address ? currentProject.adv_address : ''}`}
                  </span>
                  </div>
                  <ChangeByBtn
                      classNameBtn={cls.hide_button}
                      classNameInput={`${cls.show_input} setting-item-change__input`}
                      classNameSubmit={`${cls.btn_ok} setting-item-change__submit`}
                      format={'input'}
                      type={'string'}
                      name={'adv_address'}
                      value={dataProject ?
                          `${dataProject.adv_address ? dataProject.adv_address : ''}` :
                          `${currentProject && currentProject.adv_address ? currentProject.adv_address : ''}`}
                      onChange={(value) => handleFieldChange('adv_address', value)}
                  />
                </div>
                <div className={cls.input_entity}>
                  <div className={cls.info_wrapper}>
                    <label htmlFor='adv_inn'>{trans('INN', language)}</label>
                    <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.adv_inn ? dataProject.adv_inn : 'Не установлено'}` :
                        `${currentProject && currentProject.adv_inn ? currentProject.adv_inn : ''}`}
                  </span>
                  </div>
                  <ChangeByBtn
                      classNameBtn={cls.hide_button}
                      classNameInput={`${cls.show_input} setting-item-change__input`}
                      classNameSubmit={`${cls.btn_ok} setting-item-change__submit`}
                      format={'input'}
                      type={'string'}
                      name={'adv_inn'}
                      value={dataProject ?
                          `${dataProject.adv_inn ? dataProject.adv_inn : ''}` :
                          `${currentProject && currentProject.adv_inn ? currentProject.adv_inn : ''}`}
                      onChange={(value) => handleFieldChange('adv_inn', value)}
                  />
                </div>
                <div className={cls.input_entity}>
                  <div className={cls.info_wrapper}>
                    <label htmlFor='adv_ogrn'>{trans('OGRN', language)}</label>
                    <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.adv_ogrn ? dataProject.adv_ogrn : 'Не установлено'}` :
                        `${currentProject && currentProject.adv_ogrn ? currentProject.adv_ogrn : ''}`}
                  </span>
                  </div>
                  <ChangeByBtn
                      classNameBtn={cls.hide_button}
                      classNameInput={`${cls.show_input} setting-item-change__input`}
                      classNameSubmit={`${cls.btn_ok} setting-item-change__submit`}
                      format={'input'}
                      type={'string'}
                      name={'adv_ogrn'}
                      value={dataProject ?
                          `${dataProject.adv_ogrn ? dataProject.adv_ogrn : ''}` :
                          `${currentProject && currentProject.adv_ogrn ? currentProject.adv_ogrn : ''}`}
                      onChange={(value) => handleFieldChange('adv_ogrn', value)}
                  />
                </div>
              </div>
            </Accordion>

            <Accordion title={trans('Custom viewability', language)} className={'setting__accordion'}>
              <div className={cls.block}>
                <div className={cls.input_entity}>
                  <div className={cls.info_wrapper}>
                    <label htmlFor='cv_delay'>{trans('CV time', language)}</label>
                    <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_delay ? dataProject.cv_delay : 'Не установлено'}` :
                        `${currentProject && currentProject.cv_delay ? currentProject.cv_delay : ''}`}
                  </span>
                  </div>
                  <ChangeByBtn
                      classNameBtn={cls.hide_button}
                      classNameInput={`${cls.show_input} setting-item-change__input`}
                      classNameSubmit={`${cls.btn_ok} setting-item-change__submit`}
                      format={'input'}
                      type={'number'}
                      name={'cv_delay'}
                      value={dataProject ?
                          `${dataProject.cv_delay ? dataProject.cv_delay : ''}` :
                          `${currentProject && currentProject.cv_delay ? currentProject.cv_delay : ''}`}
                      onChange={(value) => handleFieldChange('cv_delay', value)}
                      limitNumber={60}
                  />
                </div>
                <div className={cls.input_entity}>
                  <div className={cls.info_wrapper}>
                    <label htmlFor='cv_per'>{trans('CV area', language)}</label>
                    <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_per ? dataProject.cv_per : 'Не установлено'}` :
                        `${currentProject && currentProject.cv_per ? currentProject.cv_per : ''}`}
                  </span>
                  </div>
                  <ChangeByBtn
                      classNameBtn={cls.hide_button}
                      classNameInput={`${cls.show_input} setting-item-change__input`}
                      classNameSubmit={`${cls.btn_ok} setting-item-change__submit`}
                      format={'input'}
                      type={'number'}
                      name={'cv_per'}
                      value={dataProject ?
                          `${dataProject.cv_per ? dataProject.cv_per : ''}` :
                          `${currentProject && currentProject.cv_per ? currentProject.cv_per : ''}`}
                      onChange={(value) => handleFieldChange('cv_per', value)}
                      limitNumber={100}
                  />
                </div>
                <div className={cls.input_entity}>
                  <div className={cls.info_wrapper}>
                    <label htmlFor='cv_psc'>{trans('CV psc', language)}</label>
                    <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_psc ? dataProject.cv_psc : 'Не установлено'}` :
                        `${currentProject && currentProject.cv_psc ? currentProject.cv_psc : ''}`}
                  </span>
                  </div>
                  <ChangeByBtn
                      classNameBtn={cls.hide_button}
                      classNameInput={`${cls.show_input} setting-item-change__input`}
                      classNameSubmit={`${cls.btn_ok} setting-item-change__submit`}
                      format={'input'}
                      type={'number'}
                      name={'cv_psc'}
                      value={dataProject ?
                          `${dataProject.cv_psc ? dataProject.cv_psc : ''}` :
                          `${currentProject && currentProject.cv_psc ? currentProject.cv_psc : ''}`}
                      onChange={(value) => handleFieldChange('cv_psc', value)}
                      limitNumber={100}
                  />
                </div>
                <div className={cls.input_entity}>
                  <div className={cls.info_wrapper}>
                    <label htmlFor='cv_pcsv'>{trans('CV pcsv', language)}</label>
                    <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_pcsv ? dataProject.cv_pcsv : 'Не установлено'}` :
                        `${currentProject && currentProject.cv_pcsv ? currentProject.cv_pcsv : ''}`}
                  </span>
                  </div>
                  <ChangeByBtn
                      classNameBtn={cls.hide_button}
                      classNameInput={`${cls.show_input} setting-item-change__input`}
                      classNameSubmit={`${cls.btn_ok} setting-item-change__submit`}
                      format={'input'}
                      type={'number'}
                      name={'cv_pcsv'}
                      value={dataProject ?
                          `${dataProject.cv_pcsv ? dataProject.cv_pcsv : ''}` :
                          `${currentProject && currentProject.cv_pcsv ? currentProject.cv_pcsv : ''}`}
                      onChange={(value) => handleFieldChange('cv_pcsv', value)}
                      limitNumber={100}
                  />
                </div>
                <div className={cls.input_entity}>
                  <div className={cls.info_wrapper}>
                    <label htmlFor='cv_cht'>{trans('CV cht', language)}</label>
                    <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_cht ? dataProject.cv_cht : 'Не установлено'}` :
                        `${currentProject && currentProject.cv_cht ? currentProject.cv_cht : ''}`}
                  </span>
                  </div>
                  <ChangeByBtn
                      classNameBtn={cls.hide_button}
                      classNameInput={`${cls.show_input} setting-item-change__input`}
                      classNameSubmit={`${cls.btn_ok} setting-item-change__submit`}
                      format={'input'}
                      type={'number'}
                      name={'cv_cht'}
                      value={dataProject ?
                          `${dataProject.cv_cht ? dataProject.cv_cht : ''}` :
                          `${currentProject && currentProject.cv_cht ? currentProject.cv_cht : ''}`}
                      onChange={(value) => handleFieldChange('cv_cht', value)}
                      limitNumber={60}
                  />
                </div>
                <div className={cls.input_entity}>
                  <div className={cls.info_wrapper}>
                    <label htmlFor='cv_ums'>{trans('CV ums', language)}</label>
                    <span className={cls.data}>
                    {dataProject ?
                        `${dataProject.cv_ums ? dataProject.cv_ums : 'Не установлено'}` :
                        `${currentProject && currentProject.cv_ums ? currentProject.cv_ums : ''}`}
                  </span>
                  </div>
                  <ChangeByBtn
                      classNameBtn={cls.hide_button}
                      classNameInput={`${cls.show_input} setting-item-change__input`}
                      classNameSubmit={`${cls.btn_ok} setting-item-change__submit`}
                      format={'input'}
                      type={'number'}
                      name={'cv_ums'}
                      value={dataProject ?
                          `${dataProject.cv_ums ? dataProject.cv_ums : ''}` :
                          `${currentProject && currentProject.cv_ums ? currentProject.cv_ums : ''}`}
                      onChange={(value) => handleFieldChange('cv_ums', value)}
                      limitNumber={60}
                  />
                </div>
              </div>
            </Accordion>
            <Accordion title={trans('Black list', language)} className={'setting__accordion'}>
              <div className={cls.block}>
                <div className={cls.wrapper}>
                  <label htmlFor='black_list_domain'>{trans('Domains', language)}</label>
                  <UploadFile
                      text={trans('Upload your blacklist of domains', language)}
                      uploadedFilename={dataProject && (dataProject['black_list_domain'] || dataProject['black_list_domain_url']) ? 'domains.txt' : undefined}
                      fileType={'txt'}
                      onChange={(data: any) => {
                        if (data && data.file) {
                          handleFieldChange('black_list_domain', data.file)
                        }
                      }}
                      onRemove={() => {
                        handleFieldChange('black_list_domain', undefined);
                      }}
                      isInvalid={false}
                  />
                  {dataProject && dataProject['black_list_domain_url'] &&
                      <a className='upload-content__link' href={dataProject['black_list_domain_url']}
                         target={'_blank'} rel={'noreferrer'}>{trans('Open the uploaded file', language)}</a>
                  }
                </div>
              </div>
            </Accordion>

          </div>
          <div className={`setting__bottom`}>
            <div className='setting__btns'>
              <button className={exitCounter ? cls.submit_btn : cls.submit_btn_disactive} onClick={onSubmit}>
                {trans('Save changes', language)}
              </button>
              {isDelete && <Btn className={cls.delete_btn} text={(() => {
                const status = dataProject ?
                    dataProject.status :
                    currentProject?.status;
                if (status && status === 'archive') {
                  return trans('Active the project', language);
                }
                return trans('Archive the project', language);
              })()} onClick={onDelete}/>}
            </div>

          </div>

        </div>
      </>
    )
  } else {
    return (
        <div className={cls.content}>
          <div className={cls.form}>
            <div className={cls.entity}>
              <div className={cls.info_wrapper}>
                <label htmlFor='project_name'>{trans('Project name', language)}</label>
                <span className={cls.data}>{currentProject?.name}</span>
              </div>
            </div>
            <div className={cls.entity}>
              <div className={cls.info_wrapper}>
                <label htmlFor='_'>{trans('Website address', language)}</label>
                <span className={cls.data}>{currentProject?.site}</span>
              </div>
            </div>
            <div className={cls.entity}>
              <div className={cls.info_wrapper}>
                <label htmlFor='project_name'>{trans('Project status', language)}</label>
                <span className={cls.data}>
              {
                dataProject !== null && dataProject.status ?
                  (showStatus as any)[`${dataProject.status}`] :
                  (showStatus as any)[`${currentProject?.status}`]
              }
                </span>
              </div>
            </div>
            {/*<div className={cls.entity}>*/}
            {/*  <div className={cls.info_wrapper}>*/}
            {/*    <label htmlFor='switcher'>Принимать данные только с этого домена и поддомена</label>*/}
            {/*    <label className={cls.switch}>*/}
            {/*      <input id='switcher' type="checkbox" onChange={changeDataDomain} />*/}
            {/*      <span className={`${cls.slider} ${cls.round}`}></span>*/}
            {/*    </label>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className={cls.entity}>*/}
            {/*  <div className={cls.info_wrapper}>*/}
            {/*    <label htmlFor='project_name'>{trans('Mail for administrative documents', language)}</label>*/}
            {/*    <span className={cls.data}>{userData.email ? userData.email : user?.email}</span>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={cls.entity}>
              <div className={cls.info_wrapper}>
                <label htmlFor='project_name'>{trans('Currency', language)}</label>
                <span className={cls.data}>
               <span className={cls.data}>{dataProject !== null && dataProject.currency ? dataProject.currency : currentProject?.currency}</span>
              </span>
              </div>
            </div>
            <div className={cls.entity}>
              <div className={cls.info_wrapper}>
                <label htmlFor='project_name'>{trans('Project timezone', language)}</label>
                <span className={cls.data}>{ dataProject !== null && dataProject.timezone ? dataProject.timezone : currentProject?.timezone}</span>
              </div>
            </div>
            {/*<button className={cls.submit_btn} onClick={onSubmit}>Сохранить изменения</button>*/}
          </div>
        </div>
      )
    }
  // }

};

export default PlainSettings;
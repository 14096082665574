import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import { RootState } from '..'
import { DefaultObject } from '../../interfaces/common.d';

export enum ModalName {
  NEW_PROJECT = 'NEW_PROJECT',
  NEW_MARKETING_CAMPAIGN = 'NEW_MARKETING_CAMPAIGN',
  EDIT_MARKETING_CAMPAIGN = 'EDIT_MARKETING_CAMPAIGN',
  NEW_CAMPAIGN = 'NEW_CAMPAIGN',
  NEW_PLATFORM = 'NEW_PLATFORM',
  NEW_SOURCE = 'NEW_SOURCE',
  CAMPAIGN_EDITING = 'CAMPAIGN_EDITING',
  CAMPAIGN_BASED = 'CAMPAIGN_BASED',
  PROJECT_EDITING = 'PROJECT_EDITING',
  PLATFORM_EDITING = 'PLATFORM_EDITING',
  PLATFORM_BASED = 'PLATFORM_BASED',
  ADD_ACCESS = 'ADD_ACCESS',
  MESSAGE = 'MESSAGE',
  INFORMATION = 'INFORMATION',
  NAME_EDITING = 'NAME_EDITING',
  EMAIL_EDITING = 'EMAIL_EDITING',
  PASSWORD_EDITING = 'PASSWORD_EDITING',
  CONNECT_ADRIVER = 'CONNECT_ADRIVER',
  ADD_EMAIL = 'ADD_EMAIL',
  LIMIT_TRAFFIC = 'LIMIT_TRAFFIC',
  DELETE_USER = 'DELETE_USER',
  EDIT_USER = 'EDIT_USER',
  ACCESS_TIME = 'ACCESS_TIME',
  LAST_ACTIVITY = 'LAST_ACTIVITY',
  ADD_EVENT = 'ADD_EVENT',
  ADD_INTEGRATION = 'ADD_INTEGRATION',
  UPDATE_INTEGRATION = 'UPDATE_INTEGRATION',
  CONFIRM_EXIT = 'CONFIRM_EXIT',
  CONFIRM_DELETE = 'CONFIRM_DELETE',
  FILTER = 'FILTER',
  EDIT_SELECT = 'EDIT_SELECT',
  EDIT_SOURCE = 'EDIT_SOURCE',
  TOKEN_CREATE = 'TOKEN_CREATE',
  TOKEN_EDIT = 'TOKEN_EDIT',
  EDIT_MULTI_CAMPAIGNS = 'EDIT_MULTI_CAMPAIGNS',
  EDIT_MULTI_PLACEMENTS = 'EDIT_MULTI_PLACEMENTS',
  DOCS_CREATE_PROJECT = 'DOCS_CREATE_PROJECT',
  DOCS_CREATE_CAMPAIGN_FULL = 'DOCS_CREATE_CAMPAIGN_FULL',
  DOCS_CREATE_CUSTOM_DASHBOARD = 'DOCS_CREATE_CUSTOM_DASHBOARD',
  DASHBOARD_FULLSCREEN = 'DASHBOARD_FULLSCREEN',
  CONFIRM_DELETE_SOURCES='CONFIRM_DELETE_SOURCES',
  SETTINGS_ROLE_CREATE='SETTINGS_ROLE_CREATE',
  SETTINGS_ROLE_EDIT='SETTINGS_ROLE_EDIT'
}

export interface ModalWithData {
  name: ModalName
  data: DefaultObject<any>
}

export interface ModalsState {
  modalStack: ModalName[]
  modalForAnimation: ModalName | null
  data: {
    [key in ModalName]?: DefaultObject<any>;
  }
}

const initialState: ModalsState = {
  modalStack: [] as ModalName[],
  modalForAnimation: null,
  data: {}
}

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalName | ModalWithData>) => {
      if (typeof action.payload !== 'object') {
        state.modalStack.push(action.payload);
        return;
      }

      state.modalStack.push(action.payload.name);
      state.data[action.payload.name] = Object.assign({}, action.payload.data);
    },
    closeModal: (state, action: PayloadAction<ModalName>) => {
      state.modalStack = state.modalStack.filter((modal) => modal !== action.payload);
      state.modalForAnimation = null;
      if (state.modalStack.length === 0)
        state.data = {} as DefaultObject<any>;
    },
    closeAll: (state) => {
      state.modalStack = [];
      state.modalForAnimation = null;
      state.data = {} as DefaultObject<any>;
    },
    addAnimation: (state, action: PayloadAction<ModalName>) => {
      state.modalForAnimation = action.payload;
    }
  }
})

export const { openModal, closeModal, closeAll, addAnimation } = modalsSlice.actions

export const modalStack = createSelector(
  (state: RootState) => state.modals,
  (modals) => modals.modalStack
)

export const modalForAnimation = createSelector(
  (state: RootState) => state.modals,
  (modals) => modals.modalForAnimation
)

export const dataForModal = createSelector(
  (state: RootState) => state.modals,
  (modals) => modals.data
)

export default modalsSlice.reducer

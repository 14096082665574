import {FC, useEffect, useMemo, useRef, useState} from "react";
import {trans} from "../../../_locales";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {currentUserLang} from "../../../redux/slices/user";

import "./style.scss";
import TableFunnelHead from "./Head";
import TableFunnelLabels from "./Labels";
import TableFunnelBody from "./Body";
import Loader from "../../Loader";
import Empty from "../../Empty";
import {IFilterColumns, IOnChangeFilter, IPinColumns, ISearchColumns, ISortColumns, IStretchList} from "../interfaces";
import {DefaultObject, ICampaign, IPlacement, ISources} from "../../../interfaces/common.d";
import {IMarketingItem} from "../../../redux/slices/marketing";
import {useElementWidth} from "../../../hooks/useElementWidth";
import {computeTableSizes, gatherIdsByType, handleMouseMove, IStretchSettingItem} from "./functions";
import {ReactComponent as FilterNotFound} from "../../../assets/icons/filter-not-found.svg";
import {ReactComponent as SearchNotFound} from "../../../assets/icons/search-not-found.svg";
import {openPopup} from "../../../redux/slices/popup";
import {PopupType} from "../../../data";
import MultipleActions from "../../MultipleActions";
import {getCampaignsGenerals} from "../../../actions/media";
import {ModalName, openModal} from "../../../redux/slices/modals";
import {IViewMode} from "../../../pages/Projects/Project/MarketingCampaigns/Item";


interface ITableFunnelTags {
    name: string
}

export interface ITableCheckedWithType {
    campaigns?: DefaultObject<boolean>,
    sources?: DefaultObject<boolean>,
    placements?: DefaultObject<boolean>,
    creatives?: DefaultObject<boolean>,
}
export type ITableCheckedTypes = 'campaigns'|'sources'|'placements'|'creatives';

export interface ITableFunnelData {
    id?: number
    name: string,
    onboarding_url: string,
    project_id: number,
    created_at: string,
    status: string,
    tags?: Array<ITableFunnelTags>
}

export type ITableFunnelSize = DefaultObject<number>;
interface IDefaultSettingSizeItem {
  default: string | number; // "auto" | "40%" | 200
  min: string | number;     // "10%" | 50
  max: string | number;     // "70%" | 300
}
export type ITableFunnelDefaultSize = DefaultObject<IDefaultSettingSizeItem>;

export interface ITableFunnelFilter {
    search?: string,
    filter?: DefaultObject<any>,
    sort?: ISortColumns,
}

export type ITableFunnelStatus = 'active' | 'archive';


interface ITableFunnel {
    projectId: number,
    type: ITableCheckedTypes
    data: Array<IMarketingItem|ICampaign|IPlacement|ISources>,
    dataReady?:boolean,
    onCreate: ()=>void,
    onCreateDaughter?: (id:number)=>void,
    onEdit: (id:number, data: IMarketingItem|ICampaign|IPlacement|ISources)=>void,
    onChangeStatus: (id:number, status: ITableFunnelStatus, type?: ITableCheckedTypes)=>void,
    onUpload?: (event: any)=>void
    filter: ITableFunnelFilter,
    onFilter: (data: ITableFunnelFilter)=>void
    onDownload: (mode: 'all' | 'active' | number[], type?: ITableCheckedTypes) => void
    onDownloadTemplate?: (mode: 'all' | 'active' | number[], type?: ITableCheckedTypes) => void
    isDisabled?: boolean

    columnPreferences: Array<DefaultObject<any>>,
    fixedChoice: string[],
    metricChoice: string[],
    metricChoiceActive: string[],
    onChangeMetricChoiceActive: (value: string[]) => void,
    stretch?: IStretchList,
    activePin?: IPinColumns,
    onChangePin?:(i:number)=>void,
    defaultSettingSize?:ITableFunnelDefaultSize,
    parentType: ITableCheckedTypes,
    isOnlyParent?: boolean,
    onChangeParent?: (parent: IViewMode, id?: number) => void,
    viewMode?: IViewMode,
    isView?:boolean
    isCreate?:boolean
    isEdit?:boolean
}

const TableFunnel:FC<ITableFunnel> = ({
    type,
    data,
    projectId,
    onCreate,
    onCreateDaughter,
    onEdit,
    onChangeStatus,
    onUpload,
    filter,
    onFilter,
    onDownload,
    onDownloadTemplate,
    columnPreferences,
    fixedChoice=[],
    metricChoice,
    metricChoiceActive=[],
    onChangeMetricChoiceActive,
    stretch,
    activePin,
    onChangePin,
    defaultSettingSize,
    isDisabled=false,
    dataReady,
    parentType,
    viewMode,
    isOnlyParent=false,
    onChangeParent,
    isView,
    isCreate,
    isEdit
}) => {
    const language = useAppSelector(currentUserLang);
    const dispatch = useAppDispatch();

    const [dataWrapper, setDataWrapper] = useState<Array<IMarketingItem|ICampaign|IPlacement|ISources>>(data);
    const [dataCopy, setDataCopy] = useState<Array<IMarketingItem|ICampaign|IPlacement|ISources>>([]);
    const [choiceIndexes, setChoiceIndexes] = useState<number[]>([]);
    const [choiceActive, setChoiceActive] = useState<string[]>(metricChoiceActive);
    const [settingSize, setSettingSize] = useState<ITableFunnelSize>({});
    const [activeSearch, setActiveSearch] = useState<string>('');
    const [activeChecked, setActiveChecked] = useState<ITableCheckedWithType>({})

    useEffect(() => {
        if(metricChoiceActive) {
            setChoiceActive(metricChoiceActive)
        }
    }, [metricChoiceActive]);

    const { campaignIds, sourceIds, placementIds, creativeIds } = useMemo(() => {
        return gatherIdsByType(dataWrapper);
    }, [dataWrapper]);

    const { isAllChecked, isSomeChecked, totalCheckedIds, checkedType } = useMemo(() => {
        // Подсчитываем, сколько выделено (true) для каждого типа
        const campaignsIds = Object.entries(activeChecked.campaigns ?? {})
            .filter(([_, value]) => value)
            .map(([key]) => Number(key));
        const sourcesIds = Object.entries(activeChecked.sources ?? {})
            .filter(([_, value]) => value)
            .map(([key]) => Number(key));
        const placementsIds = Object.entries(activeChecked.placements ?? {})
            .filter(([_, value]) => value)
            .map(([key]) => Number(key));
        const creativesIds = Object.entries(activeChecked.creatives ?? {})
            .filter(([_, value]) => value)
            .map(([key]) => Number(key));

        // Проверяем, для какого типа все элементы выбраны
        const isAllCampaigns = (campaignIds.length > 0) && (campaignIds.length === campaignsIds.length);
        const isAllSources = (sourceIds.length > 0) && (sourceIds.length === sourcesIds.length);
        const isAllPlacements = (placementIds.length > 0) && (placementIds.length === placementsIds.length);
        const isAllCreatives = (creativeIds.length > 0) && (creativeIds.length === creativesIds.length);

        // "isAllChecked" == true, если хотя бы один тип выбран полностью
        const isAll = isAllCampaigns || isAllSources || isAllPlacements || isAllCreatives;

        // "isSomeChecked" == true, если есть хотя бы один выделенный элемент
        const isSome = campaignsIds.length > 0 || sourcesIds.length > 0 || placementsIds.length > 0 || creativesIds.length > 0;
        const totalCheckedIds = [...campaignsIds, ...sourcesIds, ...placementsIds, ...creativesIds];
        const checkedType: ITableCheckedTypes|undefined = (campaignsIds.length > 0 && 'campaigns') || (sourcesIds.length > 0 && 'sources') || (placementsIds.length > 0 && 'placements') || (creativesIds.length > 0 && 'creatives') || undefined;

        return {
            isAllChecked: isAll,
            isSomeChecked: isSome,
            totalCheckedIds,
            checkedType
        };
    }, [
        activeChecked,
        campaignIds,
        sourceIds,
        placementIds,
        creativeIds
    ]);

    useEffect(() => {
        try {
            const temp = JSON.parse(JSON.stringify(data));
            setDataCopy(temp);
            onSearch(activeSearch, temp);
        } catch (e) {
            console.error(e);
        }
    }, [data]);

    const onAllChecked = (flag: boolean, type: ITableCheckedTypes) => {
        const temp: ITableCheckedWithType = {};
        if (flag) {
            temp[type] = dataWrapper.reduce((acc, item) => {
                acc[item.id] = true;
                return acc;
            }, {} as DefaultObject<boolean>);
        } else {
            temp[type] = {};
        }
        setActiveChecked(temp);
    }
    const onChecked = (id: number, flag: boolean, type: ITableCheckedTypes) => {
        const temp = JSON.parse(JSON.stringify(activeChecked)) as ITableCheckedWithType;

        if (flag) {
            let conflictType = type;
            const hasConflict = (Object.keys(temp) as ITableCheckedTypes[])
                .some((tableType) => {
                    if (tableType === type) return false; // не сравниваем с самим собой
                    const typeData = temp[tableType];
                    if (!typeData) return false;
                    if(Object.values(typeData).some(Boolean)) {
                        conflictType = tableType;
                        return true
                    }
                    return false;
                });

            if (hasConflict) {
                const titles = {
                    'campaigns': 'Кампания',
                    'sources': 'Источник',
                    'placements': 'Размещение',
                    'creatives': 'Креатив',
                }
                const message = `Несовместимый тип объекта. Выберите ${titles[conflictType]}`;
                dispatch(openPopup({ type: PopupType.WARNING, description: message }));
                return;
            }
        }

        temp[type] = temp[type] || {};
        (temp[type] as DefaultObject<boolean>)[id] = flag;

        setActiveChecked(temp);
    };

    const tableRef = useRef<HTMLDivElement>(null);
    const tableWidth = useElementWidth(tableRef);
    const [currentStretch, setCurrentStretch] = useState<IStretchSettingItem | null>(null);

    const handleMouseUp = (e:any) => {
        if(currentStretch) {
            setCurrentStretch(null);
        }
    }

    const handleSize = (setting: ITableFunnelDefaultSize) => {
        setSettingSize(computeTableSizes({
            defaultSettingSize: setting,
            width: tableWidth,
            minWidth: 150,
            choice: [...fixedChoice, ...choiceActive]
        }))
    }

    useEffect(() => {
        //кейс когда мы изменили ширину таблицы после того как сами расстянули какие-то блоки?
        if(defaultSettingSize) {
            handleSize(defaultSettingSize);
        }
    }, [tableWidth]);

    const onResize = (choices: string[]) => {
        setSettingSize(computeTableSizes({
            defaultSettingSize,
            width:tableWidth,
            minWidth: 150,
            // settingSize: JSON.parse(JSON.stringify(settingSize)),
            choice: [...fixedChoice, ...choices]
        }));
    }

    const onStretch = (value: IStretchSettingItem) => {
        setCurrentStretch(value);
    }

    const onSearch = (value: string, data: (IMarketingItem|ICampaign|IPlacement|ISources)[]) => {
        setActiveSearch(value);
        let tempData = JSON.parse(JSON.stringify(data));
        const checkValue = (regex: RegExp, name: string, id: number) => {
            return regex.test(name.toLowerCase()) || regex.test(String(id));
        }
        if (value) {
            const regex = new RegExp(value.toLowerCase());

            tempData = tempData
                .map((item: any) => {
                    const isItemMatched = checkValue(regex, item.name, item.id);
                    if (item.data_type === 'campaigns') {
                        item.sources = item.sources
                            ?.map((src: any) => {
                                const isSrcMatched = checkValue(regex, src.name, src.id);

                                src.placement = src.placement
                                    ?.map((pl: any) => {
                                        const isPlMatched = checkValue(regex, pl.name, pl.id);

                                        pl.creatives = pl.creatives?.filter((cr: any) =>
                                            checkValue(regex, cr.name, cr.id)
                                        );

                                        // Возвращаем placement только если он сам подходит
                                        // или содержит какие-то creatives
                                        return isPlMatched || (pl.creatives?.length > 0) ? pl : null;
                                    })
                                    .filter(Boolean);

                                // Возвращаем source только если он сам подходит
                                // или содержит какие-то placement
                                return isSrcMatched || (src.placement?.length > 0) ? src : null;
                            })
                            .filter(Boolean);

                        // Возвращаем кампанию только если она подходит сама
                        // или содержит хотя бы один source
                        return isItemMatched || (item.sources?.length > 0) ? item : null;
                    }

                    if (item.data_type === 'sources') {
                        const isSourceMatched = checkValue(regex, item.name, item.id);

                        item.placements = item.placements
                            ?.map((pl: any) => {
                                const isPlacementMatched = checkValue(regex, pl.name, pl.id);

                                pl.creatives = pl.creatives?.filter((cr: any) =>
                                    checkValue(regex, cr.name, cr.id)
                                );

                                return isPlacementMatched || (pl.creatives?.length > 0)
                                    ? pl
                                    : null;
                            })
                            .filter(Boolean);

                        return isSourceMatched || (item.placements?.length > 0) ? item : null;
                    }

                    // Если это placement
                    if (item.data_type === 'placements') {
                        const isPlacementMatched = checkValue(regex, item.name, item.id);

                        // Фильтруем creatives
                        item.creatives = item.creatives?.filter((cr: any) =>
                            checkValue(regex, cr.name, cr.id)
                        );

                        return isPlacementMatched || (item.creatives?.length > 0)
                            ? item
                            : null;
                    }

                    return isItemMatched ? item : null;
                })
                .filter(Boolean);
        }
        setDataWrapper(tempData);
    }

    const renderEmptyState = () => {
        const hasNoData = dataWrapper.length === 0 || columnPreferences.length === 0;
        const hasNoFilterObject =
            !filter.filter || Object.keys(filter.filter).length === 0;
        const isFilterActiveOnly =
            filter.filter?.status?.length === 1 && filter.filter.status[0] === 'active';
        const isSearchUsed = Boolean(filter.search);

        if (hasNoData) {
            if (isSearchUsed || hasNoFilterObject || isFilterActiveOnly) {
                return <Empty
                    className={'absolute'}
                    title={trans('Nothing found', language) + '...'}
                    description={isSearchUsed ? {
                        first: 'No objects with that name or ID were found.',
                        second: 'Please change the search settings'
                    } : undefined}
                    icon={{
                        Component: SearchNotFound,
                        width: 48,
                        height: 48
                    }}
                    isBig={true}
                    btnCallback={() => {
                        onCreate();
                    }}
                    btnText={'Create'}
                    isCreate={isCreate}
                />;
            }
            return (
                <Empty
                    className={'absolute'}
                    title={trans('Nothing found', language) + '...'}
                    description={{
                        first: 'No objects were found for the specified parameters',
                        second: 'Please change the filter settings or clear it'
                    }}
                    btnCallback={() => {
                        onFilter({
                            search: '',
                            filter: {},
                            sort: {}
                        })
                    }}
                    btnText={'Clear filters'}
                    icon={{
                        Component: FilterNotFound,
                        width: 40,
                        height: 40
                    }}
                    isBig={true}
                    isCreate={true}
                />
            );
        }

        return <></>;
    }

    return(
        <>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
                className={'table-funnel'}
                onMouseMove={(e)=>{
                    if(currentStretch) {
                        const changes = handleMouseMove({
                            e,
                            current: currentStretch,
                            settingSize,
                            defaultSettingSize,
                            width: tableWidth
                        })
                        setSettingSize(changes);
                    }
                }}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                ref={tableRef}
            >
                <TableFunnelHead
                    projectId={projectId}
                    type={type !== 'sources' ? type : 'placements'}
                    onCreate={onCreate}
                    onFilter={onFilter}
                    onSearch={(value: string) => onSearch(value, dataCopy)}
                    onDownload={(mode: 'all'|'active'|'choice')=>{
                        if (mode === 'choice') {
                            onDownload(totalCheckedIds, checkedType);
                            return;
                        }
                        onDownload(mode, checkedType)
                    }}
                    onUpload={onUpload}
                    filter={{
                        search: activeSearch,
                        filter: filter['filter'],
                        sort: filter['sort']
                    }}
                    view={viewMode}
                    viewDataset={(()=> {
                        if(type === 'sources') {
                            return {
                                'sources_placements': 'Источники и размещения',
                                'sources': 'Источники, размещения и креативы',
                                'only_sources': 'Источники',
                                'only_placements': 'Размещения',
                                'placements': 'Размещения и Креативы',
                                'creatives': 'Креативы'
                            };
                        }
                        if(type === 'placements') {
                            return {
                                'placements': 'По умолчанию',
                                'only_placements': 'Размещения',
                                'creatives': 'Креативы'
                            }
                        }
                        if(type === 'creatives') {
                            return undefined;
                        }
                        return undefined;
                    })()}
                    onChangeParent={onChangeParent}
                    isView={isView}
                    isCreate={isCreate}
                    isEdit={isEdit}
                />
                <div className={'table-funnel__wrapper'}>
                    <TableFunnelLabels
                        data={dataCopy}
                        columnPreferences={columnPreferences}
                        choiceIndexes={choiceIndexes}
                        choiceActive={choiceActive}
                        choiceFixed={fixedChoice}
                        choice={metricChoice}
                        onChangeChoice={(value: string[])=>{
                            setChoiceActive(value);
                            onResize(value);
                            onChangeMetricChoiceActive(value);
                        }}
                        stretch={stretch}
                        filter={{
                            search: activeSearch,
                            filter: filter['filter'],
                            sort: filter['sort']
                        }}
                        activePin={activePin}
                        onChangePin={onChangePin}
                        onStretch={onStretch}
                        settingSize={settingSize}
                        onAllChecked={onAllChecked}
                        isAllChecked={isAllChecked}
                        isSomeChecked={isSomeChecked}
                        width={tableWidth}
                        parentType={parentType}
                        // onResize={onResize}
                    />
                    <TableFunnelBody
                        columnPreferences={columnPreferences}
                        data={dataWrapper}
                        activePin={activePin}
                        choiceActive={[...fixedChoice, ...choiceActive]}
                        settingSize={settingSize}
                        onClearFilter={()=>{
                            onFilter({
                                search: '',
                                filter: {},
                                sort: {}
                            });
                        }}
                        onCreate={onCreate}
                        onEdit={onEdit}
                        onChangeStatus={onChangeStatus}
                        activeChecked={activeChecked}
                        onChecked={onChecked}
                        onChangeParent={onChangeParent}
                        parentType={parentType}
                        isOnlyParent={isOnlyParent}
                        onCreateDaughter={onCreateDaughter}
                        viewMode={viewMode}
                        isCreate={isCreate}
                        isEdit={isEdit}
                    />
                    { (!dataReady) &&
                        <div className="table-funnel__loader">
                            <Loader inBlock={true} isNotTransparent={true}/>
                        </div>
                    }
                    {
                        renderEmptyState()
                    }
                </div>
            </div>
            {
              totalCheckedIds.length > 0 && <MultipleActions
                  count={totalCheckedIds.length}
                  type={checkedType}
                  onChange={()=>{
                    // dispatch(getCampaignsGenerals({projectId: projectId ? +projectId : -1, campaignIds: getChosenIds()})).then((r)=> {
                    //   let initialValues:any = {};
                    //   if(r.payload) {
                    //     initialValues = r.payload;
                    //   }
                    //   dispatch(openModal({
                    //     name: ModalName.EDIT_MULTI_CAMPAIGNS,
                    //     data: {
                    //       userId: reduxUserId,
                    //       projectId: projectId ? +projectId : -1,
                    //       ids: totalCheckedIds,
                    //       initialValues: initialValues
                    //     }
                    //   }))
                    // })
                  }}
                  onDownload={()=>onDownload(totalCheckedIds, checkedType)}
                  onDownloadTemplate={typeof onDownloadTemplate === 'function' ? ()=>onDownloadTemplate(totalCheckedIds, checkedType) : undefined}
                  onReset={()=> {
                    setActiveChecked({});
                  }}
                  isEdit={false}
                  isDelete={false}
              />
            }
        </>
    )
}

export default TableFunnel;
import {createAsyncThunk} from '@reduxjs/toolkit'
import {DefaultObject} from '../interfaces/common.d'
import {closeModal, ModalName, openModal} from '../redux/slices/modals'
import {downloadCsv, showPopup, updateToken} from '../utils'
import {getTags} from "./tags";
import {ITableFunnelFilter} from "../components/Tables/TableFunnel";
import {dispatch} from "jest-circus/build/state";
import {setCurrentMarketing} from "../redux/slices/marketing";

const REQUEST_TIMEOUT_TO_LOAD = 30000;

interface DownloadParams {
  projectId: number,
  filter?: ITableFunnelFilter
}

interface DownloadItemParams {
  projectId: number,
  marketingId: number,
  filter?: ITableFunnelFilter
}


export const getMarketingCampaign = createAsyncThunk(
  'marketing/getMarketingCampaign',
  async ({projectId, filter }: DownloadParams , { rejectWithValue, extra: API}: any) => {
    try {
      let query = '';
      if(filter) {
        if(filter.filter) {
          const status = filter.filter.status;
          if(Array.isArray(status) && status.length > 0) {
            query += `&status=${status.join(',')}`;
          }
        }
      }
      const response = await API.get(`/api/marketing/?project_id=${projectId}${query}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);

export const createMarketingCampaign = createAsyncThunk(
  'marketing/createMarketingCampaign',
  async (
    { data, projectId, filter }: { data: DefaultObject<any>, projectId: number, filter?: ITableFunnelFilter },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.post(`/api/marketing/?project_id=${projectId}`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(closeModal(ModalName.NEW_MARKETING_CAMPAIGN));
      showPopup(dispatch, response.data.message as string);
      dispatch(getMarketingCampaign({projectId: projectId, filter: filter}));
      // dispatch(getTags({projectId: projectId}));
      return response.data.data;
    } catch (e: any) {
      if(typeof e.response.data.detail === 'object' && e.response.data.detail.length > 0) {
        return rejectWithValue(e.response.statusText);
      }
      return rejectWithValue(e.response.data.detail);
    }
  }
);


export const editMarketingCampaign = createAsyncThunk(
  'marketing/editMarketingCampaign',
  async (
    { data, projectId, marketingId, filter }: { data: DefaultObject<any>, projectId: number, marketingId: number, filter?: ITableFunnelFilter },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const response = await API.put(`/api/marketing/${marketingId}/?project_id=${projectId}`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(closeModal(ModalName.EDIT_MARKETING_CAMPAIGN));
      showPopup(dispatch, response.data.message as string);
      dispatch(getMarketingCampaign({projectId: projectId, filter: filter}));
      // dispatch(getTags({projectId: projectId}));
      return response.data.data;
    } catch (e: any) {
      if(typeof e.response.data.detail === 'object' && e.response.data.detail.length > 0) {
        return rejectWithValue(e.response.statusText);
      }
      return rejectWithValue(e.response.data.detail);
    }
  }
);


export const editStatusMarketingCampaign = createAsyncThunk(
  'marketing/editMarketingCampaign',
  async (
    { status, projectId, marketingId, filter }: { status: 'active' | 'archive', projectId: number, marketingId: number, filter?: ITableFunnelFilter },
    { dispatch, rejectWithValue, extra: API }: any
  ) => {
    try {
      const data = {'status':status};
      const response = await API.put(`/api/marketing/${marketingId}/status/?project_id=${projectId}`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(closeModal(ModalName.EDIT_MARKETING_CAMPAIGN));
      showPopup(dispatch, response.data.message as string);
      dispatch(getMarketingCampaign({projectId: projectId, filter: filter}));
      // dispatch(getTags({projectId: projectId}));
      return response.data.data;
    } catch (e: any) {
      if(typeof e.response.data.detail === 'object' && e.response.data.detail.length > 0) {
        return rejectWithValue(e.response.statusText);
      }
      return rejectWithValue(e.response.data.detail);
    }
  }
);


export const getMarketingItem = createAsyncThunk(
  'marketing/getMarketingItem',
  async ({
    projectId,
    marketingId,
    filter
  }: DownloadItemParams ,
  { rejectWithValue,dispatch, extra: API}: any) => {
    try {
      let query = '';
      if(filter) {
        if(filter.filter) {
          const status = filter.filter.status;
          const status_creative = filter.filter.status_creative;
          const tags = filter.filter.tags;
          const sources = filter.filter.sources;
          const geo = filter.filter.geo;
          const targeting = filter.filter.targeting;
          const type_creative = filter.filter.type_creative;
          if(Array.isArray(status) && status.length > 0) {
            query += `&status=${status.join(',')}`;
          }
          if(Array.isArray(tags) && tags.length > 0) {
            query += `&tags=${tags.join(',')}`;
          }
          if(Array.isArray(sources) && sources.length > 0) {
            query += `&sources=${sources.join(',')}`;
          }
          if(Array.isArray(status_creative) && status_creative.length > 0) {
            query += `&c_status=${status_creative.join(',')}`;
          }
          if(Array.isArray(type_creative) && type_creative.length > 0) {
            query += `&c_type=${type_creative.join(',')}`;
          }
          if(Array.isArray(geo) && geo.length > 0) {
            query += `&c_geo=${geo.join(',')}`;
          }
          if(Array.isArray(targeting) && targeting.length > 0) {
            query += `&c_targeting=${targeting.join(',')}`;
          }
        }
      }
      const response = await API.get(`/api/marketing/${marketingId}/?project_id=${projectId}${query}`);
      const new_token = response.headers["x-new-token"];
      updateToken(new_token);
      dispatch(setCurrentMarketing(response.data));
      return response.data;
    } catch (e: any) {
      return rejectWithValue(e.response.data.detail);
    }
  }
);
import {FC, useEffect, useState} from "react";
import {SearchForm} from "../../../../ui";
import {Options} from "../../../index";
import {trans} from "../../../../_locales";
import {ITableCheckedTypes, ITableFunnelFilter} from "../index";

import {ReactComponent as PlusIcon} from '../../../../assets/icons/icon-plus.svg';
import {ReactComponent as ArrowUpIcon} from '../../../../assets/icons/icon-arrow-up.svg';
import {ReactComponent as ArrowDownIcon} from '../../../../assets/icons/icon-arrow-down.svg';

import "./style.scss";
import {useAppDispatch, useAppSelector} from "../../../../redux/hooks";
import {currentUserLang} from "../../../../redux/slices/user";
import {DefaultObject} from "../../../../interfaces/common.d";
import {IFilterState} from "../../../FilterUpgraded";
import {IViewMode} from "../../../../pages/Projects/Project/MarketingCampaigns/Item";
import Select from "../../../ui/Select";
import {onChangeValueCheckbox} from "../../../../utils";
import {onChangeGeneralSelect} from "../../../ui/Select/functions";
import {downloadPlacementsData} from "../../../../actions/placements";
import {getProjectGeneral} from "../../../../actions/project";
import {downloadCurrentCampaignData} from "../../../../actions/breadcrumbs";
import {getDashboardsFilterValues} from "../../../../actions/dashboards";

interface ITableFunnelHead {
    projectId: number,
    type: ITableCheckedTypes,
    onCreate: ()=>void
    onUpload?: (event: any)=>void
    onFilter: (data: ITableFunnelFilter)=>void
    onDownload: (mode: 'all' | 'active' | 'choice') => void
    isDisabled?: boolean,
    filter: ITableFunnelFilter,
    onSearch?: (value: string) => void,
    onChangeParent?: (parent: IViewMode) => void,
    view?: IViewMode,
    viewDataset?: {
        sources?: string,
        only_sources?: string,
        // '': 'Источники и размещения',
        only_placements?: string,
        placements?: string,
        creatives?: string,
        campaigns?: string
    },
    isView?:boolean,
    isCreate?:boolean,
    isEdit?:boolean
}

const TableFunnelHead:FC<ITableFunnelHead> = ({
    projectId,
    type,
    onCreate,
    onUpload,
    onFilter,
    onDownload,
    filter,
    onSearch,
    onChangeParent,
    view,
    viewDataset,
    isView,
    isCreate,
    isEdit
}) => {
    const language = useAppSelector(currentUserLang);
    const dispatch = useAppDispatch();
    const dataset:Record<IViewMode, string> = {
        'sources_placements': 'По умолчанию',
        'sources': 'Источники, размещения и креативы',
        'only_sources': 'Источники',
        'only_placements': 'Размещения',
        'placements': 'Размещения и Креативы',
        'creatives': 'Креативы',
        'campaigns': 'Кампании'
    }
    const [datasetGeo, setDatasetGeo] = useState<DefaultObject<string>>({});
    const [datasetTargeting, setDatasetTargeting] = useState<DefaultObject<string>>({});

    useEffect(() => {
        if (projectId) {
            dispatch(getDashboardsFilterValues({projectId: projectId ? +projectId : -1, name: 'geo'})).then((r) => {
                if (r.payload) {
                    const temp = {};
                    Object.keys(r.payload).map((item) => {
                        if (r.payload[item]) {
                            (temp as any)[item] = r.payload[item];
                        }
                    })
                    setDatasetGeo(temp)
                }
            })
            dispatch(getDashboardsFilterValues({
                projectId: projectId ? +projectId : -1,
                name: 'targeting'
            })).then((r) => {
                if (r.payload) {
                    const temp = {};
                    Object.keys(r.payload).map((item) => {
                        if (r.payload[item]) {
                            (temp as any)[item] = r.payload[item];
                        }
                    })
                    setDatasetTargeting(temp)
                }
            })
        }
    }, [projectId, dispatch]);

    return (
        <div className="table-funnel-header">
            {
                typeof onChangeParent === 'function' && viewDataset && (
                    <Select
                        className={'table-funnel-header__view'}
                        // color={'dark'}
                        colorText={'dark'}
                        selectActive={`Просмотр: ${(view && dataset[view]) || 'По умолчанию'}`}
                        dataset={viewDataset}
                        onChangeActive={(res: any)=>{
                            onChangeGeneralSelect(res, onChangeParent)
                        }}
                    />
                )
            }
            <SearchForm
                value={filter['search']}
                onChangeCb={(value: string) => {
                    if(typeof onSearch === 'function') {
                        onSearch(value);
                    }
                }}
            />
            {
                <Options
                    className={'table-funnel-header__options'}
                    items={(() => {
                        const temp: Array<any> = []
                        if (typeof onUpload === 'function' && isCreate) {
                            temp.push({
                                type: 'btn',
                                tooltip: trans('Upload', language),
                                data: {
                                    isDownloadBtn: true,
                                    icon: {
                                        Component: ArrowUpIcon,
                                        width: 25,
                                        height: 25,
                                    },
                                    onFileChosen: onUpload,
                                    fileType: 'xlsx'
                                }
                            })
                        }
                        if (typeof onDownload === 'function' && isView) {
                            temp.push({
                                type: 'popup',
                                tooltip: trans('Download', language),
                                data: {
                                    btn: {
                                        icon: {
                                            Component: ArrowDownIcon,
                                            width: 16,
                                            height: 18
                                        },
                                        isIconBtn: true,
                                        size: 'lg'
                                    },
                                    isCenter: true,
                                    dropdownList: {
                                        items: [
                                            {
                                                text: trans('Download selected', language),
                                                onClick: () => {
                                                    onDownload('choice');
                                                }
                                            },
                                            {
                                                text: trans('Download active', language),
                                                onClick: () => {
                                                    onDownload('active');
                                                }
                                            },
                                            {
                                                text: trans('Download all', language),
                                                onClick: () => {
                                                    onDownload('all');
                                                }
                                            },
                                        ]
                                    },
                                }
                            })
                        }
                        if (typeof onFilter === 'function') {
                            temp.push({
                                type: 'filter',
                                tooltip: trans('Filter', language),
                                data: {
                                    applyCallback: (data: IFilterState) => {
                                        onFilter({
                                            filter: data
                                        });
                                    },
                                    type: type,
                                    geoList: datasetGeo,
                                    targetingList: datasetTargeting,
                                    projectId: Number(projectId),
                                    defaultData: filter['filter'],
                                    isDefaultSubmit: false
                                }
                            })
                        }
                        if (typeof onCreate === 'function' && isCreate) {
                            temp.push({
                                type: 'btn',
                                tooltip: (()=>{
                                    if(view === 'campaigns') {
                                        return trans('Create new campaign', language);
                                    }
                                    if(view === 'creatives') {
                                        return trans('Create new creative', language);
                                    }
                                    return trans('Create new placement', language);
                                })(),
                                data: {
                                    icon: {
                                        Component: PlusIcon,
                                        width: 23,
                                        height: 23,
                                    },
                                    onClick: onCreate
                                }
                            })
                        }
                        return temp;
                    })()}
                />
            }
        </div>
    )
}

export default TableFunnelHead;
import './style.scss';
import {trans} from "../../../../../_locales";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../../redux/hooks";
import {currentUser, currentUserLang, userId} from "../../../../../redux/slices/user";
import {ModalName, openModal} from "../../../../../redux/slices/modals";
import iconCopy from "../../../../../assets/icons/copy.svg";
import iconEdit from "../../../../../assets/icons/edit.svg";
import iconRemove from "../../../../../assets/icons/trash.svg";
import iconEmpty from "../../../../../assets/icons/empty.svg";
import Btn from "../../../../../ui/Btn";
import {useNavigate, useParams} from "react-router-dom";
import {createApiToken, deleteApiToken, getApiTokens, updateApiToken} from "../../../../../actions/tokens";
import {tokens} from "../../../../../redux/slices/tokens";
import {getScreenResolution, modifyString, sendSdk} from "../../../../../utils";
import {projectGeneral} from "../../../../../redux/slices/project";
import {getRoles} from "../../../../../actions/projects";

const SettingsApi = () => {
    const language = useAppSelector(currentUserLang);
    const reduxUserId = useAppSelector(userId);
    const reduxData = useAppSelector(tokens)
    const reduxUser = useAppSelector(currentUser);
    const currentProject = useAppSelector(projectGeneral);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {projectId} = useParams();
    const permissions = reduxUser && reduxUser.permission ? reduxUser.permission[`${projectId}`] : undefined;
    const isView = permissions && permissions['can_view'] && permissions['can_view'].includes('settings_api') || currentProject && currentProject.owner_id === reduxUserId;
    const isCreate = permissions && permissions['can_create'] && permissions['can_create'].includes('settings_api') || currentProject && currentProject.owner_id === reduxUserId;
    const isEdit = permissions && permissions['can_edit'] && permissions['can_edit'].includes('settings_api') || currentProject && currentProject.owner_id === reduxUserId;
    const isDelete = permissions && permissions['can_delete'] && permissions['can_delete'].includes('settings_api') || currentProject && currentProject.owner_id === reduxUserId;
    const used_roles = permissions && permissions['roles'] ? permissions['roles'] : {};
    const [roles, setRoles] = useState(used_roles)

    const [wrapper, setWrapper] = useState<Array<any>>([])

    useEffect(()=> {
        if (projectId && reduxUserId) {
            sendSdk('page_view', {
                'user': {
                    'uid': reduxUserId
                },
                'event_params': {
                    'project_id': projectId,
                    'user_id': reduxUserId,
                    'page': 'settings_api',
                    'resolution': getScreenResolution()
                }
            })
            if(permissions && !isView) {
                navigate(`/projects/${projectId}/settings/`);
            }
            dispatch(getRoles({projectId: Number(projectId)})).then((r)=> {
                if (Array.isArray(r.payload)) {
                    const temp: any = {}
                    r.payload.map((item:any)=> {
                        temp[item.name] = item.title;
                    })
                    setRoles(temp);
                }
            })
        }
    }, [projectId, reduxUserId, permissions])

    useEffect(()=> {
        if(reduxData && typeof reduxData === 'object') {
            setWrapper(reduxData)
        }
    }, [reduxData])

    useEffect(()=> {
        dispatch(getApiTokens({projectId: Number(projectId)})).then((r)=> {
            if(r.payload && typeof r.payload === 'object') {
                setWrapper(r.payload)
            }
        })
    }, [projectId])

    const createToken = (response:any) => {
        const data:any = {
            name: response.name,
            role: response.role
        }
        dispatch(createApiToken({projectId:Number(projectId), userId: reduxUserId, data}));
        sendSdk('event', {
            'event_type': 'create',
            'event_category': 'settings_api',
            'event_name': 'active',
            'event_value': '10',
            'event_params': {
                'project_id': projectId,
                'user_id': reduxUserId
            }
        });
    }
    const editToken = (id:number, name:string, role: string) => {
        const data:any = {
            id: id,
            name: name,
            role: role
        }
        dispatch(updateApiToken({projectId:Number(projectId), userId: reduxUserId, tokenId: id, data}));
        sendSdk('event', {
            'event_type': 'edit',
            'event_category': 'settings_api',
            'event_name': 'active',
            'event_value': '10',
            'event_params': {
                'project_id': projectId,
                'user_id': reduxUserId,
                'token_id': id
            }
        });
    }
    const removeToken = (id:number) => {
        dispatch(deleteApiToken({projectId:Number(projectId), tokenId: id}));
        sendSdk('event', {
            'event_type': 'delete',
            'event_category': 'settings_api',
            'event_name': 'active',
            'event_value': '10',
            'event_params': {
                'project_id': projectId,
                'user_id': reduxUserId,
                'token_id': id
            }
        });
    }

    const shortToken = (value:string):string => {
        return modifyString(value);
    }

    const copyToken = (value:string):void => {
        navigator.clipboard.writeText(value).then(r => document.execCommand('Copy')).then(()=>console.log('token copied'));
    }

    return(
        <div className='settings-api'>
            <div className="settings-api__head">
                <div className="settings-api__wrapper">
                    <h3 className='settings-api__title'>{trans('Токен аутентификации', language)}</h3>
                    <p className='settings-api__description'>Токены аутентификации организации могут использоваться во многих местах для программного взаимодействия с TargetAds.</p>
                </div>
                {isCreate && <Btn
                    className={'settings-api__add'}
                    text={trans('Create new token', language)}
                    color={'blue'}
                    onClick={() => {
                        dispatch(openModal({
                            name: ModalName.TOKEN_CREATE,
                            data: {
                                name: '',
                                role: 'admin',
                                roles: roles,
                                onChange: createToken
                            }
                        }));
                        sendSdk('event', {
                            'event_type': 'open',
                            'event_category': 'settings_api',
                            'event_name': 'create',
                            'event_value': '10',
                            'event_params': {
                                'project_id': projectId,
                                'user_id': reduxUserId
                            }
                        });
                    }}
                />}
            </div>
            <div className="settings-api__table-head">
                <table className='settings-api__table'>
                    <thead>
                        <tr>
                            <th>{trans('Token', language)}</th>
                            <th>{trans('Author', language)}</th>
                            <th>{trans('Role', language)}</th>
                            <th>{trans('Creation date', language)}</th>
                            <th></th>
                        </tr>
                    </thead>
                </table>
            </div>
                { wrapper.length > 0 && <div className='settings-api__table-scroll'><table className='settings-api__table'><tbody>
                    {wrapper.map((item:any)=> (
                        <tr key={item.id}>
                            <td>
                                <div className="settings-api__block">
                                    <div className="settings-api__wrapper">
                                        <div className="settings-api__name">{item.name}</div>
                                        <div className="settings-api__block">
                                            <div className="settings-api__token">{shortToken(item.token)}</div>
                                            <button className="settings-api__copy" onClick={() => copyToken(item.token)}>
                                                <img src={iconCopy} alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>{item.author}</td>
                            <td>{roles[item.role] ? roles[item.role] : ''}</td>
                            <td>{item.created_at}</td>
                            <td>
                                <div className="settings-api__btns">
                                    {(isEdit || item.author_id === reduxUserId) && <button className="settings-api__btn"
                                        onClick={() => {
                                            dispatch(openModal({
                                                name: ModalName.TOKEN_EDIT,
                                                data: {
                                                    name: item.name,
                                                    role: item.role,
                                                    roles: roles,
                                                    onChange: (response: {
                                                        name: string,
                                                        role: string
                                                    }) => editToken(item.id, response.name, response.role)
                                                }
                                            }));
                                            sendSdk('event', {
                                                'event_type': 'open',
                                                'event_category': 'settings_api',
                                                'event_name': 'edit',
                                                'event_value': '10',
                                                'event_params': {
                                                    'project_id': projectId,
                                                    'user_id': reduxUserId
                                                }
                                            });
                                        }}
                                    >
                                        <img src={iconEdit} alt=""/>
                                    </button>}
                                    {(isDelete || item.author_id === reduxUserId) && <button className="settings-api__btn" onClick={() => removeToken(item.id)}>
                                        <img src={iconRemove} alt=""/>
                                    </button>}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody> </table></div>}
            {wrapper.length === 0 && <div className='settings-api__empty'>
                <img src={iconEmpty} alt=""/>
                <p>{trans("You haven't created any authentication tokens yet", language)}</p>
            </div>}
        </div>
    )
}

export default SettingsApi;